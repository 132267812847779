// Angular
import { Component, Input, OnInit, ViewRef, NgZone, ChangeDetectorRef, Directive } from '@angular/core';
// RxJS
import { Observable, Subject } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { Logout, User } from '../../../../../core/auth';
import { StorageService } from '../../../../../services/utilities/storage.service';

import { CommonService } from '../../../../../services/utilities/common.service';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { Router } from '@angular/router';
import { UserService as InstituteUserService, UserService } from './../../../../../services/user.service';
import { ApiResult } from '../../../../../viewmodels/common/ApiResult';
import { UserProfileViewModel } from '../../../../../viewmodels/UserProfileViewModel';
import { AppConfig } from '../../../../../services/utilities/app-config';
import { ProgressbarService } from '../../../../pages/shared/progressbar/progressbar.service';
import { AssociationTypeTerm } from '../../../../../enum/AssociationTypeTerm';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { CropAttachmentComponent } from '../../../../pages/shared/crop-attachment/crop-attachment.component';
import { UserVM } from '../../../../../viewmodels/User/UserVM';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { UserActivityService } from '../../../../../services/user-activity.service';


// @Directive()
@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	associationTypeTerm: string;
	instituteID: number;
	userDisplayName: string;
	userRoleName: string;

	clientUserId: string;
	clientLogInLogId: string;

	public dialogRef: MatDialogRef<CropAttachmentComponent>;

	studentUserId: string;
	studentLogInLogId: string;

	isClientURL: boolean = false;
	isStudentURL: boolean = true;
	isTutorURL: boolean = true;
	userProfileImagePath: string;
	defaultImageURL: string;

	profileImageString: string;
	profileImageFile: File;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	userDefaultPic: string;

	adminUserProfileImageURL = this.appConfig.InstituteUserProfileAttachmentUrl;
	clientUserProfileImageURL = this.appConfig.UserProfileAttachmentUrl;
	tutorProfileImageURL = this.appConfig.TutorProfileAttachmentUrl;
	instiuteUserProfileURL = this.appConfig.InstituteUserProfileUrlOfAdminPortal;
	userDefaultImageURL = this.appConfig.UserAvtarImage_URL;
	userProfileResult: UserProfileViewModel = new UserProfileViewModel();

	studentID: number;
	tutorID: number;
	constructor(
		private storage: StorageService,
		private router: Router,
		private auth: AuthService,
		private commonService: CommonService,
		private userService: UserService,
		private userActivityService: UserActivityService,
		private instituteUserService: InstituteUserService,
		private progressbarService: ProgressbarService,
		private ngZone: NgZone,
		private _dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private appConfig: AppConfig) {

		let isStudentURL = this.commonService.isStudentURL();
		//let indexOfStudentURL = router.url.toString().indexOf('/student/');
		let studentRole = this.getStudentUserRoles();

		if (isStudentURL == true && !this.commonService.isNullOrEmpty(studentRole)) {
			this.isStudentURL = true;
			this.isClientURL = false;
		}
		else {
			this.isClientURL = true;
			this.isStudentURL = false;
		}

		if (this.isStudentURL == true) {
			this.userDisplayName = this.storage.getStudentUserDisplayName();
			this.userRoleName = this.storage.getStudentUserRoles();
		} else {
			this.userDisplayName = this.storage.getUserDisplayName();
			this.userRoleName = this.storage.getUserRoles();

			if (this.userRoleName == AssociationTypeTerm.Tutor) {
				this.tutorID = +this.storage.getTutorID() || 0;
			}
		}
	}


	ngOnInit(): void {
		let self = this;
		//let indexOfStudentURL = self.router.url.toString().indexOf('/student/');
		let isStudentURL = this.commonService.isStudentURL();
		let studentRole = self.storage.getStudentToken();


		self.associationTypeTerm = self.storage.getAdminAssociationTypeTerm();
		self.clientUserId = self.storage.getUserId();
		self.clientLogInLogId = self.storage.getLogInLogID();
		self.instituteID = +this.storage.getInstituteID() || 0;

		self.refreshProfilePic();

		self.subscribeProfilePicChangeEvents();

	}

	refreshProfilePic() {
		const self = this;
		const profilePicPath = self.storage.getUserProfilePic();

		if (!self.commonService.isNullOrEmpty(profilePicPath)) {
			self.userDefaultPic = profilePicPath.split('/').pop();
			if (self.commonService.isNullOrEmpty(self.userDefaultPic)) {
				//self.userDefaultImageURL = self.appConfig.UserAvtarImage_URL;
				self.userProfileResult.userProfilePic = self.appConfig.UserAvtarImage_URL;
				self.storage.setUserProfilePic(self.userProfileResult.userProfilePic);
			}
			else {
				// 	self.getAdminUserProfileImage(self.clientUserId, self.associationTypeTerm);
				self.userProfileResult.userProfilePic = profilePicPath;
			}
		}
	}


	getAdminUserProfileImage(userID, associationTypeTerm) {
		let self = this;
		self.instituteUserService.getUserProfileByID(userID, self.instituteID, associationTypeTerm).subscribe((res) => {
			if (res.success == true && res.data != null) {
				self.userProfileResult = res.data;
				let imageName = self.userProfileResult.userProfilePic;
				if (self.userProfileResult.isProfilePicFromUser == true) {
					if (associationTypeTerm == AssociationTypeTerm.Tutor) {
						self.userProfileResult.userProfilePic = `${self.clientUserProfileImageURL}${userID}/thumb/${imageName}`;
					}
					else {
						self.userProfileResult.userProfilePic = `${self.adminUserProfileImageURL}${userID}/thumb/${imageName}`;
					}
				}
				else {
					if (associationTypeTerm == AssociationTypeTerm.Institute) {
						self.userProfileResult.userProfilePic = `${self.instiuteUserProfileURL}${self.instituteID}/thumb/${imageName}`;
					}
					if (associationTypeTerm == AssociationTypeTerm.Tutor) {
						self.userProfileResult.userProfilePic = `${self.tutorProfileImageURL}${self.tutorID}/thumb/${imageName}`;
					}
				}
				self.storage.setUserProfilePic(self.userProfileResult.userProfilePic);
			}
			if (!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges()
			}
			//self.commonService.hideLoader();
		}, (err) => {
			self.commonService.hideLoader();
		})
	}

	getStudentUserProfileImage(userID, associationTypeTerm) {
		let self = this;
		// self.studentUserService.getUserProfileByID(userID, self.instituteID, associationTypeTerm).subscribe((res: ApiResult) => {
		// 	if (res.success == true && res.data != null) {
		// 		self.userProfileResult = res.data;
		// 		let imageName = self.userProfileResult.userProfilePic;
		// 		if (self.userProfileResult.isProfilePicFromUser == true) {
		// 			self.userProfileResult.userProfilePic = `${self.clientUserProfileImageURL}${userID}/thumb/${imageName}`;
		// 			self.storage.setStudentProfilePic(self.userProfileResult.userProfilePic);
		// 		}
		// 		else {
		// 			if (associationTypeTerm == AssociationTypeTerm.Student) {
		// 				self.userProfileResult.userProfilePic = `${self.studentProfileImageURL}${self.studentID}/thumb/${imageName}`;
		// 				self.storage.setStudentProfilePic(self.userProfileResult.userProfilePic);
		// 			}
		// 		}
		// 	}

		// 	if (!(this.cdr as ViewRef).destroyed) {
		// 		this.cdr.detectChanges()
		// 	}

		// }, (err) => {
		// 	self.commonService.hideLoader();
		// })
	}

	getStudentUserRoles(): string {
		return this.storage.getStudentUserRoles();
	}

	subscribeProfilePicChangeEvents() {
		let self = this;
		self.progressbarService.profileImageChangeEvent.subscribe((data) => {
			this.ngZone.run(() => {
				if (data != null && data != undefined && data == true) {
					let isStudentURL = this.commonService.isStudentURL();
					let studentRole = self.storage.getStudentToken();

					if (isStudentURL == true && !this.commonService.isNullOrEmpty(studentRole)) {
						self.getStudentUserProfileImage(self.studentUserId, self.associationTypeTerm);
					}
					else {
						self.getAdminUserProfileImage(self.clientUserId, self.associationTypeTerm);
					}
				}
			});
		});
	}

	logout() {
		let self = this;
		self.clientLogout();
	}

	clientLogout() {
		let self = this;
		let userRoleType = this.storage.getUserRoles();
		self.commonService.showLoader();
		this.auth.doLogout(self.clientUserId, self.clientLogInLogId).subscribe((response: ApiResult) => {
			//let res = response as ResponseModel;
			if (response.success == true) {
				this.userActivityService.saveUserActivity("Logout", "", "", "", "", "");

				let instituteKey = "";
				if (this.storage.isWhiteLabelApp() == true) {
					instituteKey = this.storage.getInstituteKey();
				}

				this._dialog.closeAll();
				this.storage.clearClientCredentials();

				if (this.commonService.isNullOrEmpty(instituteKey) == false) {
					this.storage.setWhiteLabelLogin(instituteKey);
				}

				this.router.navigate(['institute/auth/login']);

			}
			else {
				self.commonService.showToaster(response.data, "error");
				//	this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			}

			self.commonService.hideLoader();
		}, (error) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(error.error, "error");
		}
		);
	}


	gotoChangePassword() {
		let self = this;
		let isStudentURL = this.commonService.isStudentURL();
		this.userActivityService.saveUserActivity("User Management", "Change Password", "", "", "", "");
		let studentToken = self.storage.getStudentToken();
		if (isStudentURL == true && !this.commonService.isNullOrEmpty(studentToken)) {
			this.router.navigate(['/student/change-password']);
		}
		else {
			this.router.navigate(['/institute/change-password']);
		}

	}

	gotoMyProfile() {
		if (!this.isStudentURL) {

			if (this.tutorID > 0) {
				this.router.navigateByUrl('/institute/profile');
			}
			else {

			}
		}

	}

	changeProfile() {
		const self = this;

		this.userActivityService.saveUserActivity("User Management", "Change Photo", "", "", "", "");

		self.dialogRef = self._dialog.open(CropAttachmentComponent, {
			width: '90%', // Adjusted width to be responsive
			maxWidth: '800px', // Limit maximum width on larger screens
			position: {
				top: '40px',
				// left: '0px'
			},
			data: null,
		});
		self.dialogRef.afterClosed().subscribe((dataDialogue) => {
			if (dataDialogue !== undefined) {
				if (dataDialogue.mainImageFile !== undefined && dataDialogue.mainImageFile !== null && dataDialogue.croppedImageFile !== undefined && dataDialogue.croppedImageFile !== null) {

					// Start code cropped image
					const mailImageFile = dataDialogue.mainImageFile;
					const croppedImageFile = dataDialogue.croppedImageFile;
					const file = new File([croppedImageFile], mailImageFile.name, { type: mailImageFile.type, lastModified: Date.now() });
					self.assignModalAttachment(mailImageFile, file);
					// End code cropped image


				}
			}
		});
	}

	assignModalAttachment(file: any, croppedFile: any) {
		const self = this;
		/***************************render cropped image *****************************/
		const reader = new FileReader();
		reader.onload = (event: any) => {
			self.profileImageString = event.target.result;
			self.profileImageFile = croppedFile;
			// self.croppedImageFile = file;
			self.saveProfilePic();
		}
		reader.readAsDataURL(croppedFile);


		/***************************render cropped image *****************************/
	}

	saveProfilePic() {
		const self = this;

		const formData = new FormData();
		formData.append("userID", self.storage.getUserId());
		formData.append("instituteID", this.instituteID + "");
		formData.append("ProfilepicFile", this.profileImageFile, this.profileImageFile.name);
		formData.append("profilePictureFileCroppedString", this.profileImageString);

		self.userService.updateProfilePhoto(formData).subscribe((res) => {
			if (res.success === true) {
				self.commonService.showToaster(res.message, 'success');
				self.storage.setUserProfilePic(this.profileImageString);

				self.refreshProfilePic();

				if (!(this.cdr as ViewRef).destroyed) {
					this.cdr.detectChanges()
				}
				/*self.goToUserListPage();*/
			}
			else {
				self.commonService.showToaster(res.message, 'error');
			}
			self.commonService.hideLoader();
		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});


	}
}
