//@Packages
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ViewRef, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

//@Services
import { AuthNoticeService } from '../../../../core/auth/auth-notice/auth-notice.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { REGEXP } from '../../../../shared/regexp';
import { CommonService } from '../../../../services/utilities/common.service';

//@View Models
import { ApiResult } from '../../../../viewmodels/common/ApiResult';

declare var $: any;

// @Directive()
@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})


export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: UntypedFormGroup;
	loading = false;
	errors: any = [];
	isSubmitted: boolean = false;

	@ViewChild(FormGroupDirective, { static: false }) formGroupDirective: FormGroupDirective;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	constructor(
		private auth: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private commonService: CommonService,
		private regexp: REGEXP,
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit() {
		this.initRegistrationForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		//this.loading = false;
	}

	ngAfterViewInit() {
		let self = this;
		$("#email").focus();
	}
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.pattern(this.regexp.EMAIL_REGEXP),
				Validators.maxLength(255)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		let self = this;
		self.isSubmitted = true;
		const controls = this.forgotPasswordForm.controls;
		/** check form */
		if (this.forgotPasswordForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		//this.loading = true;
		this.commonService.showLoader();
		const userEmail = controls.email.value;
		this.auth.forgotPassword(userEmail).subscribe((response) => {
			//this.loading = false;

			let result = response as ApiResult;
			if (result != null && result != undefined) {
				if (result.success == true) {
					this.formGroupDirective.resetForm();
					this.commonService.showToaster(result.message, 'success');
					//this.authNoticeService.setNotice(this.translate.instant(result.message), 'success');
				} else {
					this.commonService.showToaster(result.message, 'error');
					//this.authNoticeService.setNotice(this.translate.instant(result.message), 'danger');
				}
			}
			this.commonService.hideLoader();
			if (!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges()
			}
		}, (err) => {
			//this.loading = false;
			this.commonService.hideLoader();
			this.commonService.showToaster(err.error, 'error');
			//this.authNoticeService.setNotice(err.error, 'danger');  
		});
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	keyDownForm(event) {
		if (event.keyCode == 13) {
			this.submit();
		}
	}

	goBack() {
		this.router.navigate(['/institute/auth/login']);
	}
}
