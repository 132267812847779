
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';
import { ApiVersion } from '../enum/ApiVersion';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { TestQuestionMappingVM } from '../viewmodels/TestMaster/TestQuestionMappingVM';
import { TestVM } from '../viewmodels/TestMaster/TestVM';
import { TestCloneVM } from '../viewmodels/TestMaster/testCloneVM';
import { StudentListVM } from '../viewmodels/student/StudentListVM';
import { TestUpdateVM } from '../viewmodels/TestMaster/TestUpdateVM';

const BaseInstituteURL = environment.BaseInstituteURL;
const BaseAdminURL = environment.AdminHostURL + "api/";
const ApiUrl = environment.ApiURL;

@Injectable({
	providedIn: 'root'
})
export class TestMasterService {

	constructor(private http: HttpClient) { }

	insertTestNew(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/InsertTestNew`, model);
	}

	saveTestSyllabusMapping(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/SaveTestSyllabusMapping`, model);
	}

	updateTestNew(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UpdateTestNew`, model);
	}

	saveTagTest(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/saveTagTest`, model);
	}

	getTestMasterDetailByIDNew(testMasterID: number, instituteID: number, timezoneOffset: string, instituteUserID?: number, associationTypeTerm?: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestMasterDetailByIDNew?testMasterID=${testMasterID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}&timezoneOffset=${timezoneOffset}`);
	}

	getTestInformation(testMasterID: number, instituteID: number, timezoneOffset: string, instituteUserID?: number, associationTypeTerm?: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestInformation?testMasterID=${testMasterID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}&timezoneOffset=${timezoneOffset}`);
	}

	getTestMasterSummaryNew(testMasterID: number, instituteID: number, timezoneOffset: string, instituteUserID?: number, associationTypeTerm?: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetFullTestMasterSummary?testMasterID=${testMasterID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}&timezoneOffset=${timezoneOffset}`);
	}

	flipQuestion(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/FlipQuestion`, model);
	}



	applyMannuallyChangedQuestion(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ApplyMannuallyChangedQuestion`, model);
	}

	updateTestExamTime(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UpdateTestExamTime`, model);
	}

	changeToSave(testMasterID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ChangeToSave?testMasterID=${testMasterID}&instituteID=${instituteID}`);
	}

	updateQuestionShuffle(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UpdateQuestionShuffle`, model);
	}

	getTestMasterQuestionsByIDNew(testMasterID: number, instituteID: number, instituteUserID: number, associationTypeTerm: string, languageID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestMasterQuestionsByIDNew?testMasterID=${testMasterID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}&languageID=${languageID}`);
	}

	getCountForDashboard(tutorID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetCountForDashboard?tutorID=${tutorID}&instituteID=${instituteID}`);
	}

	getTestResult(testMasterID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestResult?testMasterID=${testMasterID}&instituteID=${instituteID}`);
	}

	GetTestAttendances(testMasterID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestAttendances?testMasterID=${testMasterID}&instituteID=${instituteID}`);
	}


	getExportTestQuestionPaper(testMasterID: number, instituteID: number, pdfType: string, languageID: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ExportTestQuestionPaper?testMasterID=${testMasterID}&instituteID=${instituteID}&pdfType=${pdfType}&languageID=${languageID}`);
	}

	getExportTestResult(instituteID: number, instituteUserID: number, courseIDs: string, groupIDs: string, subjectIDs: string, tutorIDs: string, testMasterID: number, pageNum: number, sortColumn: string, sortDirection: string) {
		return this.http.get(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ExportTestResult?instituteID=${instituteID}&instituteUserID=${instituteUserID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&tutorIDs=${tutorIDs}&testMasterID=${testMasterID}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`, { responseType: 'blob' });
	}

	deleteTestMasterNew(testMasterID, instituteID) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/DeleteTestMasterNew?testMasterID=${testMasterID}&instituteID=${instituteID}`, null);
	}

	getLessonBySubjectMultipleLessons(instituteID: number, subjectID: number, lessonIDs: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetLessonBySubjectMultipleLessons?instituteID=${instituteID}&subjectID=${subjectID}&lessonIDs=${lessonIDs}`);
	}

	GetLessonBySubjectIDTestMasterID(instituteID: number, subjectID: number, testMasterID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetLessonBySubjectIDTestMasterID?instituteID=${instituteID}&subjectID=${subjectID}&testMasterID=${testMasterID}`);
	}

	//getTopicBySubjectMultipleLessonTopics(instituteID: number, subjectID: number, lessonIDs: string, topicIDs: string) {
	//	return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Topic/GetTopicBySubjectMultipleLessonTopics?instituteID=${instituteID}&subjectID=${subjectID}&lessonIDs=${lessonIDs}&topicIDs=${topicIDs}`);
	//}

	getSyllabusTopicsByLesson(testMasterID: number, instituteID: number, lessonID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Topic/GetSyllabusTopicsByLesson?instituteID=${instituteID}&testMasterID=${testMasterID}&lessonID=${lessonID}`);
	}

	projectTermByCategoryNameByTest(categoryName: string, testMasterID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/ProjectTerm/ProjectTermByCategoryNameByTest?categoryName=${categoryName}&testMasterID=${testMasterID}`);
	}

	getQuestionToChangeManually(testQuestionChangeFilterInputVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetQuestionToChangeManually`, testQuestionChangeFilterInputVM);
	}

	getParaQuestionToChangeManually(testQuestionChangeFilterInputVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetParaQuestionToChangeManually`, testQuestionChangeFilterInputVM);
	}

	getPerformanceChartData(instituteID: number, tutorID: number, courseIDs: string, groupIDs: string, subjectIDs: string, caller: string, callerParam: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetPerformanceChartData?instituteID=${instituteID}&tutorID=${tutorID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&caller=${caller}&callerParam=${callerParam}`);
	}

	getTestList(searchText: string, instituteID: number, tutorID: number, studentID: number, courseIDs: string, groupIDs: string, subjectIDs: string, pageNum: number, sortColumn: string, sortDirection: string, timeZoneOffset: string, testStatusTerm: string, caller: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestList?searchText=${searchText}&instituteID=${instituteID}&tutorID=${tutorID}&studentID=${studentID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&timeZoneOffset=${timeZoneOffset}&testStatusTerm=${testStatusTerm}&caller=${caller}`);
	}

	getStudentTestList(searchText: string, instituteID: number, tutorID: number, studentID: number, courseID: string, groupID: string, subjectIDs: string, pageNum: number, sortColumn: string, sortDirection: string, timeZoneOffset: string, testStatusTerm: string, caller: string) {
		return this.http.get<ApiResult>(`${ApiUrl}${ApiVersion.V1}/TestMaster/GetStudentTestList?searchText=${searchText}&instituteID=${instituteID}&tutorID=${tutorID}&studentID=${studentID}&courseIDs=${courseID}&groupIDs=${groupID}&subjectIDs=${subjectIDs}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&timeZoneOffset=${timeZoneOffset}&testStatusTerm=${testStatusTerm}&caller=${caller}`);
	}

	getSubjectsAndSections(testMasterID: number, instituteID?: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetSubjectsAndSections?testMasterID=${testMasterID}&instituteID=${instituteID}`);
	}

	getByID(testMasterID: number, instituteID?: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetByID?testMasterID=${testMasterID}&instituteID=${instituteID}`);
	}

	getTempWordQuestions(testMasterID: number, instituteID?: number, subjectID?: number) {
		let sub = "";
		if (subjectID != null) {
			sub = subjectID.toString();
		}
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTempWordQuestions?testMasterID=${testMasterID}&instituteID=${instituteID}&subjectID=${sub}`);
	}

	getTestMasterSubjects(testMasterID: number, instituteID?: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestMasterSubjects?testMasterID=${testMasterID}&instituteID=${instituteID}`);
	}

	//int testMasterID, int instituteID, int? sectionID
	getTestMasterQuestions(testMasterID: number, instituteID: number, sectionID?: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestMasterQuestions?testMasterID=${testMasterID}&instituteID=${instituteID}&sectionID=${sectionID}`).pipe(
			catchError(err => {
				return of({ data: "Error in loading test master questions", success: false, message: "" });
			}));
	}

	deleteTestMasterQuestion(mappingVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/DeleteTestMasterQuestionMapping`, mappingVM).pipe(
			catchError(err => {
				return of({ data: "Error in deleting question", success: false, message: "" });
			}));
	}

	updateBonusApply(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UpdateBonusApply`, model);
	}

	updateCorrectOptions(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UpdateCorrectOptions`, model);
	}

	getTestMasterSubjectsByID(testMasterID: number, instituteID?: number, instituteUserID?: number, associationTypeTerm?: string, subjectID?: number, language?: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/getTestMasterSubjectsByID?testMasterID=${testMasterID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}&subjectID=${subjectID}&languageId=${language}`);
	}

	//cloneTestMaster(testMasterID, instituteID) {
	//	return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/CloneTestMaster?testMasterID=${testMasterID}&instituteID=${instituteID}`, null);
	//}

	cloneTestMaster(test: TestCloneVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/CloneTestMaster`, test);
	}

	recalculateTestMaster(test: TestUpdateVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/RecalculateTestMaster`, test);
	}


	resetStudentTest(test: StudentListVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ResetStudentTest`, test);
	}

	getTestSamplePaper(testMasterID: number, instituteID, subjectID) {
		return this.http.get(`${BaseAdminURL}TestMaster/TestSamplePaper?testMasterID=${testMasterID}&instituteID=${instituteID}&subjectID=${subjectID}`, { responseType: 'blob' });
	}

	importTestPaper(model) {
		return this.http.post<ApiResult>(`${BaseAdminURL}TestMaster/ImportTestPaper`, model);
	}

	saveTestPaper(paper) {
		return this.http.post<ApiResult>(`${BaseAdminURL}Question/saveTestPaper`, paper).pipe(
			catchError(err => {
				return of({ data: "Error in saving test paper", success: false, message: "" });
			}));
	}

	uploadOmrFile(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UploadOmrFile`, data);
	}

	uploadOmrResponse(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UploadOmrResponse`, data);
	}

	uploadTestMarks(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/UploadTestMarks`, data);
	}

	previewOmrFile(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/PreviewOmrFile`, data);
	}

	processOmrFile(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ProcessOmrFile`, data);
	}

	getLanguages(testMasterID, instituteID) {
		if (testMasterID == null) {
			return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestLanguages?instituteID=${instituteID}`);
		}
		else {
			return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetTestLanguages?instituteID=${instituteID}&testMasterID=${testMasterID}`);
		}
	}

	getTestNames(instituteID, batches) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/GetInstituteTestNames?instituteID=${instituteID}&batches=${batches}`);
	}

	ExportTestQuestionPaperWithTwoColumn(testMasterID, instituteID, pdfType, languageID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/TestMaster/ExportTestQuestionPaperWithTwoColumn?testMasterID=${testMasterID}&instituteID=${instituteID}&pdfType=${pdfType}&languageID=${languageID}`);
	}
}
