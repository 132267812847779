import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserActivityVM } from '../viewmodels/UserActivityVM';
import { CommonService } from './utilities/common.service';
import { StorageService } from './utilities/storage.service';
import { NewApiResult } from '../viewmodels/common/NewApiResult';

const BaseInstituteURL = environment.BaseInstituteURL;
const ApiUrl = environment.ApiURL;

@Injectable({
	providedIn: 'root'
})
export class UserActivityService {

	constructor(private http: HttpClient, private storageService: StorageService, private deviceService: DeviceDetectorService) { }

	saveUserActivity( module, subModule, action, actionParam, paramType, paramID, studentID?: number, instituteID?: number) {
		const activity = new UserActivityVM();
		activity.browser = this.deviceService.browser + " " + this.deviceService.browser_version;
		activity.system = "Institute";
		activity.userID = this.storageService.getUserId();
		activity.tutorID = this.storageService.getTutorID();
		activity.instituteID = instituteID == null ? this.storageService.getInstituteID() : (instituteID + "");
		activity.studentID = studentID == null ? this.storageService.getStudentID() : (studentID + "");
		activity.updatedBy = this.storageService.getUserId();
		activity.paramType = paramType;
		activity.paramID = paramID;

		activity.module = module;
		activity.subModule = subModule;
		if (this.deviceService.isDesktop() == true) {
			activity.sdkOSVersion = this.deviceService.os_version + ' - Desktop';
		}
		else if (this.deviceService.isMobile() == true) {
			activity.sdkOSVersion = this.deviceService.os_version + " - Mobile";
		}
		else if (this.deviceService.isTablet() == true) {
			activity.sdkOSVersion = this.deviceService.os_version + + " - Tablet";
		}
		activity.deviceName = this.deviceService.device;
		activity.action = action;
		activity.actionParam = actionParam;
		return this.http.post<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/User/SaveUserActivity`, activity).subscribe(data => {
			const data1 = data;
		}, error => {
			const message = error;
		});
		
	}
}
