// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-column {
    position: absolute;
    /* top: 4px; */
    margin: auto;
    width: 30px;
    height: 30px;
    float: right;
    display: inline-block;
    right: auto;
    margin-left: 6px;
    text-align: left !important;
}
/* 
.sort-column {
    display: inline-block;
    padding-left: 5px;
} */

.upper-arrow {
}

.upper-down-arrow {
}
`, "",{"version":3,"sources":["webpack://./src/app/views/pages/shared/sortable-table/sortable-column/sortable-column.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,WAAW;IACX,gBAAgB;IAChB,2BAA2B;AAC/B;AACA;;;;GAIG;;AAEH;AACA;;AAEA;AACA","sourcesContent":[".sort-column {\r\n    position: absolute;\r\n    /* top: 4px; */\r\n    margin: auto;\r\n    width: 30px;\r\n    height: 30px;\r\n    float: right;\r\n    display: inline-block;\r\n    right: auto;\r\n    margin-left: 6px;\r\n    text-align: left !important;\r\n}\r\n/* \r\n.sort-column {\r\n    display: inline-block;\r\n    padding-left: 5px;\r\n} */\r\n\r\n.upper-arrow {\r\n}\r\n\r\n.upper-down-arrow {\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
