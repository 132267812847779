export enum Configuration {
    LoginTokenExpiresIn = "LoginTokenExpiresIn",
    ResetPasswordLinkExpiresIn = "ResetPasswordLinkExpiresIn",
    ForgotPasswordEmailSubject = "ForgotPasswordEmailSubject",
    AddQuestionOptionMinLimit = "AddQuestionOptionMinLimit",
    AddQuestionOptionMaxLimit = "AddQuestionOptionMaxLimit",
    QuestionImageMaxLimit = "QuestionImageMaxLimit",
    QuestionImageSizeLimit = "QuestionImageSizeLimit",
    PageSizeLimit_V1="PageSizeLimit_V1",
    PageSizeLimit_V2="PageSizeLimit_V2",
	GoogleAPIKey = "GoogleAPIKey"

}
