// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .kt-header-menu-wrapper {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/header/menu-horizontal/menu-horizontal.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,YAAA;AACF","sourcesContent":[":host {\r\n\twidth: 100%;\r\n\r\n\t.kt-header-menu-wrapper {\r\n\t\theight: 100%;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
