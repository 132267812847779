import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/utilities/common.service';
import { DateFormat } from '../enum/DateFormat';
import { DateSeprator } from '../enum/DateSeprator';

@Pipe({
  name: 'parseDate'
})
export class ParseDatePipe implements PipeTransform {
  constructor(private commonService: CommonService) {
  }
  transform(value: any, ...args: any[]): any { 
    return this.parseDate(value);
  }

  parseDate(date: string): string {
		if (date != null && date != undefined && date != "") {
      return this.commonService.getDateString(new Date(date), DateFormat.DDMMYYYY, DateSeprator.SLASH);
		}
		return "";
	}
}
