import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
//@Packages
import { NgbModule, NgbAlertConfig } from "@ng-bootstrap/ng-bootstrap";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
// import { NgxSlickJsModule } from 'ngx-slickjs';

//@Guard
import { AuthGuard } from "../../../core/auth";

//@Custom Modules
import { PartialsModule } from "../../partials/partials.module";
import { MaterialPreviewModule } from "../../partials/content/general/material-preview/material-preview.module";
import { SharedModule } from "../shared/shared.module";

//@Enums
import { PageCode } from "../../../enum/PageCode";

//@components
import { TutorListNewComponent } from "../tutor_management/tutor/tutor-list-new/tutor-list-new.component";
import { StudentListNewComponent } from "../student_management/student-list-new/student-list-new.component";
import { AdminListComponent } from "./../user_management/user/admin-list/admin-list.component";
import { GroupListNewComponent } from "./../group_management/group/group-list-new/group-list-new.component";
import { GroupAddNewComponent } from "./../group_management/group/group-add-new/group-add-new.component";
import { GroupViewComponent } from "./../group_management/group/group-view/group-view.component";
import { GroupEditNewComponent } from "./../group_management/group/group-edit-new/group-edit-new.component";
import { GroupStudentUpdateComponent } from "./../group_management/group/group-student-update/group-student-update.component";
import { TutorViewComponent } from "../tutor_management/tutor/tutor-view/tutor-view.component";
import { StudentViewComponent } from "../student_management/student-view/student-view.component";

import { InlineSVGModule } from "ng-inline-svg";
// search module
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import { ChartsModule } from 'ng2-charts';
import { NgxMaskDirective, IConfig } from "ngx-mask";
import { SlickCarouselModule } from "ngx-slick-carousel";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
	declarations: [StudentViewComponent],
	imports: [
		CommonModule,
		PartialsModule,
		MaterialPreviewModule,
		NgbModule,
		SharedModule,
		MatToolbarModule,
		FormsModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatStepperModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatTabsModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatToolbarModule,
		MatTooltipModule,
		MatTreeModule,
		MatCardModule,
		MatStepperModule,
		MatButtonModule,
		TranslateModule,
		AngularEditorModule,
		// Ng2SearchPipeModule,
		// ChartsModule,
		InlineSVGModule,
		NgxMaskDirective,
		SlickCarouselModule,
		// NgxMaskModule.forRoot({
		//   showMaskTyped : true
		//  // clearIfNotMatch : true
		//  }),
		// NgxSlickJsModule.forRoot(),
	],
})
export class StudentViewModule {}
