//@Packages
import { Component, OnInit, ChangeDetectorRef, ViewRef, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

//@Services
import { AuthNoticeService } from '../../../../core/auth/auth-notice/auth-notice.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { REGEXP } from '../../shared/regexp/regexp';
import { StorageService } from '../../../../services/utilities/storage.service';

//@Validators
import { MustMatch } from '../../../..//validator/must-match.validator';

//@View Models
import { InitialResetPasswordVM } from '../../../../viewmodels/InitialResetPasswordVM';
import { ApiResult } from '../../../../viewmodels/common/ApiResult';
import { LoginResultVM } from '../../../../viewmodels/LoginResultVM';

declare var $: any;
@Component({
  selector: 'kt-initial-reset-password',
  templateUrl: './initial-reset-password.component.html',
  styleUrls: ['./initial-reset-password.component.scss']
})
export class InitialResetPasswordComponent implements OnInit {


  userId: number;
  loginLogID?: number;
  initialResetPassword: InitialResetPasswordVM;
  initialResetPasswordForm: UntypedFormGroup;
  loading = false;
  isSubmitted: boolean = false;
  hideInitRestPassword: boolean = true;
  hideInitCnfRestPassword: boolean = true;

  constructor(private router: Router,
    private commonService: CommonService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private regex: REGEXP,
    private formBuilder: UntypedFormBuilder) {

    this.initialResetPassword = new InitialResetPasswordVM();
  }

  ngOnInit() {
    this.userId = +localStorage.getItem("TempUserID") || 0;
    this.loginLogID = +localStorage.getItem("LoginLogID") || 0;

    // if (+this.userId == 0) {
    //   this.router.navigate(['/institute/login']);
    // } else {
    this.initResetPasswordForm();
    //}
  }

  ngAfterViewInit() {
    $("#newPassword").focus();
  }


  initResetPasswordForm() {
    let self = this;
    self.initialResetPassword.userID = self.userId;
    self.initialResetPassword.loginLogID = self.loginLogID;
    self.initialResetPassword.isInitialPasswordResetComplete = true;

    self.initialResetPasswordForm = this.formBuilder.group({
      userID: [self.initialResetPassword.userID],
      isInitialPasswordResetComplete: [self.initialResetPassword.isInitialPasswordResetComplete],
      newPassword: [self.initialResetPassword.newPassword, Validators.compose([Validators.required, Validators.pattern(this.regex.PASSWORD_EXP)])],
      confirmPassword: [self.initialResetPassword.confirmPassword, Validators.compose([Validators.required, Validators.pattern(this.regex.PASSWORD_EXP)])],
      updatedBy: [self.userId],
      logInLogID: [self.initialResetPassword.loginLogID],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });

  }


  resetPassword() {
    let self = this;
    self.isSubmitted = true;
    const controls = self.initialResetPasswordForm.controls;
    /** check form */
    if (self.initialResetPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    //self.loading = true;
    self.commonService.showLoader();
    self.auth.initialResetPassword(this.initialResetPasswordForm.value).subscribe((response) => {
      let result = response as ApiResult;
      if (result != null && result.success == true) {

        localStorage.removeItem("TempUserID");
        localStorage.removeItem("LoginLogID");

        self.auth.setSession(result.data as LoginResultVM);
        //self.router.navigate(['/institute/question']);
        self.storageService.goToLandingPage();
        //this.loading = false;
        self.commonService.hideLoader();
        if (!(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges()
        }
      }
      else {
        self.commonService.showToaster(result.message, 'error');
        self.commonService.hideLoader();
        //self.authNoticeService.setNotice(result.message, 'danger');
        self.commonService.hideLoader();
        self.cdr.detectChanges();
      }
    }, (err) => {
      self.commonService.hideLoader();
      self.commonService.showToaster(err.error, 'error');
      //self.authNoticeService.setNotice(err.error, 'danger');
      self.cdr.detectChanges();
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.initialResetPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  keyDownForm(event) {
    if (event.keyCode == 13) {
      this.resetPassword();
    }
  }
}
