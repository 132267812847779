import { Component, OnInit, Input, OnChanges, NgZone, AfterViewInit, Directive } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { GlobalService } from '../../../../services/utilities/global.service';
import { CommonService } from '../../../../services/utilities/common.service';

@Directive()
@Component({
  selector: 'mathjax',
  // inputs: ['content'],
  templateUrl: './mathjax.component.html',
  styleUrls: ['./mathjax.component.css']
})
export class MathjaxComponent implements OnInit, AfterViewInit {
  @Input() content: string = '';
  mathJaxObject;
  spanId: string = "";

  constructor(public gs: GlobalService,
    private ngZone: NgZone,
    public commonService: CommonService) {
   
  }
  ngOnInit() {

  }

  ngAfterViewInit() {
    this.spanId = `mathContent_${this.commonService.generateRandomString(8)}`;
    this.loadMathConfig()
    this.renderMath();
  }

  ngOnChanges(changes: SimpleChanges) { 
    if (changes['content']) {
      this.renderMath();
    } 
  }

  updateMathObt() {
    this.mathJaxObject = this.gs.nativeGlobal()['MathJax']; 
  }

  renderMath() {
    //MathJax.Hub.Queue(["Typeset",MathJax.Hub]); 
    this.updateMathObt();
    let angObj = this;
    this.ngZone.runOutsideAngular(()=>{
      setTimeout(() => { 
        let elementExists = document.getElementById(angObj.spanId);
        // console.log(angObj.spanId);
        // console.log(elementExists);
  
        if (elementExists) {
          let mathContentPara = angObj.mathJaxObject.Hub.Queue(["Typeset", angObj.mathJaxObject.Hub], angObj.spanId);
        }

        // let mathContentPara = angObj.mathJaxObject.Hub.Queue(["Typeset", angObj.mathJaxObject.Hub], angObj.spanId);
        // console.log(mathContentPara);
      }, 0)
    });
  

    // let mathContentPara = angObj.mathJaxObject.Hub.Queue(["Typeset", angObj.mathJaxObject.Hub], this.id);
    // console.log(mathContentPara);
  }

  loadMathConfig() {
    this.updateMathObt();
    this.mathJaxObject.Hub.Config({
      showMathMenu: false,
      tex2jax: { inlineMath: [["$", "$"], ["\\(", "\\)"]] },
      //menuSettings: { zoom: "Double-Click", zscale: "150%" },
      CommonHTML: { linebreaks: { automatic: true } },
      "HTML-CSS": { linebreaks: { automatic: true } },
      SVG: { linebreaks: { automatic: true } }
    });
  }

  
}
