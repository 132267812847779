import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { NewApiResult } from '../viewmodels/common/NewApiResult';


const BaseInstituteURL = environment.BaseInstituteURL;
const ApiUrl = environment.ApiURL;

@Injectable({
  providedIn: 'root'
})
export class SubjectService {


  constructor(private http: HttpClient) { }

  getByCourseID(courseID: number, instituteID: number, instituteUserID: number, associationTypeTerm:string) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Subject/GetByCourseID?courseID=${courseID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}`);
  }

  getSubjectInfoByTutorCourseIDs(courseIDs: string, instituteID: number, tutorID:number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Subject/GetSubjectInfoByTutorCourseIDs?courseIDs=${courseIDs}&instituteID=${instituteID}&tutorID=${tutorID}`);
	}

	getByInstituteID(instituteID: number) {
		return this.http.get<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/Subject/getByInstituteID?instituteID=${instituteID}`);
	}
}
