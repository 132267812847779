import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ktOnlyNumeric]'
})
export class OnlyNumericDirective {

    @Input("allowNegative") allowNegative: number = 0;

    // Allow decimal numbers and negative values
    private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);    

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete', 'ArrowLeft', 'ArrowRight'];

    constructor(private _el: ElementRef) {         
    }
   

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        
        if (+this.allowNegative == 1) {            
            if (this.specialKeys.filter(i => i == "-").length == 0)
                this.specialKeys.push("-");
        }
      
        // Allow Backspace, tab, end, delete and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this._el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

}
