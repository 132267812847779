// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',



  // new Production final
  BaseInstituteURL: 'https://api.brainflex360.com/api/',
  HostURL: 'https://api.brainflex360.com/',
  AdminHostURL: 'https://admin-api.brainflex360.com/',
  ApiURL: 'https://api.brainflex360.com/api/',
  BaseStudentFrontURL: 'https://student.brainflex360.com/',

  //  old production 
  // BaseInstituteURL: 'https://institute-api.brainflex360.com/Institute/api/',
  // HostURL: 'https://institute-api.brainflex360.com/',
  // AdminHostURL: 'https://admin-api.brainflex360.com/',
  // ApiURL: 'https://api.brainflex360.com/api/',
  // BaseStudentFrontURL: 'https://student.brainflex360.com/',

  //local
  // BaseInstituteURL: 'http://172.16.0.1:6300/api/',
  // HostURL: 'http://172.16.0.1:6300/',
  // AdminHostURL: 'http://172.16.0.1:6400/',
  // ApiURL: 'http://172.16.0.1:6300/api/',
  // BaseStudentFrontURL: 'http://localhost:8081/'


  // other wifi conncetion 
  // BaseInstituteURL: 'http://182.70.120.81:6300/api/',
  // HostURL: 'http://182.70.120.81:6300/',
  // AdminHostURL: 'http://182.70.120.81:6400/',
  // ApiURL: 'http://182.70.120.81:6300/api/',
  // BaseStudentFrontURL: 'http://182.70.120.81:6300/',



};
