// Angular
import { Component, OnInit, ChangeDetectorRef, ViewRef, NgZone, ViewChild, ElementRef, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

// Services
import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
// Models
import { ResponseModel } from '../../../../models/common/ResponseModel';
import { Logout } from '../../../../../app/core/auth';
import { ApiResult } from '../../../../viewmodels/common/ApiResult';
import { RefreshAccessTokenVM } from '../../../../viewmodels/RefreshAccessTokenVM';
import { RefreshAccessTokenResultVM } from '../../../../viewmodels/RefreshAccessTokenResultVM';

// Enums
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { RoleType } from '../../../../enum/RoleType';
import { UserActivityService } from '../../../../services/user-activity.service';

// @Directive()
@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

	isShowTimer = false;
	hourTimer: string = "00";
	minuteTimer: string = "00";
	secondTimer: string = "00";
	timerInterval?: any;
	userId: string;
	logInLogId: string;
	sessionMessage: string = "";
	isRefreshTokenApiCalled?: boolean = false;
	countDownDate: number = 0;
	toggleSearch: boolean = false;
	searchText: string = '';
	activeInstitute: boolean = false;


	@ViewChild('searchbar', { static: false }) searchbar: ElementRef;

	constructor(public router: Router,
		private ngZone: NgZone,
		private auth: AuthService,
		private userActivityService: UserActivityService,
		private cdr: ChangeDetectorRef,
		private _dialog: MatDialog,
		private commonService: CommonService,
		private storageService: StorageService) {

	}

	ngOnInit() {
		this.userId = this.storageService.getUserId();
		this.logInLogId = this.storageService.getLogInLogID();
		this.countDownTimer();

		if (this.storageService.getUserRoles() != RoleType.Tutor) {
			this.activeInstitute = true;
		} else {
			this.activeInstitute = false;
		}
		this.cdr.detectChanges();
	}

	ngOnDestroy() {
		if (this.timerInterval) {
			clearInterval(this.timerInterval);
		}
	}

	countDownTimer() {
		let self = this;

		self.countDownDate = parseInt(this.storageService.getTokenExpireAt());
		this.timerInterval = setInterval(() => {

			let current = new Date().getTime();
			let diffrenceMS = self.countDownDate - current;

			let minutes = Math.floor((diffrenceMS / 60) / 1000);
			let minutesms = (diffrenceMS % (60 * 1000));
			let seconds = Math.floor((minutesms) / (1000));

			if (minutes < 0) {
				// Logout Code
				clearInterval(this.timerInterval);
				self.clientLogout();
				// Logout Code
			}
			if (minutes < 10) {
				//if (minutes < 5) {
				if (self.isRefreshTokenApiCalled == false) {
					//Refresh Access Token.
					self.refreshAccessToken();
				}

				if (minutes < 0) {
					self.minuteTimer = "00";
					self.secondTimer = "00";
				}
				else {
					self.minuteTimer = minutes.toString().length > 1 ? minutes.toString() : "0" + minutes.toString();
					self.secondTimer = seconds.toString().length > 1 ? seconds.toString() : "0" + seconds.toString();
				}
				self.isShowTimer = false;
				self.sessionMessage = "Your session will be expire in ";
				if (!(this.cdr as ViewRef).destroyed) {
					this.cdr.detectChanges()
				}
			}

		}, 1000);
	}

	clientLogout() {
		let self = this;
		self.commonService.showLoader();
		this.auth.doLogout(self.userId, self.logInLogId).subscribe((response: ApiResult) => {
			//let res = response as ResponseModel;
			if (response.success == true) {
				this.userActivityService.saveUserActivity("Logout", "", "", "", "", "");
				this._dialog.closeAll();
				this.storageService.clearClientCredentials();
				this.router.navigate(['/institute/auth/login']);
			}
			else {
				self.commonService.showToaster(response.data, "error");
			}

			self.commonService.hideLoader();
		}, (error) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(error.error, "error");
		}
		);
	}

	refreshAccessToken() {
		let self = this;

		let model = new RefreshAccessTokenVM();
		model.access_Token = self.storageService.getToken();
		model.refresh_Token = self.storageService.getRefreshToken();

		self.isRefreshTokenApiCalled = true;
		self.auth.refreshAccessToken(model).subscribe(data => {
			self.isRefreshTokenApiCalled = false;
			const result = data as ApiResult;
			if (result != null) {
				if (result.success == true) {
					const newToken = result.data as RefreshAccessTokenResultVM;
					if (!self.commonService.isEmptyObject(newToken)) {
						self.storageService.setRefreshToken(newToken.refresh_Token);
						self.storageService.setAccessToken(newToken.access_Token, newToken.expiresIn, newToken.loginLogID);
						self.countDownDate = parseInt(this.storageService.getTokenExpireAt());
					}
				} else {
					self.clientLogout();
				}
			}

		}, err => {
			console.log(err);
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		})
	}

	openSearch() {
		let self = this;
		self.toggleSearch = true;
		self.searchbar.nativeElement.focus();
	}

	searchClose() {
		if (this.searchText != '') {
			this.searchText = '';
		}
		this.toggleSearch = false;
	}

	onFocusOutEvent(event: any) {
		this.toggleSearch = false;
	}

	onQuickStartClicked() {
		let self = this;
		self.router.navigate(['institute/quickstart']);
	}
}
