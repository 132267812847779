
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { NotificationInputVM } from '../viewmodels/NotificationDataVM';

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient) { }

  getByNotificationSentTo(notificationSentTo, timezoneOffset) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/GetByNotificationSentTo?notificationSentTo=${notificationSentTo}&timezoneOffset=${timezoneOffset}`);
  }

  updateViewFlag(notificationID, isView){ 
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/UpdateViewFlag?notificationID=${notificationID}&isView=${isView}`, null);
  }

  getAllNotification(userID:number, instituteID:number, timezoneOffset:string) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/GetAllNotification?userID=${userID}&instituteID=${instituteID}&timezoneOffset=${timezoneOffset}`);
	}

	saveNotification(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/SaveNotification`, data);
	}

	getNotificationList(vm: NotificationInputVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/GetNotificationList`, vm);
	}

	getByNotificationLink(instituteID, notificationLink) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/GetByNotificationLink?notificationLink=${notificationLink}&instituteID=${instituteID}`);
	}

	sendNotificationDelivery(data) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Notification/SendNotificationDelivery`, data);
	}
}
