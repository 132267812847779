import { Component, OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { StorageService } from '../../../../services/utilities/storage.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { Configuration } from '../../../../enum/Configuration';
import { parse } from 'path';

@Directive()
@Component({
  selector: 'kt-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  paginationSize: string;
  @Input() id: string; //id 
  //@Input() pager: any = {}; // the pager object 
  @Input() collectionSize: number;
  @Input() pageSizeKey: string;
  @Input() pageSize: number;
  @Input() page: number;
  @Input() startIndex: number;
  @Input() endIndex: number;

  @Output() changePageSize = new EventEmitter<number>();
  @Output() changePage = new EventEmitter<number>();

  lstPageSize: number[] = [];
  constructor(private storageService: StorageService,
    private commonService: CommonService) {


  }

  ngOnInit() {
    let self = this;
    if (self.pageSizeKey == Configuration.PageSizeLimit_V2) {
      self.paginationSize = self.storageService.getPaginationSize(Configuration.PageSizeLimit_V2);

    }
    else {
      self.paginationSize = self.storageService.getPaginationSize(Configuration.PageSizeLimit_V1);
    }
    if (!self.commonService.isNullOrEmpty(self.paginationSize)) {
      let pagesizelst = self.paginationSize.split(',');
      pagesizelst.forEach((ele) => {
        this.lstPageSize.push(+ele);
      });
    }

  }

  onPageSizeChange(pageSize): void {
    this.changePageSize.emit(+pageSize);
  }

  onPageChange(page): void {
    this.changePage.emit(+page);
  }

}
