// Angular
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation, ViewRef, Directive } from '@angular/core';
import {  ChartOptions, ChartType } from 'chart.js';
// import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-gauge/dist/chartjs-gauge.js';
import { EChartsOption } from 'echarts';

import { ActivatedRoute, Router } from '@angular/router';
import {
	ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexLegend, ApexDataLabels, ApexTitleSubtitle,
	ApexPlotOptions, ApexYAxis
} from "ng-apexcharts";

import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { CourseService } from '../../../../services/course.service';


import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupService } from '../../../../services/group.service';
import { SubjectService } from '../../../../services/subject.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StudentService } from '../../../../services/student.service';
import { AppConfig } from '../../../../services/utilities/app-config';
import { LessonService } from '../../../../services/lesson.service';
import { InstituteVM } from '../../../../viewmodels/InstituteVM';
import { InstituteService } from '../../../../services/institute.service';
import { TutorService } from '../../../../services/tutor.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationStudentVM, ProgressReportVM } from '../../../../viewmodels/NotificationStudentVM';
import { environment } from '../../../../../environments/environment';
import { ProgressTestReportViewComponent } from '../progress-test-report/progress-test-report.component';
import moment from 'moment';
import { Student } from '../../../../models/Student';
import { forEach } from 'lodash';
import { UserActivityService } from '../../../../services/user-activity.service';

declare var $: any;
declare global {
	interface Window {
		Apex: any;
	}
}

const BaseStudentFrontURL = environment.BaseStudentFrontURL;

@Directive()
@Component({
	selector: 'kt-progress-report',
	templateUrl: './progress-report.component.html',
	styleUrls: ['./progress-report.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ProgressReportViewComponent implements AfterViewInit {
	isLoading: boolean = false;
	isSubmitted: boolean = false;
	viewMode = 0;

	// @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
	@ViewChild('studentProficiencyChart', { static: false }) studentProficiencyChart: ElementRef;
	@ViewChild('gaugeChart', { static: false }) gaugeChart: ElementRef;

	public ProgressTestReportViewComponent: MatDialogRef<ProgressTestReportViewComponent>;

	studentGaugeChartData = [{ data: [], value: 0, marker: 0 }];

	subjectGaugeChartsData = [];
	subjectTimeChartsData = [];

	

	testScores: any;
	testNames: any[] = [];
	subjectNames: any[] = [];
	topChapters: string[] = [];
	bottomChapters: string[] = [];

	sunburstSeries = {
		type: 'sunburst',
		data: [],
		radius: [0, '95%'],
		label: {
			overflow: 'breakAll',
		},
		emphasis: {
			focus: 'self'
		},
		levels: [
			{ radius: [0, '100%'], },
			{
				itemStyle: {
					color: '#e6e01e'
				},
				label: {
					rotate: 'radial',
					width: '25',
				}
			},
			{
				itemStyle: {
					color: '#5744e0'
				},
				label: {
					rotate: 0,
				}
			},
			{
				itemStyle: {
					color: '#2c255e'
				},
				label: {
					rotate: 0,
					position: "outside"
				}
			}
		]
	}

	sunburstChartOptions: EChartsOption = {
		series:  {
			type: 'sunburst',
			data: [],
			radius: [0, '95%'],
			emphasis: {
				focus: 'ancestor'
			},
		},
	};
	sunburstChartData: {};

	barChartType: string = "horizontalBar";
	barChartTestLabels: string[] = [];
	barChartTestLegend = true;
	barChartTestOptions: any = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3,
		hover: {
			animationDuration: 0
		},
		scales: {
			yAxes: [{
				stacked: true
			}],
			xAxes: [{
				stacked: true
			}]
		},
		legend: {
			position: 'bottom',
			labels: {
				fontColor: '#212529',
				fontSize: 12,
				usePointStyle: true,
				padding: 30
			}
		}
	};
	barChartTestData = [{ data: [] }];

	chartTimeColors: Array<any> = [
		{
			borderColor: '#e6e01e',
			backgroundColor: '#e6e01e'
		},
		{
			borderColor: '#5744e0',
			backgroundColor: '#5744e0'
		},
		{
			borderColor: '#2c255e',
			backgroundColor: '#2c255e'
		},
		{
			borderColor: '#2cd9c5',
			backgroundColor: '#2cd9c5'
		},
		{
			borderColor: '#4cb8ff',
			backgroundColor: '#4cb8ff'
		},
		{
			borderColor: '#fa6400',
			backgroundColor: '#fa6400'
		},
		{
			borderColor: '#f7b500',
			backgroundColor: '#f7b500'
		},
		{
			borderColor: '#6dd400',
			backgroundColor: '#6dd400'
		},
		{
			borderColor: '#b620e0',
			backgroundColor: '#b620e0'
		},
		
		{
			borderColor: '#ff2700',
			backgroundColor: '#ff2700'
		},
		{
			borderColor: '#000086',
			backgroundColor: '#000086'
		},
		{
			borderColor: '#a67257',
			backgroundColor: '#a67257'
		},
		{
			borderColor: '#ff00c5',
			backgroundColor: '#ff00c5'
		},
		{
			borderColor: '#395B53',
			backgroundColor: '#395B53'
		},
		{
			borderColor: '#4F2629',
			backgroundColor: '#4F2629'
		},
		{
			borderColor: '#318165',
			backgroundColor: '#318165'
		},
		{
			borderColor: '#D0785E',
			backgroundColor: '#D0785E'
		},
		{
			borderColor: '#E8573F',
			backgroundColor: '#E8573F'
		},
		{
			borderColor: '#A3C797',
			backgroundColor: '#A3C797'
		}, {
			borderColor: '#43122B',
			backgroundColor: '#43122B'
		},
		{
			borderColor: '#F59424',
			backgroundColor: '#F59424'
		}
	];

	lineChartType: ChartType = "line";
	lineChartTimeLabels: string[] = [];
	lineChartTimeLegend = true;
	lineChartTimeOptions: any = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3,
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'Proficiency (%)',
					fontColor: "#000000",
					fontSize: 16,
					fontStyle: 'bold',
					
				},
				
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: '',
					fontColor: "#000000",
					fontSize: 16,
					fontStyle: 'bold',
					
				},
				
			}]
		},
		legend: {
			position: 'bottom',
			labels: {
				fontColor: '#212529',
				fontSize: 12,
				usePointStyle: true,
				padding: 15
			}
		},
		tooltips: {
			backgroundColor: 'rgba(255,255,255,0.9)',
			borderColor: '#999',
			borderWidth: 1,
			titleFontColor: '#000',
			titleMarginBottom: 10,
			titleFontSize: 14,
			//titleFontStyle: 'bold',
			//bodyFontStyle: '400',
			bodyFontColor: '#999',
			bodyFontSize: 14
		}
	};
	lineChartTimeData = [{ data: [] }];

 

	gaugeChartType: string = "gauge";
	//gaugeChartColors: Color[] = [{ pointRadius: 7 }];
	//gaugeChartColors1: Color[] = [{ pointRadius: 7 }];
	gaugeChartColors: any = [{ backgroundColor: ['#5254cf', '#c8c9ff'] }];
	gaugeChartOptions = {
		responsive: true,
		layout: {
			padding: {
				top: 20,
				bottom: 30
			}
		},
		needle: {
			radiusPercentage: 2,
			widthPercentage: 3.2,
			lengthPercentage: 100,
			color: 'rgba(0, 0, 0, 1)'
		},
		valueLabel: {
			formatter: Math.round
		}
	};

	notificationLink: string;
	instituteID?: number;
	progressReport: ProgressReportVM;

	constructor(private fb: UntypedFormBuilder, private commonService: CommonService, private storageService: StorageService, private router: Router,
		private dashboardService: DashboardService, private cdr: ChangeDetectorRef, private courseService: CourseService, private userActivityService: UserActivityService,
		private _formBuilder: UntypedFormBuilder, private groupService: GroupService, private subjectService: SubjectService, private tutorService: TutorService,
		private instituteService: InstituteService, private studentService: StudentService, private appConfig: AppConfig,
		private notificationService: NotificationService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {

		this.progressReport = new ProgressReportVM();
	}

	ngOnInit() {
		let self = this;

		this.activatedRoute.params.subscribe((ele) => {
			this.notificationLink = ele.link;
			this.instituteID = +ele.id;
		});

		self.isLoading = true;
		self.validateKey();
		
	}

	ngAfterViewInit(): void {
		let self = this;
		//self.setGaugeColor();
	}


	setGaugeColor() {
		//var ctx1 = this.studentProficiencyChart.nativeElement.getContext('2d');
		//const gradient = ctx1.createLinearGradient(50, 0, ctx1.canvas.width / 1.5, 0);
		//gradient.addColorStop(0, 'rgba(255,255,0,0.25)');
		//gradient.addColorStop(1, 'rgba(160,32,240,0.25)');

		//this.gaugeChartColors = [{ backgroundColor: gradient }]

		//var ctx2 = this.gaugeChart.nativeElement.getContext('2d');
		//const gradient1 = ctx2.createLinearGradient(50, 0, ctx2.canvas.width / 1.5, 0);
		//gradient1.addColorStop(0, 'rgba(255,255,0,0.25)');
		//gradient1.addColorStop(1, 'rgba(160,32,240,0.25)');

		//this.gaugeChartColors1 = [{ backgroundColor: gradient1 }]
	}

	

	validateKey() {
		const self = this;
		self.commonService.showLoader();
		//this.commonService.getAccessToken
		self.notificationService.getByNotificationLink(self.instituteID, self.notificationLink).subscribe(data => {
			if (data.success == true) {
				let notification = data.data as NotificationStudentVM;
				if (notification != null) {
					if (notification.isValid == false || notification.notificationType != "PROGRESS" || notification.progressReport == null) {
						self.commonService.showToaster("Sorry, your request in no longer valid. Please contact your Institute admin for more information.", "error");
						self.commonService.redirectToLoginPage(false);
					}
					else {
						this.userActivityService.saveUserActivity("Reports", "Progress Report", "", "", "", "", notification.studentID, notification.instituteID);

						self.progressReport = notification.progressReport;
						if (self.progressReport != null) {
							self.studentGaugeChartData = [{ data: [100, self.progressReport.studentProficiency], value: self.progressReport.studentProficiency, marker: self.progressReport.averageProficiency }]
						}

						for (var i = 0; i < self.progressReport.subjectsProficiency.length; i++) {
							var subProf = self.progressReport.subjectsProficiency[i];
							var subChartData = [{ data: [100, subProf.efficiency], value: subProf.efficiency, marker: subProf.averageEfficiency }];
							self.subjectGaugeChartsData.push({ data: subChartData, name: subProf.name });
						}


						self.testScores = {};
						let datasets = [];
						for (var i = 0; i < self.progressReport.subjects.length; i++) {
							let testData = [];
							for (var j = 0; j < self.progressReport.tests.length; j++) {
								const subjectID = self.progressReport.subjects[i].itemID;
								const testID = self.progressReport.tests[j].itemID;
								const testItem = self.progressReport.testScores.find(f => f.subjectID == subjectID && f.testID == testID);
								if (testItem != null && testItem !== undefined) {
									self.testScores["S" + subjectID + "T" + testID] = testItem.percentage + "%";
									testData.push(testItem.percentage != null ? testItem.percentage : "");
								}
								else {
									self.testScores["S" + subjectID + "T" + testID] = "";
									testData.push(0);
								}

							}

							const dataset = { data: testData, label: self.progressReport.subjects[i].itemName, backgroundColor: this.chartTimeColors[i].backgroundColor };
							datasets.push(dataset);
						}

					
						self.barChartTestLabels = self.progressReport.tests.map(f => f.itemValue);
						self.barChartTestData = datasets;

						self.generateTimeBased();
						self.generateSubjectTimeBased();

						var chapterCount = self.progressReport.chaptersProficiency.length - 1;
						var chapters = self.progressReport.chaptersProficiency.slice(0, Math.min(5, chapterCount));
						for (var i = 0; i < chapters.length; i++) {
							const cp = chapters[i];
							let chapterSubject = this.progressReport.subjects.find(f => f.itemID == cp.parentID);
							let chapterName = chapterSubject.itemName + " - " + cp.name;
							self.topChapters.push(chapterName);
						}

						chapters = self.progressReport.chaptersProficiency.slice(chapterCount - 5, chapterCount);
						for (var i = 0; i < chapters.length; i++) {
							const cp = chapters[i];
							let chapterSubject = this.progressReport.subjects.find(f => f.itemID == cp.parentID);
							let chapterName = chapterSubject.itemName + " - " + cp.name;
							self.bottomChapters.push(chapterName);
						}

						self.generateSunburstData();
					}
				}

				self.isLoading = false;
				if (!(this.cdr as ViewRef).destroyed) {
					this.cdr.detectChanges()
				}
				self.commonService.hideLoader();

				// if (self.chart != null) {
				// 	self.setGaugeColor();

				// 	self.chart.ngOnDestroy();
				// 	// self.chart.chart = this.chart.getChartBuilder(this.chart.ctx);
				// }
				// this.chart.chart.update();
			}
			else {
				self.commonService.showToaster(data.message, "error");
				self.commonService.hideLoader();
				self.isLoading = false;
				self.commonService.redirectToLoginPage(false);
			}

			
		}, (err) => {
			self.isLoading = false;
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

	generateSunburstData() {
		const self = this;
		const items = [];
		for (var i = 0; i < self.progressReport.subjectsProficiency.length; i++) {
			var subjectItem = {};
			var sp = self.progressReport.subjectsProficiency[i];
			subjectItem["id"] = sp.id;
			subjectItem["name"] = sp.name;
			subjectItem["value"] = sp.efficiency;
			subjectItem["itemStyle"] = { color: this.chartTimeColors[i].backgroundColor };

			var chapters = self.progressReport.chaptersProficiency.filter(f => f.parentID == sp.id);
			var chapterItems = [];
			for (var j = 0; j < chapters.length; j++) {
				var chapterItem = {};
				var cp = chapters[j];
				chapterItem["id"] = cp.id;
				chapterItem["name"] = cp.name;
				chapterItem["value"] = cp.efficiency;
				chapterItem["itemStyle"] = { color: this.chartTimeColors[this.chartTimeColors.length - 1].backgroundColor };

				//var topics = self.progressReport.topicsProficiency.filter(f => f.parentID == cp.id);
				//var topicItems = [];
				//for (var k = 0; k < topics.length; k++) {
				//	var topicItem = {};
				//	var tp = self.progressReport.topicsProficiency[k];
				//	topicItem["id"] = tp.id;
				//	topicItem["name"] = tp.name;
				//	topicItem["value"] = tp.efficiency;
				//	topicItem["itemStyle"] = { color: this.chartTimeColors[this.chartTimeColors.length - 3].backgroundColor };
				//	topicItems.push(topicItem);
				//}
				//chapterItem["children"] = topicItems;
				chapterItems.push(chapterItem);
			}

			subjectItem["children"] = chapterItems;
			items.push(subjectItem);
		}

		this.sunburstSeries.data = items;
		this.sunburstChartData = {
			series: this.sunburstSeries
		}; 
	}

	generateTimeBased() {
		const self = this;
		let dataset = [];

		self.lineChartTimeLabels = self.progressReport.timeBasedEfficiency.map(f => moment(f.date).format('DD MMM YYYY'));

		const studentData = self.progressReport.timeBasedEfficiency.map(f => f.studentEfficiency);
		const averageData = self.progressReport.timeBasedEfficiency.map(f => f.averageEfficiency);
		const topperData = self.progressReport.timeBasedEfficiency.map(f => f.topperEfficiency);

		let data = {
			data: studentData,
			label: "Student",
			fill: false,
			lineTension: 0,
			order: 1,
			borderColor: self.chartTimeColors[0].borderColor,
			backgroundColor: self.chartTimeColors[0].backgroundColor,
		}
		dataset.push(data);

		let data1 = {
			data: averageData,
			label: "Avg",
			fill: false,
			lineTension: 0,
			order: 2,
			borderColor: self.chartTimeColors[1].borderColor,
			backgroundColor: self.chartTimeColors[1].backgroundColor,
		}
		dataset.push(data1);

		let data2 = {
			data: topperData,
			label: "Topper",
			fill: false,
			lineTension: 0,
			order: 3,
			borderColor: self.chartTimeColors[2].borderColor,
			backgroundColor: self.chartTimeColors[2].backgroundColor,
		}
		dataset.push(data2);

		
		self.lineChartTimeData = dataset;
	}

	generateSubjectTimeBased() {
		const self = this;


		const subjects = self.progressReport.subjects;
		for (var i = 0; i < subjects.length; i++) {
			let dataset = [];

			var subjectID = subjects[i].itemID;

			const labels = self.progressReport.subjectTimeBasedEfficiency.filter(f => f.subjectID == subjectID).map(f => moment(f.date).format('DD MMM YYYY'));
			const studentData = self.progressReport.subjectTimeBasedEfficiency.filter(f => f.subjectID == subjectID).map(f => f.studentEfficiency);
			const averageData = self.progressReport.subjectTimeBasedEfficiency.filter(f => f.subjectID == subjectID).map(f => f.averageEfficiency);
			const topperData = self.progressReport.subjectTimeBasedEfficiency.filter(f => f.subjectID == subjectID).map(f => f.topperEfficiency);

			let data = {
				data: studentData,
				label: "Student",
				fill: false,
				lineTension: 0,
				order: 1,
				borderColor: self.chartTimeColors[0].borderColor,
				backgroundColor: self.chartTimeColors[0].backgroundColor,
			}
			dataset.push(data);

			let data1 = {
				data: averageData,
				label: "Avg",
				fill: false,
				lineTension: 0,
				order: 2,
				borderColor: self.chartTimeColors[1].borderColor,
				backgroundColor: self.chartTimeColors[1].backgroundColor,
			}
			dataset.push(data1);

			let data2 = {
				data: topperData,
				label: "Topper",
				fill: false,
				lineTension: 0,
				order: 3,
				borderColor: self.chartTimeColors[2].borderColor,
				backgroundColor: self.chartTimeColors[2].backgroundColor,
			}
			dataset.push(data2);

			self.subjectTimeChartsData.push({ data: dataset, name: subjects[i].itemName, labels });
		}	
	}

	viewSubject(subjectID) {
		const self = this;
		let subjectName = '';
		let testData: any[] = [];
		const subjectItem = this.progressReport.subjects.find(f => f.itemID == subjectID);
		if (subjectItem !== undefined) {
			subjectName = subjectItem.itemName;

			for (var j = 0; j < self.progressReport.tests.length; j++) {
				const testID = self.progressReport.tests[j].itemID;
				const testItem = self.progressReport.testScores.find(f => f.subjectID == subjectID && f.testID == testID);
				if (testItem != null && testItem !== undefined) {
					testData.push(testItem.percentage);
				}
				else {
					testData.push(0);
				}
			}
		}


		const dialogConfig = new MatDialogConfig();
		dialogConfig.maxWidth = "700px";
		dialogConfig.width = "700px";
		dialogConfig.height = "auto";


		dialogConfig.data = {
			instituteID: this.instituteID,
			testData: testData,
			testNames: self.progressReport.tests.map(f=> f.itemValue),
			subjectName: subjectName
		};

		self.ProgressTestReportViewComponent = self.dialog.open(ProgressTestReportViewComponent, dialogConfig);
	}
}
