// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .kt-aside {
  height: 100%;
}
:host .kt-aside .kt-aside-menu {
  margin: 0;
}
:host .kt-menu__link-text {
  white-space: nowrap;
}

svg {
  width: 20px;
  height: 20px;
  fill: #ea2242;
  margin-right: 7px;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
  background: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/aside/aside-left.component.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AACQ;EACI,SAAA;AACZ;AAGI;EACI,mBAAA;AADR;;AAKA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AAFJ;;AAKA;EACI,kCAAA;AAFJ","sourcesContent":[":host {\r\n    .kt-aside {\r\n        height: 100%;\r\n        .kt-aside-menu {\r\n            margin: 0;\r\n        }\r\n    }\r\n    // fixed text line break issue on minimized aside hover\r\n    .kt-menu__link-text {\r\n        white-space: nowrap;\r\n    }\r\n}\r\n\r\nsvg {\r\n    width: 20px;\r\n    height: 20px;\r\n    fill: #ea2242;\r\n    margin-right: 7px;\r\n}\r\n\r\n.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {\r\n    background: transparent !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
