export enum TestCaller {
    DASHBOARD = "DASHBOARD",
    TESTMGTCURRENT = "TEST-MGT-CURRENT",
    TESTMGTPAST = "TEST-MGT-PAST",
    TUTORWALL = "TUTOR-WALL",
    STUDENTWALL = "STUDENT-WALL",
    TUTORRESULT = "TUTOR-RESULT",
    COURSE = "COURSE",
    STUDENTREMINDER = "STUDENT-REMINDER",
    STUDENTONGOING = "STUDENT-ONGOING",
    STUDENTUPCOMING = "STUDENT-UPCOMING",
    STUDENTCOMPLETED = "STUDENT-COMPLETED"
}