// Angular
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewEncapsulation, ViewRef, Directive } from '@angular/core';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from '../../../core/_base/layout';
// Auth
import { AuthNoticeService } from '../../../core/auth';
import { CommonService } from '../../../services/utilities/common.service';
import { StorageService } from '../../../services/utilities/storage.service';
import { ActivatedRoute } from '@angular/router';
import { InstituteService } from '../../../services/institute.service';
import { InstituteVM } from '../../../viewmodels/InstituteVM';
import { Title } from '@angular/platform-browser';

@Directive()
@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	companyName: string = "";

	isAdminMode: boolean = false;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private titleService: Title,
		private splashScreenService: SplashScreenService,
		private instituteService: InstituteService,
		private storageService: StorageService,
		private commonService: CommonService,
		private route: ActivatedRoute, private cdr: ChangeDetectorRef) {

	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.translationService.setLanguage(this.translationService.getSelectedLanguage());
		this.splashScreenService.hide();

		var key = this.storageService.getWhiteLabelLogin();
		if (this.commonService.isNullOrEmpty(key) == false) {
			this.validateKey(key);
		}
		else {
			this.companyName = "Brainflex 360";
			this.headerLogo = this.layoutConfigService.getLogo();
		}

	}

	validateKey(key: string) {
		const self = this;
		self.commonService.showLoader();
		self.instituteService.checkKeyExists(this.commonService.InstituteKeyName, key).subscribe(data => {
			if (data.success == true) {
				const institute = data.data as InstituteVM;
				if (institute != null) {
					self.headerLogo = institute.logoName;
					self.companyName = institute.instituteName;
					self.titleService.setTitle(self.companyName);

					if (!(this.cdr as ViewRef).destroyed) {
						this.cdr.detectChanges()
					}
				}

				self.commonService.hideLoader();
			}


		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}

	onAdminMode() {
		this.storageService.showAdminMode(true);
	}
}
