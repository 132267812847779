//@Packages
import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, Directive } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//@Services
import { CommonService } from '../../../../services/utilities/common.service';

@Directive()
@Component({
  selector: 'kt-material-preview',
  templateUrl: './material-preview.component.html',
  styleUrls: ['./material-preview.component.scss']
})
export class MaterialPreviewComponent implements OnInit {

  url: any;
  fileExtension: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private _dialogRef: MatDialogRef<MaterialPreviewComponent>,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
  ) {
    //REVIEW
    let url = this.data.url;
    this.fileExtension = this.data.fileExtension; 
    if (url != undefined && url != null && url != '') {

      if (this.commonService.isNullOrEmpty(this.fileExtension)) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url + "#toolbar=0");
      } else {
        switch (this.commonService.lower(this.fileExtension)) {
          case ".doc":
          case ".docx":
          case ".xls":
          case ".xlsx":
          case ".ppt":
          case ".pptx":
            //NOTE : This is a url for preview microsoft's file such as word, excel, powerpoint etc..
            //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://view.officeapps.live.com/op/embed.aspx?src=${url}`);
            //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://view.officeapps.live.com/op/embed.aspx?src=http://api.testing.brainflex360.com/Upload/Topic/1216/Material/04_Electric_field-theory-ii202003240930368762020061711261568220200618164154607.doc`);
            
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${url}&embedded=true#toolbar=0`);
            //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=http://api.testing.brainflex360.com/Upload/Topic/1216/Material/04_Electric_field-theory-ii202003240930368762020061711261568220200618164154607.doc&embedded=true#toolbar=0`);
            break;
          default:
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url + "#toolbar=0");
            break;
        }      
      }
    }

    //https://view.officeapps.live.com/op/embed.aspx?src=https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.docx
  }

  ngOnInit() {
  }

  closeModal() {
    this._dialogRef.close();
  }

}
