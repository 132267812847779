import { StudentLessonActivityVM } from "../StudentLessonActivityVM";
import { StudentAdvancedAnalysisVM } from "./StudentAdvancedAnalysisVM";
import { StudentAssignmentDataVM } from "./StudentAssignmentDataVM";
import { StudentLessonInfoVM } from "./StudentLessonInfoVM";

export class StudentWallVM {
    studentID: number;
    instituteID: number;
    userID: number;
    studentName: string;
    genderTypeTerm: string;
    email: string;
    mobileNo: string;
    profilePicture: string;
    profilePictureURL?: string;
    currentStatusTerm: string;
    subjectNames: string;
    courseNames: string;
    brainflexAdminRank: number;
    efficiencyLevel: number;
    courses: StudentWallCourseSubjectInfoVM[] = [];
    chapterAnalysisList: StudentAdvancedAnalysisVM[] = [];
	practiceList: StudentLessonInfoVM[] = [];
	assignments: StudentAssignmentDataVM[] = [];
	lessonActivities: StudentLessonActivityVM[] = [];
}

export class StudentWallCourseSubjectInfoVM {
    courseID: number;
    courseName: string;
    subjectID: number;
    subjectName: string;
    tutorNames: string;
    noOfCompletedPercentage: number;
    efficiencyLevel: number;
    isChecked: boolean = false;
}
