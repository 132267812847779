import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MathjaxComponent } from './mathjax/mathjax.component';
import { MathTextDirective } from '../../../directives/math-text.directive';
import { GlobalService } from '../../../services/utilities/global.service';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import { DragDropDirective } from '../../../directives/drag-drop.directive';
import { ImageURLBindPipe } from './../../../pipes/image-urlbind.pipe';
import { IsImageContentPipePipe } from './../../../pipes/is-image-content-pipe.pipe';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { LatexPreviewComponent } from './latex-preview/latex-preview.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../../../core/auth';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { OnlyIntegerDirective } from '../../../directives/only-integer.directive';
import { OnlyNumericDirective } from '../../../directives/only-numeric.directive';
// import { CropAttachmentComponent } from './crop-attachment/crop-attachment.component';

// import { ImageCropperModule } from 'ngx-image-cropper';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { TruncateStringPipe } from '../../../pipes/truncate-string.pipe';
import { IntegerDirective } from '../../../directives/integer.directive';

import { IndianCurrencyFormatPipe } from './../../../pipes/indian-currency-format.pipe';
import { RangesFooter } from './ranges-footer/ranges-footer.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

import { MaterialPreviewComponent } from './material-preview/material-preview.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SortableColumnComponent } from './sortable-table/sortable-column/sortable-column.component';
import { SortService } from './sortable-table/sort.service';
import { SortableTableDirective } from './sortable-table/sortable-table.directive';
import { NgOtpInputModule } from 'ng-otp-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddReportQuestionComponent } from './add-report-question/add-report-question.component';
import { ContactMaskPipe } from '../../../pipes/contact-mask.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [MathjaxComponent,
    MathTextDirective,
    DragDropDirective,
    PaginationComponent,
    ImageURLBindPipe,
    IsImageContentPipePipe,
    LatexPreviewComponent,
    OnlyIntegerDirective,
    OnlyNumericDirective,
    // CropAttachmentComponent,
    UnauthorizeComponent,
    TruncateStringPipe,
    ContactMaskPipe,
    IntegerDirective,
    IndianCurrencyFormatPipe,
    RangesFooter,
    MaterialPreviewComponent,
    SortableColumnComponent,
    SortableTableDirective,
    AddReportQuestionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    TranslateModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    // ImageCropperModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgOtpInputModule,
    InfiniteScrollModule,
    RouterModule.forChild([]),
  ],
  exports: [
    CommonModule,
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    MathjaxComponent,
    MathTextDirective,
    DragDropDirective,
    PaginationComponent,
    ImageURLBindPipe,
    IsImageContentPipePipe,
    LatexPreviewComponent,
    OnlyIntegerDirective,
    OnlyNumericDirective,
    // CropAttachmentComponent,
    TruncateStringPipe,
    ContactMaskPipe,
    IntegerDirective,
    IndianCurrencyFormatPipe,
    NgxMaterialTimepickerModule,
    SortableColumnComponent,
    SortableTableDirective,
    AddReportQuestionComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        GlobalService,
        SortService,
        {
          provide: MAT_DIALOG_DEFAULT_OPTIONS,
          useValue: {
            disableClose: true,
            hasBackdrop: true
          }
        }],
    }
  }
}
