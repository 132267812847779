// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './views/pages/shared/change-password/change-password.component'; 
import { StudentViewComponent } from './views/pages/student_management/student-view/student-view.component';
import { RegisterInstituteComponent } from './views/pages/register/institute/register.component';
import { RegisterStudentComponent } from './views/pages/register/student/student-register.component';
import { StudentReportViewComponent } from './views/pages/reports/student-report/student-report.component';
import { ProgressReportViewComponent } from './views/pages/reports/progress-report/progress-report.component';
import { ProgressTestReportViewComponent } from './views/pages/reports/progress-test-report/progress-test-report.component';
import { NewRegisterInstituteComponent } from './views/pages/register/institute/register-new.component';

const routes: Routes = [
	{ path: '', redirectTo:'institute', pathMatch:'full' },
	{ path: 'institute', loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule) },

	{
		path: 'institute/register/cfi-2022',
		component: RegisterInstituteComponent
	},

	{
		path: 'institute/register',
		component: NewRegisterInstituteComponent
	},

	{
		path: 'students/register/cfi-2022/:id',
		component: RegisterStudentComponent
	},

	{
		path: 'students/reports/:id/:link',
		component: StudentReportViewComponent
	},

	{
		path: 'students/progress/:id/:link',
		component: ProgressReportViewComponent
	},

	{
		path: 'students/progress/test',
		component: ProgressTestReportViewComponent
	},

	{
		path: 'institute',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'change-password',
				component: ChangePasswordComponent 
			},  
			//{
			//	path: 'notification',
			//	loadChildren: () => import('../app/views/pages/notification/notification.module').then(m=> m.NotificationModule),
			//},
			{
				path: 'dashboard',
				loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'configurations',
				loadChildren: () => import('../app/views/pages/configurations/configurations.module').then(m => m.ConfigurationsModule),
			},
			{
				path: 'quickstart',
				loadChildren: () => import('../app/views/pages/quickstart/quickstart.module').then(m => m.QuickStartModule),
			},
			{
				path: 'course',
				loadChildren: () => import('../app/views/pages/course/course.module').then(m => m.CourseModule),
			},
			{
				path: 'doubts',
				loadChildren: () => import('../app/views/pages/doubts/doubts.module').then(m => m.DoubtsModule),
			},
			{
				path: 'subscription-detail',
				loadChildren: () => import('../app/views/pages/subscription/subscription.module').then(m => m.SubscriptionModule),
			},
			{
				path: 'role',
				loadChildren: () => import('./views/pages/user_management/user.module').then(m => m.UserModule),
			},
			{
				path: 'group',
				loadChildren: () => import('./views/pages/group_management/group.module').then(m => m.GroupModule),
			},
			{
				path: 'student',
				loadChildren: () => import('./views/pages/student_management/student.module').then(m => m.StudentModule),
			},
			{
				path: 'user-master',
				loadChildren: () => import('./views/pages/user_master/user-master.module').then(m=>m.UserMasterModule),
			},
			{
				path: 'test-master',
				loadChildren: () => import('../app/views/pages/test_management/test-master.module').then(m => m.TestMasterModule),
			},
			{
				path: 'unauthorize',
				loadChildren: () => import('./views/pages/unauthorize/unauthorize.module').then(m => m.UnauthorizeModule),
			},
			{
				path: 'tutor',
				loadChildren: () => import('./views/pages/tutor_management/tutor-management.module').then(m => m.TutorManagementModule),
			},
			{
				path: 'my-data',
				loadChildren: () => import('./views/pages/my_data/my-data.module').then(m => m.MyDataModule),
			},
			{
				path: 'notifications',
				loadChildren: () => import('./views/pages/notification/notification.module').then(m => m.NotificationModule),
			},
			
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
				},
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: 'error/403', pathMatch: 'full' },
			{ path: '**', redirectTo: 'error/403', pathMatch: 'full' },
		],
	},

	//{ path: '', redirectTo: 'student-view', pathMatch: 'full' },
	//{
	//	path: 'student-view/:id',
	//	component: StudentViewComponent,
	//},

	{
		path: 'students/student-wall/:id',
		component: StudentViewComponent
	},

	


	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: '403... Access forbidden',
			desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
		},
	},
	{ path: 'error/:type', component: ErrorPageComponent },
	{ path: '', redirectTo: 'error/403', pathMatch: 'full' },
	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' }, 
	
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
