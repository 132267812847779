// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
@media (max-width: 800px) {
  .list-pagination {
    flex-direction: column;
  }
}
.list-pagination .list-show-result {
  display: flex;
  width: 13%;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 800px) {
  .list-pagination .list-show-result {
    width: 70%;
  }
}
.list-pagination .totalShowEntry p {
  margin-bottom: auto;
}
@media (max-width: 800px) {
  .list-pagination .totalShowEntry p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.list-pagination ul.pagination {
  margin-bottom: 0 !important;
}

ul {
  margin-bottom: 0 !important;
}

select.form-control {
  height: auto !important;
  padding: 4px 5px !important;
  line-height: 1 !important;
  width: 60px !important;
  min-width: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/shared/pagination/pagination.component.scss"],"names":[],"mappings":"AAYA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;AAXJ;AAEI;EAKJ;IAMQ,sBAAA;EATN;AACF;AAUI;EACI,aAAA;EAEA,UAAA;EACA,mBAAA;EACA,6BAAA;AATR;AATI;EAaA;IAOQ,UAAA;EAPV;AACF;AASI;EACI,mBAAA;AAPR;AAjBI;EAuBA;IAGQ,gBAAA;IACA,mBAAA;EALV;AACF;;AAUI;EACI,2BAAA;AAPR;;AAWA;EACI,2BAAA;AARJ;;AAWA;EACI,uBAAA;EACA,2BAAA;EACA,yBAAA;EACA,sBAAA;EACA,0BAAA;AARJ","sourcesContent":["@mixin min-mq($min-screen-size) {\r\n    @media (min-width: $min-screen-size +\"px\") {\r\n        @content;\r\n    }\r\n}\r\n\r\n@mixin max-mq($max-screen-size) {\r\n    @media (max-width: $max-screen-size +\"px\") {\r\n        @content;\r\n    }\r\n}\r\n\r\n.list-pagination {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px 0px;\r\n    @include max-mq(800) {\r\n        flex-direction: column;\r\n    }\r\n    .list-show-result {\r\n        display: flex;\r\n        // width: 20%;\r\n        width: 13%;\r\n        align-items: center;\r\n        justify-content: space-around;\r\n        @include max-mq(800) {\r\n            width: 70%;\r\n        }\r\n    }\r\n    .totalShowEntry p {\r\n        margin-bottom: auto;\r\n        @include max-mq(800) {\r\n            margin-top: 15px;\r\n            margin-bottom: 15px;\r\n        }\r\n    }\r\n}\r\n\r\n.list-pagination {\r\n    ul.pagination {\r\n        margin-bottom: 0 !important;\r\n    }\r\n}\r\n\r\nul {\r\n    margin-bottom: 0 !important;\r\n}\r\n\r\nselect.form-control {\r\n    height: auto !important;\r\n    padding: 4px 5px !important;\r\n    line-height: 1 !important;\r\n    width: 60px !important;\r\n    min-width: auto !important\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
