// Angular
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation, ViewRef, Directive } from '@angular/core';
import {  ChartOptions, ChartType } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexLegend, ApexDataLabels, ApexTitleSubtitle,
	ApexPlotOptions, ApexYAxis
} from "ng-apexcharts";

import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { CourseService } from '../../../../services/course.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupService } from '../../../../services/group.service';
import { SubjectService } from '../../../../services/subject.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StudentService } from '../../../../services/student.service';
import { AppConfig } from '../../../../services/utilities/app-config';
import { LessonService } from '../../../../services/lesson.service';
import { InstituteService } from '../../../../services/institute.service';
import { StudentVM } from '../../../../viewmodels/StudentVM';
import { environment } from '../../../../../environments/environment';
import { InstituteVM } from '../../../../viewmodels/InstituteVM';

declare var $: any;
declare global {
	interface Window {
		Apex: any;
	}
}

@Directive()
@Component({
	selector: 'kt-student-register',
	templateUrl: './student-register.component.html',
	styleUrls: ['student-register.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class RegisterStudentComponent implements OnInit {
	isLoading: boolean = false;

	viewMode = 0;
	frmRegister: UntypedFormGroup;
	instituteKey: string;
	resultInstitute: InstituteVM;

	constructor(private fb: UntypedFormBuilder, private commonService: CommonService, private storageService: StorageService, private router: Router,
		private cdr: ChangeDetectorRef, private courseService: CourseService,
		private _formBuilder: UntypedFormBuilder, private groupService: GroupService, private subjectService: SubjectService, 
		private instituteService: InstituteService, private studentService: StudentService, private appConfig: AppConfig,
		private lessonService: LessonService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {

	}

	ngOnInit() {
		let self = this;

		this.activatedRoute.params.subscribe((ele) => {
			this.instituteKey = ele.id ;
		})

		self.buildForm();
		self.validateKey();
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.frmRegister.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	getStudentUrl() {
		return environment.BaseStudentFrontURL;
	}

	buildForm() {

		const model = new StudentVM();

		this.frmRegister = this.fb.group({
			studentName: [model.studentName, Validators.maxLength(320)],
			mobileNo: [model.mobileNo, [Validators.maxLength(13), Validators.pattern('[- +()0-9]+')]],
			email: [model.email, [Validators.maxLength(100), Validators.email]],
		});
	}

	validateKey() {
		const self = this;
		self.commonService.showLoader();
		//this.commonService.getAccessToken
		self.instituteService.checkKeyExists(this.commonService.InstituteKeyName, self.instituteKey).subscribe(data => {
			if (data.success == true) {
				self.resultInstitute = data.data as InstituteVM;
				if (self.resultInstitute == null) {
					self.commonService.showToaster("Sorry, cannot find institute for student registration. Please contact your Institute admin for correct registration link", "error");
					self.commonService.redirectToLoginPage(false);
				}
				else {
					if (!(this.cdr as ViewRef).destroyed) {
						this.cdr.detectChanges()
					}
				}

				self.commonService.hideLoader();
			}
			else {
				self.commonService.showToaster(data.message, "error");
				self.commonService.hideLoader();
			}


		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

	registerStudent() {
		const self = this;

		const controls = self.frmRegister.controls;

		let isFormValid = true;
		if (self.frmRegister.invalid) {

			Object.keys(controls).forEach(controlName => {
				controls[controlName].markAsTouched();
			});
			isFormValid = false;
		}

		if (isFormValid === false) {
			self.commonService.showToaster("Please enter all the details", "error");
			return;
		}

		let student = self.frmRegister.value as StudentVM;
		student.instituteID = self.resultInstitute.instituteId;


		self.commonService.showLoader();

		self.studentService.register(student).subscribe(data => {
			if (data.success == true) {
				self.resultInstitute = data.data as InstituteVM;
				if (self.resultInstitute != null) {
					self.viewMode = 1;
				}
			}
			else {
				self.commonService.showToaster(data.message, "error");
			}
			self.commonService.hideLoader();
			if (!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges()
			}

		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

}
