import { Component, OnInit, ViewChild, Inject, ViewRef, ChangeDetectorRef, Directive } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';
import { DateAdapter, ThemePalette } from '@angular/material/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//@ViewModels
import { UpdateTestExamTimeVM } from '../../../../viewmodels/TestMaster/UpdateTestExamTimeVM';

//@Service
import { StorageService } from '../../../../services/utilities/storage.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { TestMasterService } from '../../../../services/test-master.service';

//@enum
import { ApiResult } from '../../../../viewmodels/common/ApiResult';
import { AttachmentDataVM, AttachmentInputVM } from '../../../../viewmodels/AttachmentDataVM';
import { AttachmentService } from '../../../../services/attachment.service';
import { DocumentFileTypes } from '../../../../enum/DocumentFileTypes';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChartType } from 'ng-apexcharts';



@Directive()
@Component({
	selector: 'kt-progress-test-report',
	templateUrl: './progress-test-report.component.html',
	styleUrls: ['./progress-test-report.component.scss']

})
export class ProgressTestReportViewComponent implements OnInit {

	date: moment.Moment;
	/*************************** Test Form Properties **************************/
	instituteID: number = 0;
	instituteUserID: number = 0;

	subjectName: string;
	testData: any[] = [];


	lineChartType: ChartType = "line";
	lineChartTestLabels: string[] = [];
	lineChartTestLegend = true;
	lineChartTestOptions:any = {
		responsive: true,
		maintainAspectRatio: false,
		aspectRatio: 3,
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: 'Proficiency (%)',
					fontColor: "#000000",
					fontSize: 16,
					fontStyle: 'bold',
				},
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: '',
					fontColor: "#000000",
					fontSize: 16,
					fontStyle: 'bold',
				},
			}]
		},
		legend: {
			position: 'bottom',
			labels: {
				fontColor: '#212529',
				fontSize: 12,
				usePointStyle: true,
				padding: 30
			}
		},
		tooltips: {
			backgroundColor: 'rgba(255,255,255,0.9)',
			borderColor: '#999',
			borderWidth: 1,
			titleFontColor: '#000',
			titleMarginBottom: 10,
			titleFontSize: 14,
			//titleFontStyle: 'bold',
			//bodyFontStyle: '400',
			bodyFontColor: '#999',
			bodyFontSize: 14
		}
	};
	lineChartTestData = [{ data: [] }];

	chartTimeColors: Array<any> = [
		{
			borderColor: '#e6e01e',
			backgroundColor: '#e6e01e'
		},
		{
			borderColor: '#5744e0',
			backgroundColor: '#5744e0'
		},
		{
			borderColor: '#2c255e',
			backgroundColor: '#2c255e'
		},
		{
			borderColor: '#2cd9c5',
			backgroundColor: '#2cd9c5'
		},
		{
			borderColor: '#4cb8ff',
			backgroundColor: '#4cb8ff'
		},
		{
			borderColor: '#fa6400',
			backgroundColor: '#fa6400'
		},
		{
			borderColor: '#f7b500',
			backgroundColor: '#f7b500'
		},
		{
			borderColor: '#6dd400',
			backgroundColor: '#6dd400'
		},
		{
			borderColor: '#b620e0',
			backgroundColor: '#b620e0'
		},

		{
			borderColor: '#ff2700',
			backgroundColor: '#ff2700'
		},
		{
			borderColor: '#000086',
			backgroundColor: '#000086'
		},
		{
			borderColor: '#a67257',
			backgroundColor: '#a67257'
		},
		{
			borderColor: '#ff00c5',
			backgroundColor: '#ff00c5'
		},
		{
			borderColor: '#395B53',
			backgroundColor: '#395B53'
		},
		{
			borderColor: '#4F2629',
			backgroundColor: '#4F2629'
		},
		{
			borderColor: '#318165',
			backgroundColor: '#318165'
		},
		{
			borderColor: '#D0785E',
			backgroundColor: '#D0785E'
		},
		{
			borderColor: '#E8573F',
			backgroundColor: '#E8573F'
		},
		{
			borderColor: '#A3C797',
			backgroundColor: '#A3C797'
		}, {
			borderColor: '#43122B',
			backgroundColor: '#43122B'
		},
		{
			borderColor: '#F59424',
			backgroundColor: '#F59424'
		}
	];



	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private cdr: ChangeDetectorRef,
		private dialogRef: MatDialogRef<ProgressTestReportViewComponent>,
		private fb: UntypedFormBuilder,
		private storageService: StorageService,
		private commonService: CommonService,
		private attachmentService: AttachmentService,
		private router: Router, private sanitizer: DomSanitizer,
		private dateAdapter: DateAdapter<Date>,
	) {
		this.dateAdapter.setLocale('en-GB');
		this.instituteID = parseInt(this.storageService.getInstituteID());
		this.instituteUserID = +this.storageService.getUserId() || 0;
		this.subjectName = data.subjectName;
		this.lineChartTestLabels = data.testNames;
		this.testData = data.testData;
	}

	ngOnInit() {
		let self = this;
		let data = {
			data: self.testData,
			label: self.subjectName,
			fill: false,
			lineTension: 0,
			order: 1,
			borderColor: self.chartTimeColors[0].borderColor,
			backgroundColor: self.chartTimeColors[0].backgroundColor,
		}
		var dataset = [];
		dataset.push(data);
		self.lineChartTestData = dataset;
	}

	closemodel(result) {
		this.dialogRef.close({ data: result });
	}

	refresh() {
		if (!(this.cdr as ViewRef).destroyed) {
			this.cdr.detectChanges();
		}
	}

	ngOnDestroy() {
		this.cdr.detach();
	}
}
