import { ChangeDetectorRef, Component, Inject, OnInit, ViewRef, Directive } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectTermCategory } from '../../../../enum/ProjectTermCategory';
import { ProjecttermService } from '../../../../services/projectterm.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { ApiResult } from '../../../../viewmodels/common/ApiResult';
import { ProjectTermVM } from '../../../../viewmodels/ProjectTermVM';

@Directive()
@Component({
  selector: 'kt-add-report-question',
  templateUrl: './add-report-question.component.html',
  styleUrls: ['./add-report-question.component.scss']
})
export class AddReportQuestionComponent implements OnInit {

  projectTermList: ProjectTermVM[] = [];
  comment: any;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private commonService: CommonService,
    private projecttermservice: ProjecttermService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddReportQuestionComponent>) { }

  ngOnInit() {
    this.getReportQuestion();
  }

  getReportQuestion() {
    let self = this;
    self.commonService.showLoader();
    self.projecttermservice.getProjectTermByCategoryName(ProjectTermCategory.StudentReportTerm).subscribe((result: ApiResult) => {

      if (result.success == true) {
        self.projectTermList = result.data as ProjectTermVM[];

      } else {
        if (!self.commonService.isNullOrEmpty(result.message))
          self.commonService.showToaster(result.message, "error");
      }

      self.commonService.hideLoader();
      if (!(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges()
      }
    }, (error) => {
      console.log(error);
      self.commonService.hideLoader();
      self.commonService.showToaster(error.error, "error");
    });
  }

  cancelmodel() {
    let self = this;
    let obj = {
      isCancel: true,
      comment: "",
      selectedTermIDs: ""
    }
    self.dialogRef.close(obj);
  }

  saveReport(isReplace) {
    let self = this;
    let selectedTermIDs = self.projectTermList.filter(x => x.isChecked === true).map(s => s.termID).join(',');
    let obj = {
		isCancel: false,
		isReplace: isReplace,
      comment: self.comment,
      selectedTermIDs: selectedTermIDs
    }

    self.projectTermList.forEach(term => {
      term.isChecked = false;
    });

    this.dialogRef.close(obj);
  }
}
