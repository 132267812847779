// Angular
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation, ViewRef, Directive } from '@angular/core';
// import { ChartOptions, ChartType } from 'chart.js';
// import { BaseChartDirective } from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexLegend, ApexDataLabels, ApexTitleSubtitle,
	ApexPlotOptions, ApexYAxis
} from "ng-apexcharts";

import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { CourseService } from '../../../../services/course.service';


import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupService } from '../../../../services/group.service';
import { SubjectService } from '../../../../services/subject.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StudentService } from '../../../../services/student.service';
import { AppConfig } from '../../../../services/utilities/app-config';
import { LessonService } from '../../../../services/lesson.service';
import { InstituteVM } from '../../../../viewmodels/InstituteVM';
import { InstituteService } from '../../../../services/institute.service';
import { TutorService } from '../../../../services/tutor.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationStudentVM } from '../../../../viewmodels/NotificationStudentVM';
import { environment } from '../../../../../environments/environment';
import { UserActivityService } from '../../../../services/user-activity.service';

declare var $: any;
declare global {
	interface Window {
		Apex: any;
	}
}

const BaseStudentFrontURL = environment.BaseStudentFrontURL;

@Directive()
@Component({
	selector: 'kt-student-report',
	templateUrl: './student-report.component.html',
	styleUrls: ['student-report.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class StudentReportViewComponent implements OnInit {
	isLoading: boolean = false;
	isSubmitted: boolean = false;
	viewMode = 0;

	notificationLink: string;
	instituteID?: number;

	constructor(private fb: UntypedFormBuilder, private commonService: CommonService, private storageService: StorageService, private router: Router,
		private dashboardService: DashboardService, private cdr: ChangeDetectorRef, private userActivityService: UserActivityService,
		private _formBuilder: UntypedFormBuilder, private groupService: GroupService, private subjectService: SubjectService, private tutorService: TutorService,
		private instituteService: InstituteService, private studentService: StudentService, private appConfig: AppConfig,
		private notificationService: NotificationService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {

	}

	ngOnInit() {
		let self = this;

		this.activatedRoute.params.subscribe((ele) => {
			this.notificationLink = ele.link;
			this.instituteID = +ele.id;
		});

		self.validateKey();
	}

	validateKey() {
		const self = this;
		self.commonService.showLoader();
		//this.commonService.getAccessToken
		self.notificationService.getByNotificationLink(self.instituteID, self.notificationLink).subscribe(data => {
			if (data.success == true) {
				let notification = data.data as NotificationStudentVM;
				if (notification != null) {
					if (notification.isValid == false) {
						self.commonService.showToaster("Sorry, your request in no longer valid. Please contact your Institute admin for more information.", "error");
						self.commonService.redirectToLoginPage(false);
					}
					else {
						this.userActivityService.saveUserActivity("Reports", "Student Report", "", "", "TestMasterID", notification.testID, notification.studentID, notification.instituteID);
						let newGenerateID = self.commonService.generateNewGUID();
						const url = `${BaseStudentFrontURL}student-test/institute-student-test-result?institute=${self.instituteID}&test=${notification.testID}&student=${notification.studentID}&user=${notification.userID}&studentName=${notification.displayName}&key=${newGenerateID}&t=${notification.token}`;
						window.location.href = url;
					}
				}
				self.commonService.hideLoader();
			}
			else {
				self.commonService.showToaster(data.message, "error");
				self.commonService.hideLoader();
			}


		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}
}
