// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { CommonService } from '../../../../services/utilities/common.service';
//Enum
import { PageCode } from '../../../../enum/PageCode';


@Injectable()
export class MenuAsideService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(private menuConfigService: MenuConfigService,
		private storageService: StorageService,
		private commonService: CommonService) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
		let menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
 
		const questionMenuPrivilege = this.storageService.getPageAcess(PageCode.QUESTION);
		const questionVerifyMenuPrivilege = this.storageService.getPageAcess(PageCode.QUESTION_VERIFY);
		const questionPublishMenuPrivilege = this.storageService.getPageAcess(PageCode.QUESTION_PUBLISH);
		const roleMenuPrivilege = this.storageService.getPageAcess(PageCode.ROLE);
		const userMenuPrivilege = this.storageService.getPageAcess(PageCode.USER);
		const groupMenuPrivilege = this.storageService.getPageAcess(PageCode.GROUP);
		const tutorMenuPrivilege = this.storageService.getPageAcess(PageCode.TUTOR);
		const studentMenuPrivilege = this.storageService.getPageAcess(PageCode.STUDENT);
		const examMenuPrivilege = this.storageService.getPageAcess(PageCode.EXAM);
		const testTemplateMenuPrivilege = this.storageService.getPageAcess(PageCode.TEST_TEMPLATE);
		const testTemplateCloneMenuPrivilege = this.storageService.getPageAcess(PageCode.TEST_TEMPLATE_CLONE);
		const testMenuPrivilege = this.storageService.getPageAcess(PageCode.TEST);
		const testCloneMenuPrivilege = this.storageService.getPageAcess(PageCode.TEST_CLONE); 
		
		const chapterMenuPrivilege = this.storageService.getPageAcess(PageCode.CHAPTER);
		const conceptMenuPrivilege = this.storageService.getPageAcess(PageCode.CONCEPT);
		
		if ((questionMenuPrivilege != null && questionMenuPrivilege != undefined)
			|| (questionVerifyMenuPrivilege != null && questionVerifyMenuPrivilege != undefined)
			|| (questionPublishMenuPrivilege != null && questionPublishMenuPrivilege != undefined)) {

			if (questionMenuPrivilege.isRead == false
				&& questionVerifyMenuPrivilege.isRead == false
				&& questionPublishMenuPrivilege.isRead == false) {
				menuItems = menuItems.filter(c => c.pageCode != PageCode.QUESTION);
			}

		}
 
		if (tutorMenuPrivilege != null && tutorMenuPrivilege != undefined) {
			if (tutorMenuPrivilege.isRead == false) {
				menuItems = menuItems.filter(c => c.pageCode != PageCode.TUTOR);
			}
		}

		if (studentMenuPrivilege != null && studentMenuPrivilege != undefined) {
			if (studentMenuPrivilege.isRead == false) {
				menuItems = menuItems.filter(c => c.pageCode != PageCode.STUDENT);
			}
		}

		if (groupMenuPrivilege != null && groupMenuPrivilege != undefined) {
			if (groupMenuPrivilege.isRead == false) {
				menuItems = menuItems.filter(c => c.pageCode != PageCode.GROUP);
			}
		}

		if (chapterMenuPrivilege != null && chapterMenuPrivilege != undefined) {
			if (chapterMenuPrivilege.isRead == false) {
				menuItems = menuItems.filter(c => c.pageCode != PageCode.CHAPTER);
			}
		}

		if (conceptMenuPrivilege != null && conceptMenuPrivilege != undefined) {
			if (conceptMenuPrivilege.isRead == false) {
				menuItems = menuItems.filter(c => c.pageCode != PageCode.CONCEPT);
			}
		}

		/*************************************child menu of exam management *************************************/
		if (examMenuPrivilege != null && examMenuPrivilege != undefined) {
			if (examMenuPrivilege.isRead == false) {
				menuItems.forEach((ele) => {
					if (!this.commonService.isEmptyArray(ele.submenu)) {
						ele.submenu = ele.submenu.filter(c => c.pageCode != PageCode.EXAM);
					}
				});
			}
		}

		if ((testTemplateMenuPrivilege != null && testTemplateMenuPrivilege != undefined)
			|| (testTemplateCloneMenuPrivilege != null && testTemplateCloneMenuPrivilege != undefined)) {

			if (testTemplateMenuPrivilege.isRead == false && testTemplateCloneMenuPrivilege.isRead == false) {
				menuItems.forEach((ele) => {
					if (!this.commonService.isEmptyArray(ele.submenu)) {
						ele.submenu = ele.submenu.filter(c => c.pageCode != PageCode.TEST_TEMPLATE);
					}
				});
			}
		}

		if ((testMenuPrivilege != null && testMenuPrivilege != undefined)
		|| (testCloneMenuPrivilege != null && testCloneMenuPrivilege != undefined)) {

		if (testMenuPrivilege.isRead == false && testCloneMenuPrivilege.isRead == false) {
			menuItems.forEach((ele) => {
				if (!this.commonService.isEmptyArray(ele.submenu)) {
					ele.submenu = ele.submenu.filter(c => c.pageCode != PageCode.TEST);
				}
			});
		}
	}

		if (roleMenuPrivilege != null && roleMenuPrivilege != undefined) {
			if (roleMenuPrivilege.isRead == false) {
				menuItems.forEach((ele) => {
					if (!this.commonService.isEmptyArray(ele.submenu)) {
						ele.submenu = ele.submenu.filter(c => c.pageCode != PageCode.ROLE);
					}
				});
			}
		}


		// if (roleMenuPrivilege != null && roleMenuPrivilege != undefined) {
		// 	if (roleMenuPrivilege.isRead == false) {
		// 		menuItems.forEach((ele) => {
		// 			if (!this.commonService.isEmptyArray(ele.submenu)) {
		// 				ele.submenu = ele.submenu.filter(c => c.pageCode != PageCode.ROLE);
		// 			}
		// 		});
		// 	}
		// }

		if (userMenuPrivilege != null && userMenuPrivilege != undefined) {
			if (userMenuPrivilege.isRead == false) {
				menuItems.forEach((ele) => {
					if (!this.commonService.isEmptyArray(ele.submenu)) { 
						ele.submenu = ele.submenu.filter(c => c.pageCode != PageCode.USER);
					}
				})
			}
		}
		/******************** User Management *****************/


		//  let check parent has submenu
		let parentMenuHasNoAnySubmenuAccess = [];
		menuItems.forEach((ele, index) => {
			if (ele.hasSubMenu == true) {
				if (this.commonService.isEmptyArray(ele.submenu)) {
					parentMenuHasNoAnySubmenuAccess.push(ele);
				}
			}
		});


		if (!this.commonService.isNullOrEmpty(parentMenuHasNoAnySubmenuAccess)) {
			parentMenuHasNoAnySubmenuAccess.forEach((ele) => {
				if (ele.hasSubMenu == true) {
					menuItems = menuItems.filter(c => c.title != ele.title && ele.submenu.length == 0)
				}
			});
		}
		this.menuList$.next(menuItems);
	}
}
