// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert.alert-success {
  background: #71bb4e !important;
  border: 1px solid #71bb4e !important;
  color: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/auth/auth-notice/auth-notice.component.scss"],"names":[],"mappings":"AACA;EACI,8BAAA;EACA,oCAAA;EACA,yBAAA;AAAJ","sourcesContent":["\r\n.alert.alert-success {\r\n    background: #71bb4e !important;\r\n    border: 1px solid #71bb4e !important;\r\n    color: #ffffff !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
