import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild, NgZone, HostListener, Directive } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuAsideService, MenuOptions, OffcanvasOptions, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { MatDialog } from '@angular/material/dialog';
import { PageName } from '../../../enum/PageName';
import { StorageService } from '../../../services/utilities/storage.service';
import { CommonService } from '../../../services/utilities/common.service';

declare var $: any;


@Directive()
@Component({
	selector: 'kt-aside-left',
	templateUrl: './aside-left.component.html',
	styleUrls: ['./aside-left.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLeftComponent implements OnInit, AfterViewInit {

	asideDisplay: boolean;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
	};

	@ViewChild('asideMenu', { static: true }) asideMenu: ElementRef;

	currentRouteUrl = '';
	insideTm: any;
	outsideTm: any;

	menuCanvasOptions: OffcanvasOptions = {
		baseClass: 'kt-aside',
		overlay: true,
		closeBy: 'kt_aside_close_btn',
		toggleBy: {
			target: 'kt_aside_mobile_toggler',
			state: 'kt-header-mobile__toolbar-toggler--active'
		}
	};

	menuOptions: MenuOptions = {
		// vertical scroll
		scroll: null,

		// submenu setup
		submenu: {
			desktop: {
				// by default the menu mode set to accordion in desktop mode
				default: 'dropdown',
			},
			tablet: 'accordion', // menu set to accordion in tablet mode
			mobile: 'accordion' // menu set to accordion in mobile mode
		},

		// accordion setup
		accordion: {
			expandAll: false // allow having multiple expanded accordions in the menu
		}
	};

	/**
	 * Component Conctructor
	 *
	 * @param htmlClassService: HtmlClassService
	 * @param menuAsideService
	 * @param layoutConfigService: LayouConfigService
	 * @param router: Router
	 * @param render: Renderer2
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		public htmlClassService: HtmlClassService,
		public menuAsideService: MenuAsideService,
		public layoutConfigService: LayoutConfigService,
		private storageService: StorageService,
		private router: Router,
		private render: Renderer2,
		private cdr: ChangeDetectorRef,
		private ngZone: NgZone,
		public dialog: MatDialog,
		private eRef: ElementRef,
		private commonService : CommonService
	) {

	}

	ngAfterViewInit(): void {
	}

	ngOnInit() {
		this.subscribeToEvents();

		this.menuAsideService.loadMenu();

		this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
				this.cdr.markForCheck();
			});

		const config = this.layoutConfigService.getConfig();

		if (objectPath.get(config, 'aside.menu.dropdown')) {
			this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1');
			// tslint:disable-next-line:max-line-length
			this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown-timeout', objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'));
		}
	}

	/**
	 * Check Menu is active
	 * @param item: any
	 */
	isMenuItemIsActive(item): boolean {
		if (item.submenu) {
			return this.isMenuRootItemIsActive(item);
		}

		if (!item.page) {
			return false;
		}
		let currentUrl = this.router.url;

		if (item.title == PageName.ExamTitle) {
			if (currentUrl.indexOf(PageName.ExamList) !== -1 || currentUrl.indexOf(PageName.ExamAdd) !== -1 || currentUrl.indexOf(PageName.ExamEdit) !== -1) {
				return true;
			}
		}
		else if (item.title == PageName.TestTemplateTitle) {
			if (currentUrl.indexOf(PageName.TestTemplateList) !== -1 || currentUrl.indexOf(PageName.TestTemplateAdd) !== -1 || currentUrl.indexOf(PageName.TestTemplateEdit) !== -1) {
				return true;
			}
		}
		else if (item.title == PageName.TestTitle) {
			if (currentUrl.indexOf(PageName.TestList) !== -1 || currentUrl.indexOf(PageName.TestAdd) !== -1 || currentUrl.indexOf(PageName.TestEdit) !== -1) {
				return true;
			}
		}
		else if (item.title == PageName.RoleTitle) {
			if (currentUrl.indexOf(PageName.RoleList) !== -1 || currentUrl.indexOf(PageName.RoleAdd) !== -1 || currentUrl.indexOf(PageName.RoleEdit) !== -1) {
				return true;
			}
		}
		else if (item.title == PageName.SchoolTitle) {
			if (currentUrl.indexOf(PageName.SchoolList) !== -1 || currentUrl.indexOf(PageName.SchoolAdd) !== -1 || currentUrl.indexOf(PageName.SchoolEdit) !== -1) {
				return true;
			}
		}
		else if (item.title == PageName.UserTitle) {
			if (currentUrl.indexOf(PageName.UserList) !== -1 || currentUrl.indexOf(PageName.UserAdd) !== -1 || currentUrl.indexOf(PageName.UserEdit) !== -1) {
				return true;
			}
		}
		else {
			return this.currentRouteUrl.indexOf(item.page) !== -1;
		}

	}

	/**
	 * Check Menu Root Item is active
	 * @param item: any
	 */
	isMenuRootItemIsActive(item): boolean {
		let result = false;

		for (const subItem of item.submenu) {
			result = this.isMenuItemIsActive(subItem);
			if (result) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseEnter(e: Event) {
		// check if the left aside menu is fixed
		if (document.body.classList.contains('kt-aside--fixed')) {
			if (this.outsideTm) {
				clearTimeout(this.outsideTm);
				this.outsideTm = null;
			}

			this.insideTm = setTimeout(() => {
				// if the left aside menu is minimized
				if (document.body.classList.contains('kt-aside--minimize') && KTUtil.isInResponsiveRange('desktop')) {
					// show the left aside menu
					this.render.removeClass(document.body, 'kt-aside--minimize');
					this.render.addClass(document.body, 'kt-aside--minimize-hover');
				}
			}, 50);
		}
	}

	/**
	 * Use for fixed left aside menu, to show menu on mouseenter event.
	 * @param e Event
	 */
	mouseLeave(e: Event) {
		if (document.body.classList.contains('kt-aside--fixed')) {
			if (this.insideTm) {
				clearTimeout(this.insideTm);
				this.insideTm = null;
			}

			this.outsideTm = setTimeout(() => {
				// if the left aside menu is expand
				if (document.body.classList.contains('kt-aside--minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
					// hide back the left aside menu
					this.render.removeClass(document.body, 'kt-aside--minimize-hover');
					this.render.addClass(document.body, 'kt-aside--minimize');
				}
			}, 100);
		}
	}

	/**
	 * Returns Submenu CSS Class Name
	 * @param item: any
	 */
	getItemCssClasses(item) {
		let classes = 'kt-menu__item';

		if (objectPath.get(item, 'submenu')) {
			classes += ' kt-menu__item--submenu';
		}

		if (!item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--active kt-menu__item--here';
		}

		if (item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--open kt-menu__item--here';
		}

		// custom class for menu item
		const customClass = objectPath.get(item, 'custom-class');
		if (customClass) {
			classes += ' ' + customClass;
		}

		if (objectPath.get(item, 'icon-only')) {
			classes += ' kt-menu__item--icon-only';
		}

		return classes;
	}

	getItemAttrSubmenuToggle(item) {
		let toggle = 'hover';
		if (objectPath.get(item, 'toggle') === 'click') {
			toggle = 'click';
		} else if (objectPath.get(item, 'submenu.type') === 'tabs') {
			toggle = 'tabs';
		} else {
			// submenu toggle default to 'hover'
		}

		return toggle;
	}

	// openStickyDialog(header) {
	// 	const dialogRef = this.dialog.open(StickyNoteDialogComponent, { data: { headerTitle: header } });
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (!res) {
	// 			return;
	// 		}
	// 	});
	// }

	subscribeToEvents() {
		let self = this;
		self.storageService.refreshMenus.subscribe((data) => {
			this.ngZone.run(() => {
				if (data != null && data != undefined && data == true) { 
					self.menuAsideService.loadMenu();
				}
			});
		});

		self.storageService.showHideHamburgerEvent.subscribe((data) => {
			this.ngZone.run(() => {
				//   if (data == false) { 
				// 	$("#kt_aside").removeClass("kt-aside--on");
				//   } 
				$("#kt_aside").removeClass("kt-aside--on");
			});
		});
	}

	hidePannel() {
		//class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop kt-aside--on"
		$("#kt_aside").removeClass("kt-aside--on");
	}



	@HostListener('document:click', ['$event'])
	clickout(event) {
		let menuButtonId = event.target.id;
		let menuClassName = event.target.classList 	as [];
		
		
		if(!this.commonService.isEmptyArray(menuClassName)){
			let getIndexOfClass = -1 ; 
				menuClassName.forEach((ele,ind)=>{
					if(ele ==  'parent_menu_link_title'){
						getIndexOfClass = ind as number;
					}
				}); 
			 
			if(getIndexOfClass == -1 || getIndexOfClass == undefined){
 
				if (menuButtonId == 'kt_aside_mobile_toggler') {
					$("#kt_aside").addClass("kt-aside--on");
					$("#kt_aside").addClass("kt-aside--on");
				}
				else if (menuButtonId == 'kt_aside_close_btn') {
					$("#kt_aside").removeClass("kt-aside--on");
					$("#kt_aside").removeClass("kt-aside--on--check");
				}
				else {
					if (this.eRef.nativeElement.contains(event.target)) {
						$("#kt_aside").addClass("kt-aside--on");
						$("#kt_aside").addClass("kt-aside--on");
					}
					else {
						this.handleMenuShowHideEvent();
						let checkClassIsExist = $("#kt_aside").hasClass("kt-aside--on--check");
	
						if ($("#kt_aside").hasClass("kt-aside--on--check")) {
	
							$("#kt_aside").removeClass("kt-aside--on");
							$("#kt_aside").removeClass("kt-aside--on--check");
						}
					}
				}
			}
			else{	
					$("#kt_aside").removeClass("kt-aside--on");
					$("#kt_aside").removeClass("kt-aside--on--check"); 
			}
		}
		
		 

	}

	handleMenuShowHideEvent() {
		let checkClassIsExist = $("#kt_aside").hasClass("kt-aside--on--check");

		if (!$("#kt_aside").hasClass("kt-aside--on--check")) {
			$("#kt_aside").addClass("kt-aside--on--check");
		}

	}


}
