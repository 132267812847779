
export class InstituteVM {
	instituteId?: number;
	instituteCode: string;
	instituteName: string;
	instituteKey: string;
	contactName: string;
	email: string;
	mobileNo: string;
	coursesEnrolled?: number;
	logoName: string;
	courseID?: number;
	isChecked: boolean;
	courses: number[] = [];
	type: number;
}
