export enum PageCode {
    //Module: LMS
    COURSE = "COURSE",
    COURSE_CLONE = "COURSE_CLONE",
    SUBJECT = "SUBJECT",
    CHAPTER = "CHAPTER",
    CONCEPT = "CONCEPT",

    //Module: Question Bank
    QUESTION = "QUESTION",
    QUESTION_VERIFY = "QUESTION_VERIFY",
    QUESTION_PUBLISH = "QUESTION_PUBLISH",

    //Module: User Management
    ROLE = "ROLE",
    USER = "USER",

    //Module: Group Management
    GROUP = "GROUP",

    //Module: Tutor Management
    TUTOR = "TUTOR",

    //Module: Student Management
    STUDENT = "STUDENT",

    //Module: Exam Management
    EXAM = "EXAM",
    TEST_TEMPLATE = "TEST_TEMPLATE",
    TEST_TEMPLATE_CLONE = "TEST_TEMPLATE_CLONE",    
	TEST = "TEST",
	TEST_CLONE = "TEST_CLONE",
	
    //Module: 
    TUTOR_COURSE_VIEW="TUTOR_COURSE_VIEW",
    TUTOR_COURSE_LIST="TUTOR_COURSE_LIST",

    //Module: Subscription
    SUBSCRIPTION = "SUBSCRIPTION",
    SCHOOL = "SCHOOL",

    STUDENT_COURSE_MANAGEMENT="STUDENT_COURSE_MANAGEMENT",
    STUDENT_STUDENT_COURSE_VIEW="STUDENT_COURSE_VIEW",
    STUDENT_COURSE_LIST="STUDENT_COURSE_LIST",
    STUDENT_SUBJECT_VIEW="STUDENT_SUBJECT_VIEW",
    STUDENT_SIGNUP="STUDENT_SIGNUP",
    STUDENT_FORGOTPASSWORD="STUDENT_FORGOTPASSWORD",

    
    CHANGE_PASSWORD = "CHANGE_PASSWORD",

    DOUBTS = "DOUBTS",
}