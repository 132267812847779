// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
// Material
import { MatInputModule } from '@angular/material/input';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD 
// Module components
import { AuthGuard } from '../../../core/auth';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthService } from './../../../core/auth/_services/auth.service';
import { REGEXP } from '../shared/regexp/regexp';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InitialResetPasswordComponent } from './initial-reset-password/initial-reset-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SharedModule } from '../shared/shared.module';
import { StudentViewModule } from '../student-master/studentview.module';
import { RegisterInstituteModule } from '../register/register.module';
import { ReportModule } from '../reports/reports.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'auth/login',
				pathMatch: 'full'
			},
			{
				path: 'auth/login',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'auth/login/:id',
				component: LoginComponent,
				data: { returnUrl: window.location.pathname }
			},
			{
				path: 'auth/initial-reset-password',
				component: InitialResetPasswordComponent,
			},

			{
				path: 'auth/verify-email',
				component: VerifyEmailComponent,
			},
			{
				path: 'auth/forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'auth/reset-password',
				component: ResetPasswordComponent,
			},
			// { 
			// 	path: '**', 
			// 	redirectTo: 'auth/login',
			// 	pathMatch: 'full' 
			// },
		]
	}
];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatRadioModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		SharedModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', []),
		EffectsModule.forFeature([]),
		StudentViewModule,
		RegisterInstituteModule,
		ReportModule,
		MatIconModule,
	],
	providers: [

		REGEXP,
		{
			provide: RECAPTCHA_SETTINGS,
			useValue: {
				siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' //'http://localhost:4200/auth/login',
			} as RecaptchaSettings,
		}
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		ResetPasswordComponent,
		InitialResetPasswordComponent,
		VerifyEmailComponent,
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard
			]
		};
	}
}
