import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { of } from 'rxjs';

const BaseInstituteURL = environment.BaseInstituteURL;
const BaseAdminURL = environment.AdminHostURL + "api/";

@Injectable({
	providedIn: 'root'
})
export class InstituteService {
	constructor(private http: HttpClient) { }

	register(model) {
		return this.http.post<ApiResult>(`${BaseAdminURL}Institute/Register`, model);
	}

	checkKeyExists(keyName: string, keyValue: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Institutes/CheckKeyExists?keyName=${keyName}&keyValue=${keyValue}`);
	}

	getAll() {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Institutes/GetAll`);
	}
}
