// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .kt-splash-screen {
  background-color: #f2f3f8;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
:host .kt-splash-screen img {
  margin-left: calc(100vw - 100%);
  width: 180px;
  margin-bottom: 30px;
}
:host .kt-splash-screen span {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
}
:host .kt-splash-screen ::ng-deep [role=progressbar] {
  margin-left: calc(100vw - 100%);
}
:host .kt-splash-screen ::ng-deep .mat-progress-spinner circle,
:host .kt-splash-screen ::ng-deep .mat-spinner circle {
  stroke: #4B37A5;
}`, "",{"version":3,"sources":["webpack://./src/app/views/partials/layout/splash-screen/splash-screen.component.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AAAR;AACQ;EACI,+BAAA;EACA,YAAA;EACA,mBAAA;AACZ;AACQ;EACI,+BAAA;EACA,mBAAA;AACZ;AAEY;EACI,+BAAA;AAAhB;AAEY;;EAGI,eAAA;AADhB","sourcesContent":[":host {\r\n    .kt-splash-screen {\r\n        background-color: #f2f3f8;\r\n        position: absolute;\r\n        display: flex;\r\n        flex-direction: column;\r\n        align-items: center;\r\n        justify-content: center;\r\n        height: 100%;\r\n        width: 100%;\r\n        z-index: 1000;\r\n        img {\r\n            margin-left: calc(100vw - 100%);\r\n            width: 180px;\r\n            margin-bottom: 30px;\r\n        }\r\n        span {\r\n            margin-left: calc(100vw - 100%);\r\n            margin-bottom: 30px;\r\n        }\r\n        ::ng-deep {\r\n            [role=\"progressbar\"] {\r\n                margin-left: calc(100vw - 100%);\r\n            }\r\n            .mat-progress-spinner circle,\r\n            .mat-spinner circle {\r\n                // brand color\r\n                stroke: #4B37A5;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
