import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { LoginResultVM } from '../../../viewmodels/LoginResultVM';
import { StorageService } from '../../../services/utilities/storage.service';
import { SuccessMessageVM } from '../../../viewmodels/common/SuccessMessageVM';
import { ApiResult } from '../../../viewmodels/common/ApiResult';
import { ApiVersion } from '../../../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';

@Injectable()
export class AuthService {
    // Create a stream of logged in status to communicate throughout app
    loggedIn: boolean = false;
    loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

    constructor(private http: HttpClient,
        private _storageService: StorageService) { }

    getHttpHeader() {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return httpHeaders;
    }

    login(userCredential) {
        return this.http.post<LoginResultVM>(`${BaseInstituteURL}${ApiVersion.V1}/account/Login`, userCredential, { headers: this.getHttpHeader() });
    }

    forgotPassword(userEmail) {
        let model = { email: userEmail };
        return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/account/ForgotPassword`, model, { headers: this.getHttpHeader() });
    }

    checkForgotPasswordDetail(userID) {
        return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/account/CheckForgotPasswordDetail?userID=${userID}`, { headers: this.getHttpHeader() });
    }

    checkInstituteUserName(userName) {
        return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/account/CheckInstituteUserName?userName=${userName}`, { headers: this.getHttpHeader() });
    }

    resetPassword(model) {
        return this.http.post<SuccessMessageVM>(`${BaseInstituteURL}${ApiVersion.V1}/account/ResetPassword`, model, { headers: this.getHttpHeader() });
    }

    initialResetPassword(model) {
        return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/account/InitialResetPassword`, model, { headers: this.getHttpHeader() });
    }

    refreshAccessToken(model) {
        return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/account/RefreshAccessToken`, model, { headers: this.getHttpHeader() });
    }

    doLogout(userId, logInLogId) {
        let self = this;
        return self.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/account/InstituteUserLogout?userId=${userId}&logInLogId=${logInLogId}`, { headers: this.getHttpHeader() })
    }

    setSession(authResult: LoginResultVM) {

        // Set tokens and expiration in localStorage and props
        this._storageService.setCredentials(authResult);

        // Update login status in loggedIn$ stream
        this.setLoggedIn(true);
    }

    clearSession() {
        let self = this;
        // Ensure all auth items removed from localStorage
        self._storageService.clearClientCredentials();

        // Reset local properties, update loggedIn$ stream    
        self.setLoggedIn(false);
    }


    setLoggedIn(value: boolean) {
        // Update login status subject
        this.loggedIn$.next(value);
        this.loggedIn = value;
    }
    public isAuthenticated(): boolean {
        const expiresAt = JSON.parse(this._storageService.getTokenExpireAt());
        return new Date().getTime() < expiresAt;
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    // getAllRoles(): Observable<Role[]> {
    //     return this.http.get<Role[]>(API_ROLES_URL);
    // }


    // // Check Role Before deletion
    // isRoleAssignedToUsers(roleId: number): Observable<boolean> {
    //     return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    // }
    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
