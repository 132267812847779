import { StudentAssignmentResultVM } from "./../../../../viewmodels/student/StudentAssignmentDataVM";
import { ChapterWiseProficiencyVMResultVM } from "../../../../viewmodels/student/ChapterWiseProficiencyDataVM";
import { ChapterWiseProficiencyDataVM } from "../../../../viewmodels/student/ChapterWiseProficiencyDataVM";
import { ChangeDetectorRef, Component, OnInit, HostListener, ViewChild, ViewRef, ViewEncapsulation, Directive, ElementRef } from "@angular/core";
// import { Slick } from "ngx-slickjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartDataset, ChartOptions, ChartConfiguration, ChartType } from "chart.js";

//@ViewModel
import { StudentWallVM, StudentWallCourseSubjectInfoVM } from "../../../../viewmodels/student/StudentWallVM";
import { StudentDiagnosisDataVM } from "../../../../viewmodels/student/StudentDiagnosisDataVM";
import { StudentDiscoveryDataVM } from "../../../../viewmodels/student/StudentDiscoveryDataVM";
import { StudentEfficiencyLevelDataByMonthsVM } from "../../../../viewmodels/student/StudentEfficiencyLevelDataByMonthsVM";
import { StudentAssignmentDataVM } from "../../../../viewmodels/student/StudentAssignmentDataVM";
import { StudentAdvancedAnalysisVM } from "../../../../viewmodels/student/StudentAdvancedAnalysisVM";
import { StudentLessonInfoVM, StudentLessonInfoListResultVM } from "../../../../viewmodels/student/StudentLessonInfoVM";

//@Service
import { AppConfig } from "../../../../services/utilities/app-config";
import { CommonService } from "../../../../services/utilities/common.service";
import { StudentService } from "../../../../services/student.service";
import { LessonService } from "../../../../services/lesson.service";
import { StorageService } from "../../../../services/utilities/storage.service";
// import { BaseChartDirective } from "ng2-charts";
import { TestMasterService } from "../../../../services/test-master.service";
import { TestListResultVM, TestListVM } from "../../../../viewmodels/TestMaster/TestListResultVM";
import { TestCaller } from "../../../../enum/TestCaller";
import { environment } from "../../../../../environments/environment";
import { StudentLessonActivityVM } from "../../../../viewmodels/StudentLessonActivityVM";
import { UserActivityService } from "../../../../services/user-activity.service";
import { InstituteUserPermissionVM } from "../../../../viewmodels/InstituteUserPermissionVM";
import { Slick } from "ngx-slickjs";
import Chart from 'chart.js/auto';

declare var $: any;
const BaseStudentFrontURL = environment.BaseStudentFrontURL;

// @Directive()
@Component({
	selector: "kt-student-view",
	templateUrl: "./student-view.component.html",
	styleUrls: ["./student-view.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class StudentViewComponent implements OnInit {
	@ViewChild("slickController", { static: true }) slickController: any;
	@ViewChild("slickModal") slickModal: any;
	// @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
	@ViewChild("tabGroup", { static: false }) tabGroup;
	@ViewChild('lineChartCanvas', { static: false }) lineChartCanvas!: ElementRef<HTMLCanvasElement>;

	/******************************  Common Properties ****************************/
	searchText: string = "";
	permission: InstituteUserPermissionVM;

	studentID: number;
	instituteID: number;
	tutorID: number = 0;
	groupIDs: string = "";
	subjectIDs: string = "";
	studentProfileImageURL: string = "";
	studentVM: StudentWallVM = new StudentWallVM();
	selectedCoursesIDs: number[] = [];
	selectedSubjectIDs: number[] = [];
	selectSubject = [];
	studentDefaultImageURL = this.appConfig.UserAvtarImage_URL;

	studentDiagnosisDataVM: StudentDiagnosisDataVM[] = [];
	studentDiscoveryDataVM: StudentDiscoveryDataVM[] = [];
	studentEfficiencyLevelDataByMonthsVM: StudentEfficiencyLevelDataByMonthsVM[] = [];

	areasOfStrengthAnalysisVM: StudentAdvancedAnalysisVM[] = [];
	areasOfImprovementAnalysisVM: StudentAdvancedAnalysisVM[] = [];

	noOfPagesOfAssignment: number = 1;
	assignmentPage = 1;
	assignmentPageSize: number = 10;
	assignmentSortColumn: string = "";
	assignmentSortDirection: string = "";
	assignmentZoneOffset: string = "-330";
	assignmentStatusTerm: string = "";
	isSearchSortCompletedAssignmentList = true;

	noOfPagesOfTest: number = 1;
	testPage = 1;
	testPageSize: number = 10;
	testSortColumn: string = "";
	testSortDirection: string = "";
	timeZoneOffset: string = "-330";
	testStatusTerm: string = "";
	tutorWallTestInfoVM: TestListVM[] = [];
	StudentAssignmentDataVM: StudentAssignmentDataVM[] = [];
	ChapterWiseProficiencyDataVM: ChapterWiseProficiencyDataVM[] = [];
	studentWallCourseSubjectInfo: StudentWallCourseSubjectInfoVM = new StudentWallCourseSubjectInfoVM();
	isSearchSortCompletedTestList = true;

	isSearchSortCompletedChapterList = true;

	assessmentSortColumn: string = "";
	assessmentSortDirection: string = "";

	noOfPagesOfPractice: number = 1;
	practicePage = 1;
	practicePageSize: number = 10;
	activitySortColumn: string = "";
	activitySortDirection: string = "";
	isSearchSortCompletedActivityList = true;
	studentLessonInfoVM: StudentLessonInfoVM[] = [];

	isStudentView: boolean = false;
	studentToken: string;
	chartDates: any = [];
	chart!: Chart;
	pageNum = 1;



	// SortColumn: string = "";
	// SortDirection: string = "";

	activeUrl: any;

	config: Slick.Config = {
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: true,
		autoplay: false,
		autoplaySpeed: 2000,
		mouseWheelMove: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	//#region Discovery Chart - Horizontal Bar Chart

	// public barDiscoveryChartOptions: ChartOptions = {
	// 	responsive: true,
	// scaleBeginAtZero:false,
	// barBeginAtOrigin:true
	// scales: {
	// 	xAxes: [
	// 		{
	// 			scaleLabel: {
	// 				display: true,
	// 				labelString: "Variance",
	// 			},
	// 			ticks: {
	// 				min: -100,
	// 				max: 100,
	// 			},
	// 		},
	// 	],
	// },
	// scales: {
	// 	xAxes: [{
	// 		display: true
	// 	}],
	// 	yAxes: [{
	// 		display: true
	// 	}]
	// }
	// tooltips: {
	// 	backgroundColor: "rgba(255,255,255,0.9)",
	// 	borderColor: "#999",
	// 	borderWidth: 1,
	// 	titleFontColor: "#000",
	// 	titleMarginBottom: 10,
	// 	titleFontSize: 14,
	// 	bodyFontColor: "#999",
	// 	bodyFontSize: 14,
	// 	callbacks: {
	// 		title: function (tooltipItem, chart) {
	// 			return "Lesson: " + chart.labels[tooltipItem[0].index];
	// 		},
	// 	},
	// },
	// };
	barDiscoveryChartType: ChartType = "bar";
	barDiscoveryChartLegend = true;
	barDiscoveryChartData: ChartDataset[] = [{ data: [], label: "" }];
	barDiscoveryChartLabels: string[] = [];
	chartDiscoveryColors: Array<any> = [
		{
			backgroundColor: "rgb(48,100,188,1)",
			borderColor: "rgba(48,100,188,1)",
			pointBackgroundColor: "rgba(48,100,188,1)",
			pointBorderColor: "#fff",
			pointHoverBackgroundColor: "#fff",
			pointHoverBorderColor: "rgba(48,100,188,1)",
		},
		{
			backgroundColor: "rgb(238,112,46,1)",
			borderColor: "rgba(238,112,46,1)",
			pointBackgroundColor: "rgba(238,112,46,1)",
			pointBorderColor: "#fafafa",
			pointHoverBackgroundColor: "#fafafa",
			pointHoverBorderColor: "rgba(238,112,46,1)",
		},
	];
	//#endregion

	//#region StudentEfficiencyLevel Chart - Horizontal Bar Chart
	dynHeight = 300;
	// public barStudentEfficiencyLevelChartOptions = {
	// 	responsive: true,
	// 	// scaleShowVerticalLines: false,
	// 	//maintainAspectRatio: false,
	// 	scales: {
	// 		yAxes: [
	// 			{
	// 				scaleLabel: {
	// 					display: true,
	// 					labelString: "Proficiency (%)",
	// 					fontColor: "#000000",
	// 					fontSize: 16,
	// 					fontStyle: "bold",
	// 					padding: {
	// 						bottom: 10,
	// 					},
	// 				},
	// 				ticks: {
	// 					padding: 10,
	// 					beginAtZero: true,
	// 					stepSize: 25,
	// 					callback: function (value, index, values) {
	// 						return value + "%";
	// 					},
	// 				},
	// 			},
	// 		],
	// 		xAxes: [
	// 			{
	// 				ticks: {
	// 					padding: 10,
	// 				},
	// 			},
	// 		],
	// 	},
	// 	legend: {
	// 		position: "top",
	// 		labels: {
	// 			fontColor: "#212529",
	// 			fontSize: 12,
	// 			usePointStyle: true,
	// 		},
	// 	},
	// 	tooltips: {
	// 		backgroundColor: "rgba(255,255,255,0.9)",
	// 		borderColor: "#999",
	// 		borderWidth: 1,
	// 		titleFontColor: "#000",
	// 		titleMarginBottom: 10,
	// 		titleFontSize: 14,
	// 		bodyFontColor: "#999",
	// 		bodyFontSize: 14,
	// 		callbacks: {
	// 			title: function (tooltipItem, chart) {
	// 				return "Month: " + chart.labels[tooltipItem[0].index];
	// 			},
	// 		},
	// 	},
	// };


	barStudentEfficiencyLevelChartType: ChartType = "line";
	barStudentEfficiencyLevelChartLegend = true;
	barStudentEfficiencyLevelChartData: ChartDataset[] = [
		// { data: [24], label: "savan" },
		// { data: [34], label: "nirali" },
		// { data: [34], label: "xyz" },
		{ data: [], label: "" },
		{ data: [], label: "" },
		{ data: [], label: "" },
	];
	barStudentEfficiencyLevelChartLabels: string[] = [];

	chartColors: Array<any> = [
		{
			borderColor: "rgba(48,100,188)",
		},
		{
			borderColor: "rgba(238,112,46)",
		},
		{
			borderColor: "rgba(0,128,0)",
		},
	];

	//#endregion

	qCourseId: number = 0;
	qSubjectId: any;
	qSource: string = "";
	qValue: number = 0;
	qSelectCourseId: any;
	qGroupID: string = "";
	canvas: any;
	groupName: any = ['OverAll Proficiency', 'Test Proficiency', 'Practice Proficiency'];
	constructor(
		private storageService: StorageService, private activatedRoute: ActivatedRoute, private commonService: CommonService, private studentService: StudentService, private userActivityService: UserActivityService, private lessonService: LessonService, private testMasterService: TestMasterService, private cdr: ChangeDetectorRef, private appConfig: AppConfig, private router: Router) {
		this.timeZoneOffset = this.storageService.getTimezoneOffset();
		this.permission = this.storageService.getUserPermission();
		this.storageService.showHideHamburger(false);
		this.instituteID = +this.storageService.getInstituteID() || 0;
		this.tutorID = +this.storageService.getTutorID() || 0;
		this.activatedRoute.params.subscribe((params) => {
			this.studentID = +params["id"] || 0;
		});
		this.activatedRoute.queryParams.subscribe((params) => {
			this.qCourseId = +params["CourseID"] || 0;
			this.qSource = params["Source"] || "";
			this.qValue = +params["Value"] || 0;
			this.qSubjectId = params["SubjectID"] || [];
			this.qSelectCourseId = params["SelectCourse"] || [];
			this.qGroupID = params["GroupID"] || "";
			this.studentToken = params["t"] || "";
		});
		this.activeUrl = this.activatedRoute.snapshot.queryParams;

		// pass institue_id studentwall on institue side sanket add
		if (this.activeUrl?.instituteId) {
			this.instituteID = this.activeUrl?.instituteId;
		}
		else {
			this.instituteID = +this.storageService.getInstituteID() || 0;
		}

		let studentWallIndexOf = this.activatedRoute.snapshot.routeConfig.path.indexOf("students/student-wall");
		if (studentWallIndexOf >= 0) {
			this.isStudentView = true;
			this.commonService.setTempToken(this.studentToken);
		}
		else {
			this.commonService.setTempToken("");
		}



	}

	ngOnInit(): void {
		this.activatedRoute.snapshot.queryParams;
		// this.subscribeEventForSelectedCourse(false);
		this.ngPageLoad();

		// static chart demo ss add
		// new Chart('canvas', {
		// 	type: 'line',
		// 	data: {
		// 		labels: ['Overall', 'Practice', 'Test'],
		// 		datasets: [
		// 			{
		// 				data: [24],
		// 				borderColor: '#3cba9f',
		// 				fill: false,
		// 			},
		// 			{
		// 				data: [20],
		// 				borderColor: '#ffcc00',
		// 				fill: false,
		// 			},
		// 			{
		// 				data: [15],
		// 				borderColor: '#ffcc00',
		// 				fill: false,
		// 			},
		// 		],
		// 	},
		// 	options: {
		// 		plugins: {
		// 			legend: {
		// 				display: false,
		// 			},
		// 		},
		// 		scales: {
		// 			x: {
		// 				ticks: {
		// 					display: true,
		// 				},
		// 			},
		// 			y: {
		// 				ticks: {
		// 					display: true,
		// 				},
		// 			},
		// 		},
		// 	},
		// });
	};


	@HostListener("window:scroll", ["$event"])
	onWindowScroll() {
		let self = this;

		if ($(window).scrollTop() == $(document).height() - $(window).height()) {
			let documentHeight = $(document).height();
			let windowScroll = window.innerHeight + window.scrollY;
			if (documentHeight - windowScroll < 1) {
				if (self.isSearchSortCompletedTestList) {
					if (self.testPage < self.noOfPagesOfTest) {
						self.testPage = self.testPage + 1;
					}
				}
				if (this.tabGroup.selectedIndex == 0) {
					self.onTestScroll();
				}
				// else if (this.tabGroup.selectedIndex == 3) {
				// 	self.onPracticeScroll();
				// }
			}
		}

	}

	initializeTimeChart(data): void {
		if (this.chart) {
			this.chart.destroy();
		}
		if (this.lineChartCanvas) {
			this.canvas = this.lineChartCanvas.nativeElement;
			const ctx = this.canvas.getContext('2d');

			let dataTemp = []
			this.groupName.map((el: any) => {
				dataTemp.push({
					label: el,
					data: [],
					fill: false,
					pointerRadius: 5
				})
			})

			const labelToPropertyMapping = {
				"OverAll Proficiency": "overAllEfficiencyPercentage",
				"Test Proficiency": "testEfficiencyPercentage",
				"Practice Proficiency": "practiceEfficiencyPercentage"
			};

			data.data.forEach(entry => {
				dataTemp.forEach(dataset => {
					const property = labelToPropertyMapping[dataset.label];
					if (property) {
						dataset.data.push(entry[property]);
					}
				});
			});

			const config: ChartConfiguration = {
				type: 'line',
				data: {
					labels: this.chartDates,
					datasets: dataTemp,
				},
				options: {
					responsive: false,
					// scales: {
					// 	x: [{
					// 		display: true,
					// 	}],

					// 	// {
					// 	// 	title: {
					// 	// 		display: true,
					// 	// 	}
					// 	// },
					// 	yAxes: [{
					// 		type: 'line',
					// 		scaleLabel: {

					// 			display: true,
					// 			labelString: 'Proficiency Level (%)',
					// 		},

					// 		// title: {
					// 		// 		font: {
					// 		// 		weight: 'bold'
					// 		// 	}
					// 		// },
					// 		// min: 0,
					// 		ticks: {
					// 			callback: function (value: number) {
					// 				return value + '%';
					// 			},
					// 			stepSize: 10
					// 		}
					// 	}]
					// 	// {
					// 	// 	title: {
					// 	// 		display: true,
					// 	// 		text: 'Proficiency Level (%)',
					// 	// 		font: {
					// 	// 			weight: 'bold'
					// 	// 		}
					// 	// 	},
					// 	// 	min: 0,
					// 	// 	ticks: {
					// 	// 		callback: function (value: number) {
					// 	// 			return value + '%';
					// 	// 		},
					// 	// 		stepSize: 10
					// 	// 	}
					// 	// }
					// },
					scales: {
						x: {
							title: {
								display: true,
							}
						},
						y: {
							title: {
								display: true,
								text: 'Proficiency Level (%)',
								font: {
									weight: 'bold'
								}
							},
							min: 0,
							ticks: {
								callback: function (value: number) {
									return value + '%';
								},
								stepSize: 10
							}
						}
					},
					plugins: {
						legend: {
							display: true,
							position: 'top',
							labels: {
								usePointStyle: true, // Use point style for legend
								pointStyle: 'circle' // Set legend point style to circle
							}
						},


						tooltip: {
							enabled: true,

							intersect: false,
							callbacks: {
								title: function (context: any) {
									let title = context[0].label || '';
									title = 'Month Name: ' + title;
									return title;
								},
								label: function (context: any) {
									let label = context.dataset.label || '';

									if (context.parsed.y !== null) {
										label = label + ': ' + context.parsed.y + '%';
									}
									return label;
								}
							}
						}
					}
				}
			};

			this.chart = new Chart(ctx, config);
		} else {
			console.error('Canvas element not found!');
		}
	}

	ngPageLoad() {
		let self = this;
		self.commonService.showLoader();

		this.userActivityService.saveUserActivity("User Management", "Student Wall", "", "", "", "", self.studentID, self.instituteID);

		try {
			Promise.all([self.getStudentView()])
				.then((data: any) => {
					if (data != null) {
						let studentViewResult = data[0];

						if (studentViewResult != null && studentViewResult.success == true) {
							self.studentVM = studentViewResult.data as StudentWallVM;
							if (!self.commonService.isNullOrEmpty(self.studentVM.profilePictureURL)) {
								self.studentProfileImageURL = self.studentVM.profilePictureURL;
							} else {
								self.studentProfileImageURL = "./../../../../../../assets/media/images/img_avatar.png";
							}

							if (this.isStudentView == true) {
								self.instituteID = self.studentVM.instituteID;
							}

							if (!self.commonService.isEmptyArray(self.studentVM.courses)) {
								if (self.qSubjectId == 0) {
									let index = 0;

									self.studentVM.courses.forEach((ele) => {
										self.studentVM.courses[index].isChecked = true;
										self.selectedCoursesIDs.push(self.studentVM.courses[index].courseID);
										self.selectedSubjectIDs.push(self.studentVM.courses[index].subjectID);

										index = index + 1;
									});

									self.ChapterWiseProficiencyDataVM = [];
									if (self.studentVM.chapterAnalysisList !== null && self.studentVM.chapterAnalysisList !== undefined) {
										self.ChapterWiseProficiencyDataVM.push(...self.studentVM.chapterAnalysisList);
									}

									self.StudentAssignmentDataVM = [];
									if (self.studentVM.assignments !== null) {
										self.StudentAssignmentDataVM.push(...self.studentVM.assignments);
									}

									self.getExpertAdviceData();

									self.subscribeEventForSelectedCourse(true);
								} else {
									let index = 0;

									self.studentVM.courses.forEach((ele) => {
										self.studentVM.courses[index].isChecked = true;
										index = index + 1;
									});

									self.getDefaultSelectedSubject();
								}
							} else {
								self.subscribeEventForSelectedCourse(true);

								self.commonService.hideLoader();
							}
						} else {
							if (studentViewResult.status == 401) {
								self.commonService.hideLoader();
								this.commonService.redirectToLoginPage(false);
							}
						}
					}
					if (!(self.cdr as ViewRef).destroyed) {
						self.cdr.detectChanges();
					}
				})
				.catch((err) => {
					self.commonService.showToaster(err.message, "error");
					self.commonService.hideLoader();
				});
		} catch (e) { }
	}

	getDefaultSelectedSubject() {
		let self = this;
		if (!self.commonService.isNullOrEmpty(self.qSubjectId) && !self.commonService.isNullOrEmpty(self.qSelectCourseId)) {
			let selectedSujects = self.qSubjectId.split(",") as [];
			let selectedCourse = self.qSelectCourseId.split(",") as [];

			selectedSujects.forEach((ele) => {
				let subjectID = parseInt(ele) || 0;
				if (subjectID > 0) {
					let selectedCourse = self.studentVM.courses.findIndex((c) => c.subjectID == subjectID);
					// self.studentVM.courses[selectedCourse].isChecked = true;
					self.selectedSubjectIDs.push(subjectID);
				}
			});
			selectedCourse.forEach((ele) => {
				let courseID = parseInt(ele) || 0;
				if (courseID > 0) {
					let selectedCourse = self.studentVM.courses.findIndex((c) => c.courseID == courseID);
					// self.studentVM.courses[selectedCourse].isChecked = true;
					self.selectedCoursesIDs.push(courseID);
				}
			});

			if (!(self.cdr as ViewRef).destroyed) {
				self.cdr.detectChanges();
			}

			// self.studentVM.courses.forEach((ele) => {
			// 	let selectedCourse = self.studentVM.courses.findIndex(c => c.courseID == ele.courseID);
			// 	self.studentVM.courses[selectedCourse].isChecked = true;
			// 	self.selectedSubjectIDs.push(ele.courseID);
			// });

			self.subscribeEventForSelectedCourse(true);
		}
	}

	getStudentView() {
		let self = this;
		return new Promise((resolve, reject) => {
			self.studentService.getStudentView(self.studentID, self.instituteID).subscribe(
				(data) => {
					resolve(data);
					self.commonService.hideLoader();
				},
				(error) => {
					resolve(error);
					self.commonService.hideLoader();
				}
			);
		});
	}

	changeSelectedGroup(event) {
		let self = this;

		self.testPage = 1;
		self.tutorWallTestInfoVM = [];

		self.selectedCoursesIDs = [];
		self.selectedSubjectIDs = [];
		if (!self.commonService.isEmptyArray(self.studentVM.courses)) {
			self.selectedCoursesIDs = this.studentVM.courses.filter((c) => c.isChecked == true).map((c) => c.courseID);
		}
		if (!self.commonService.isEmptyArray(self.studentVM.courses)) {
			self.selectedSubjectIDs = this.studentVM.courses.filter((c) => c.isChecked == true).map((c) => c.subjectID);
		}
		if (self.commonService.isEmptyArray(self.selectedCoursesIDs) || self.commonService.isEmptyArray(self.selectedSubjectIDs)) {
			self.tutorWallTestInfoVM = [];
			self.studentDiagnosisDataVM = [];
			self.barDiscoveryChartData = [{ data: [] }];
			self.barDiscoveryChartLabels = [];
			/******* StudentEfficiencyLeve graph rest ****** */
			// self.barStudentEfficiencyLevelChartLabels = [];
			/******* StudentEfficiencyLeve graph reset *******/
		}

		if (!self.commonService.isNullOrEmpty(self.qSubjectId)) {
			let selectedSujects = self.qSubjectId.split(",") as [];
			selectedSujects.forEach((ele) => {
				let subjectID = parseInt(ele) || 0;
				if (subjectID > 0) {
					self.router.navigate(["institute/user-master/student-view", self.studentID]);
				}
			});
		} else {
			self.subscribeEventForSelectedCourse(false);
		}
		if (!(self.cdr as ViewRef).destroyed) {
			self.cdr.detectChanges();
		}
	}

	isCheckedEventForCheckbox(studentWallCourseSubjectInfoVM: StudentWallCourseSubjectInfoVM, index: number) {
		let self = this;

		self.testPage = 1;
		self.tutorWallTestInfoVM = [];

		if (studentWallCourseSubjectInfoVM.isChecked != true) {
			studentWallCourseSubjectInfoVM.isChecked = true;
		} else {
			studentWallCourseSubjectInfoVM.isChecked = false;
		}

		self.selectedCoursesIDs = [];
		self.selectedSubjectIDs = [];
		if (!self.commonService.isEmptyArray(self.studentVM.courses)) {
			self.selectedCoursesIDs = this.studentVM.courses.filter((c) => c.isChecked == true).map((c) => c.courseID);
			self.selectedSubjectIDs = this.studentVM.courses.filter((c) => c.isChecked == true).map((c) => c.subjectID);
		}
		if (self.commonService.isEmptyArray(self.selectedCoursesIDs) || self.commonService.isEmptyArray(self.selectedSubjectIDs)) {
			self.tutorWallTestInfoVM = [];
			self.studentDiagnosisDataVM = [];
			self.barDiscoveryChartData = [{ data: [] }];
			self.barDiscoveryChartLabels = [];
			/******* StudentEfficiencyLeve graph rest ****** */
			// self.barStudentEfficiencyLevelChartLabels = [];
			/******* StudentEfficiencyLeve graph reset *******/
		}

		if (!self.commonService.isNullOrEmpty(self.qSubjectId)) {
			let selectedSujects = self.qSubjectId.split(",") as [];
			selectedSujects.forEach((ele) => {
				let subjectID = parseInt(ele) || 0;
				if (subjectID > 0) {
					self.router.navigate(["institute/user-master/student-view", self.studentID]);
				}
			});
		} else {
			self.subscribeEventForSelectedCourse(false);
		}
		if (!(self.cdr as ViewRef).destroyed) {
			self.cdr.detectChanges();
		}
	}

	subscribeEventForSelectedCourse(isLoad) {
		let self = this;
		let requests = [];
		if (isLoad == 'scroll') {
			requests = [{}, self.getStudentTestList()];
		} else if (isLoad == true) {
			requests = [self.getStudentEfficiencyLevelDataByMonths(), self.getStudentTestList()];
		} else {
			requests = [self.getStudentEfficiencyLevelDataByMonths(), self.getStudentTestList(), self.getChapterWiseProficiency()];
		}

		self.commonService.showLoader();
		Promise.all(requests)
			.then((data: any) => {
				if (data != null) {
					let studentEfficiencyLevel = data[0];
					let testInfoByGroup = data[1];
					//let StudentAssignmentList = data[2];
					let ChapterWiseProficiencyList = data[2];

					if (studentEfficiencyLevel != null && studentEfficiencyLevel.success == true) {
						this.chartDates = [...new Set(studentEfficiencyLevel.data.map(item => item.monthName.slice(0, 3) + '-' + item.year))]
						this.initializeTimeChart(studentEfficiencyLevel);
						self.studentEfficiencyLevelDataByMonthsVM = studentEfficiencyLevel.data as StudentEfficiencyLevelDataByMonthsVM[];
						// this.barStudentEfficiencyLevelChartData = [];

						if (!self.commonService.isEmptyArray(self.studentEfficiencyLevelDataByMonthsVM)) {
							this.barStudentEfficiencyLevelChartLabels = self.studentEfficiencyLevelDataByMonthsVM.map(function (x, i) {
								let year = x.year.toString();
								if (year.length >= 4) {
									year = year.substring(2);
								}

								let monthName = x.monthName;
								if (monthName.length >= 3) {
									monthName = monthName.substring(0, 3);
								}
								return monthName + "-" + year;
							});

							let chartOverallEfficiencyData = self.studentEfficiencyLevelDataByMonthsVM.map(function (x, i) {
								return x.overAllEfficiencyPercentage;
							});

							let chartTestEfficiencyData = self.studentEfficiencyLevelDataByMonthsVM.map(function (x, i) {
								return x.testEfficiencyPercentage;
							});

							let chartPracticeTestEfficiencyData = self.studentEfficiencyLevelDataByMonthsVM.map(function (x, i) {
								return x.practiceEfficiencyPercentage;
							});

							let dataObj = [
								{
									data: chartOverallEfficiencyData,
									label: "OverAll Proficiency",
								},
								{
									data: chartTestEfficiencyData,
									label: "Test Proficiency",
								},
								{
									data: chartPracticeTestEfficiencyData,
									label: "Practice Proficiency",
								},
							];

							this.barStudentEfficiencyLevelChartData = dataObj;

							// this.chart.update();
						}
					}

					if (testInfoByGroup?.statusCode == 1) {
						self.tutorWallTestInfoVM = [];
						const response = testInfoByGroup.data as TestListResultVM;
						self.noOfPagesOfTest = response.response.noOfPages;
						self.tutorWallTestInfoVM.push(...response.response.tests);
						if (!(this.cdr as ViewRef).destroyed) {
							this.cdr.detectChanges()
						}
					} else {
						if (!self.commonService.isNullOrEmpty(testInfoByGroup.message)) {
							self.commonService.showToaster(testInfoByGroup.message, "success");
						}
					}

					if (ChapterWiseProficiencyList != null) {
						if (ChapterWiseProficiencyList.success == 1) {
							self.ChapterWiseProficiencyDataVM = [];
							const response = ChapterWiseProficiencyList.data as ChapterWiseProficiencyVMResultVM;
							self.ChapterWiseProficiencyDataVM.push(...response.chapterAnalysisList);
						} else {
							if (!self.commonService.isNullOrEmpty(ChapterWiseProficiencyList.message)) {
								self.commonService.showToaster(ChapterWiseProficiencyList.message, "error");
							}
						}
					}
				}

				if (!(self.cdr as ViewRef).destroyed) {
					self.cdr.detectChanges();
				}

				self.commonService.hideLoader();
			})
			.catch((err) => {
				self.commonService.showToaster(err.message, "error");
				self.commonService.hideLoader();
			});
	}

	getStudentEfficiencyLevelDataByMonths() {
		let self = this;
		// Commented By Jigesh : 25-01-2020
		// let commaSaperatedSubjectIDs = self.selectedSubjectIDs.length > 0 ? ("," + self.selectedSubjectIDs.join(',') + ",") : "";
		let commaSaperatedSubjectIDs = self.selectedSubjectIDs.length > 0 ? self.selectedSubjectIDs.join(",") : "";
		let commaSaperatedChapterIDs = "";
		let commaSaperatedConceptIDs = "";
		let backMonths = 3;
		return new Promise((resolve, reject) => {
			self.studentService.getStudentEfficiencyLevelByMonths(self.instituteID, self.studentID.toString(), commaSaperatedSubjectIDs).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					resolve(error);
				}
			);
		});
	}

	getTestList() {
		let self = this;
		return new Promise((resolve, reject) => {
			self.testMasterService.getTestList(self.searchText, self.instituteID, self.tutorID, self.studentID, self.selectedCoursesIDs.toString(), self.groupIDs, self.subjectIDs, self.testPage, self.testSortColumn, self.testSortDirection, self.timeZoneOffset, self.testStatusTerm, TestCaller.STUDENTWALL).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					resolve(error);
				}
			);
		});
	}

	getStudentTestList() {
		let self = this;
		return new Promise((resolve, reject) => {
			self.testMasterService.getStudentTestList(self.searchText, self.instituteID, self.tutorID, self.studentID, self.selectedCoursesIDs.toString(), self.groupIDs, self.subjectIDs, self.testPage, self.testSortColumn, self.testSortDirection, self.timeZoneOffset, self.testStatusTerm, TestCaller.STUDENTWALL).subscribe(
				(data) => {
					resolve(data);
					self.isSearchSortCompletedTestList = true
				},
				(error) => {
					resolve(error);
				}
			);
			if (!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges()
			}
		});
	}

	getAssignmentList() {
		let self = this;
		return new Promise((resolve, reject) => {
			self.studentService.getAssignmentList(self.instituteID, 0, self.studentID, self.assignmentSortColumn, self.assignmentSortDirection).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					resolve(error);
					//self.commonService.displayPromiseError(error.error);
				}
			);
		});
	}

	getLessonActivites() {
		let self = this;
		return new Promise((resolve, reject) => {
			self.studentService.getLessonActivities(self.instituteID, self.studentID, "", self.activitySortColumn, self.activitySortDirection).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					resolve(error);
					//self.commonService.displayPromiseError(error.error);
				}
			);
		});
	}

	getChapterWiseProficiency() {
		let self = this;
		let commaSaperatedSubjectIDs = self.selectedSubjectIDs.length > 0 ? self.selectedSubjectIDs.join(",") : "";
		return new Promise((resolve, reject) => {
			self.studentService.getChapterWiseProficiency(self.instituteID, self.studentID, commaSaperatedSubjectIDs, self.assessmentSortColumn, self.assessmentSortDirection).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					resolve(error);
					//self.commonService.displayPromiseError(error.error);
				}
			);
		});
	}

	onTestScroll() {
		let self = this;
		if (self.isSearchSortCompletedTestList) {
			if (self.testPage < self.noOfPagesOfTest) {
				self.testPage = self.testPage + 1;
				self.sortingTest();
			}
		}
	}

	onSortedTest($event) {
		var sortedEvent = $event;
		if (sortedEvent) {
			this.testSortColumn = sortedEvent.sortColumn;
			this.testSortDirection = sortedEvent.sortDirection;
			this.testPage = 1;
			this.tutorWallTestInfoVM = [];
			this.isSearchSortCompletedTestList = false;
			this.sortingTest();
		}
	}

	onSortedAssessment($event) {
		const sortedEvent = $event;
		if (sortedEvent) {
			this.assessmentSortColumn = sortedEvent.sortColumn;
			this.assessmentSortDirection = sortedEvent.sortDirection;
			this.ChapterWiseProficiencyDataVM = [];
			this.isSearchSortCompletedChapterList = false;
			this.sortingChapterWiseProficiency();
		}
	}

	onSortedActivity($event) {
		const sortedEvent = $event;
		if (sortedEvent) {
			this.activitySortColumn = sortedEvent.sortColumn;
			this.activitySortDirection = sortedEvent.sortDirection;

			this.studentVM.lessonActivities = [];
			this.isSearchSortCompletedActivityList = false;
			this.sortingActivity();
		}
	}

	sortingActivity() {
		const self = this;
		self.commonService.showLoader();
		Promise.all([self.getLessonActivites()])
			.then((data: any) => {
				if (data !== null) {
					const lessonActivityResult = data[0];
					if (lessonActivityResult.success === true) {
						const response = lessonActivityResult.data as StudentLessonActivityVM[];
						self.studentVM.lessonActivities.push(...response);
					} else {
						if (!self.commonService.isNullOrEmpty(lessonActivityResult.message)) {
							self.commonService.showToaster(lessonActivityResult.message, "error");
						}
					}
				}

				if (!(self.cdr as ViewRef).destroyed) {
					self.cdr.detectChanges();
				}

				self.isSearchSortCompletedActivityList = true;

				self.commonService.hideLoader();
			})
			.catch((err) => {
				self.commonService.showToaster(err.message, "error");
				self.commonService.hideLoader();
			});
	}

	onSortedAssignment($event) {
		var sortedEvent = $event;
		if (sortedEvent) {
			this.assignmentSortColumn = sortedEvent.sortColumn;
			this.assignmentSortDirection = sortedEvent.sortDirection;
			this.assignmentPage = 1;
			this.StudentAssignmentDataVM = [];
			this.isSearchSortCompletedAssignmentList = false;
			this.sortingAssignment();
		}
	}

	sortingAssignment() {
		let self = this;
		self.commonService.showLoader();
		Promise.all([self.getAssignmentList()])
			.then((data: any) => {
				if (data != null) {
					let AssignmentInfoByGroup = data[0];
					if (AssignmentInfoByGroup.success == true) {
						const assignments = AssignmentInfoByGroup.data as StudentAssignmentDataVM[];
						self.StudentAssignmentDataVM.push(...assignments);
					} else {
						if (!self.commonService.isNullOrEmpty(AssignmentInfoByGroup.message)) {
							self.commonService.showToaster(AssignmentInfoByGroup.message, "error");
						}
					}
				}

				if (!(self.cdr as ViewRef).destroyed) {
					self.cdr.detectChanges();
				}

				self.isSearchSortCompletedAssignmentList = true;

				self.commonService.hideLoader();
			})
			.catch((err) => {
				self.commonService.showToaster(err.message, "error");
				self.commonService.hideLoader();
			});
	}

	sortingTest() {
		let self = this;
		self.commonService.showLoader();
		Promise.all([self.getStudentTestList()])
			.then((data: any) => {
				if (data != null) {
					let testInfoByGroup = data[0];
					if (testInfoByGroup.statusCode == 1) {
						const response = testInfoByGroup.data as TestListResultVM;
						self.noOfPagesOfTest = response.response.noOfPages;
						self.tutorWallTestInfoVM.push(...response.response.tests);
					} else {
						if (!self.commonService.isNullOrEmpty(testInfoByGroup.message)) {
							self.commonService.showToaster(testInfoByGroup.message, "error");
						}
					}
				}

				if (!(self.cdr as ViewRef).destroyed) {
					self.cdr.detectChanges();
				}

				self.isSearchSortCompletedTestList = true;

				self.commonService.hideLoader();
			})
			.catch((err) => {
				self.commonService.showToaster(err.message, "error");
				self.commonService.hideLoader();
			});
	}

	sortingChapterWiseProficiency() {
		let self = this;
		self.commonService.showLoader();
		Promise.all([self.getChapterWiseProficiency()])
			.then((data: any) => {
				if (data != null) {
					const ChapterWiseProficiency = data[0];
					if (ChapterWiseProficiency.success === true) {
						const response = ChapterWiseProficiency.data as ChapterWiseProficiencyVMResultVM;
						self.ChapterWiseProficiencyDataVM.push(...response.chapterAnalysisList);
					} else {
						if (!self.commonService.isNullOrEmpty(ChapterWiseProficiency.message)) {
							self.commonService.showToaster(ChapterWiseProficiency.message, "error");
						}
					}
				}

				if (!(self.cdr as ViewRef).destroyed) {
					self.cdr.detectChanges();
				}

				self.isSearchSortCompletedChapterList = true;

				self.commonService.hideLoader();
			})
			.catch((err) => {
				self.commonService.showToaster(err.message, "error");
				self.commonService.hideLoader();
			});
	}

	redirectStudentList() {
		let self = this;
		if (self.activeUrl.CourseID && self.activeUrl.Source == "Course Detail") {
			self.router.navigate(["institute/course/course-list"], {
				queryParams: { CourseID: self.qCourseId, Tab: "Student" },
			});
		} else if (self.qValue && self.activeUrl.Source == "group-view") {
			self.router.navigate(["institute/user-master/batch-view", self.qValue]);
		} else if (self.qValue && self.activeUrl.Source == "tutor-student-view") {
			self.router.navigate(["institute/user-master/tutor-view", self.qValue], { queryParams: { GroupID: self.qGroupID, Tab: "Student" } });
		} else if (self.qValue && self.activeUrl.Source == "Student Test Result") {
			self.router.navigate(["institute/test-master/test-result", self.qValue]);
		} else if (self.activeUrl.Source == "dashboard") {
			self.router.navigate(["institute/dashboard"]);
		} else {
			self.router.navigate(["institute/user-master/user"]);
		}
	}

	chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {
		//console.log(event, active);
	}

	chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {
		//console.log(event, active);
	}

	gotoTestDetails(test: TestListVM) {
		let self = this;
		let selectedsubjectGroupFilter = self.selectedSubjectIDs.join(",");
		let selectedCourseGroupFilter = self.selectedCoursesIDs.join(",");

		this.userActivityService.saveUserActivity("User Management", "Student Wall", "Test Result Clicked", "", "TestMasterID", test.testMasterID, self.studentID, self.instituteID);

		let newGenerateID = self.commonService.generateNewGUID();
		let token = "";
		if (self.isStudentView == true) {
			token = self.commonService.getTempToken();
		} else {
			token = self.storageService.getToken();
		}
		const url = `${BaseStudentFrontURL}student-test/institute-student-test-result?institute=${self.instituteID}&test=${test.testMasterID}&student=${self.studentID}&user=${self.studentVM.userID}&studentName=${self.studentVM.studentName}&key=${newGenerateID}&t=${token}`;
		window.open(url, "_blank");

		// self.router.navigate(['institute/test-master/test-result', test.testMasterID], { queryParams: { Source: 'student-test-result', Value: this.studentID, SubjectID: selectedsubjectGroupFilter, SelectCourse: selectedCourseGroupFilter } });
	}

	// gotoAssignmentDetails(test: StudentAssignmentDataVM) {
	// 	let self = this;
	// 	let selectedsubjectGroupFilter = self.selectedSubjectIDs.join(",");
	// 	let selectedCourseGroupFilter = self.selectedCoursesIDs.join(",");

	// 	let newGenerateID = self.commonService.generateNewGUID();
	// 	const url = `${BaseStudentFrontURL}student-test/institute-student-test-result?institute=${self.instituteID}&test=${test.testMasterID}&student=${self.studentID}&user=${self.studentVM.userID}&studentName=${self.studentVM.studentName}&key=${newGenerateID}`;
	// 	window.open(url, "_blank");

	// 	// self.router.navigate(['institute/test-master/test-result', test.testMasterID], { queryParams: { Source: 'student-test-result', Value: this.studentID, SubjectID: selectedsubjectGroupFilter, SelectCourse: selectedCourseGroupFilter } });
	// }

	getExpertAdviceData() {
		let self = this;
		if (self.studentVM.chapterAnalysisList !== null && self.studentVM.chapterAnalysisList !== undefined) {
			self.areasOfStrengthAnalysisVM = self.studentVM.chapterAnalysisList.filter((item) => item.overAllEfficiencyPercentage >= 50);
			self.areasOfStrengthAnalysisVM.sort((a, b) => (a.overAllEfficiencyPercentage < b.overAllEfficiencyPercentage ? 1 : -1));
			self.areasOfStrengthAnalysisVM.splice(5, self.areasOfStrengthAnalysisVM.length);

			self.areasOfImprovementAnalysisVM = self.studentVM.chapterAnalysisList.filter((item) => item.overAllEfficiencyPercentage < 50);
			self.areasOfImprovementAnalysisVM.sort((a, b) => (a.overAllEfficiencyPercentage > b.overAllEfficiencyPercentage ? 1 : -1));
			self.areasOfImprovementAnalysisVM.splice(5, self.areasOfImprovementAnalysisVM.length);
		}
	}

	secondsToHms(d) {
		d = Number(d);
		const h = Math.floor(d / 3600);
		const m = Math.floor((d % 3600) / 60);
		const s = Math.floor((d % 3600) % 60);

		if (h === 0 && m < 1) {
			return "< 1 min";
		} else {
			const hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : "";
			const mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
			//const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
			return hDisplay + mDisplay;
		}
	}
}
