import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'input[ktInteger]'
})
export class IntegerDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;

    var num = /^[-+]?\d*$/g;    //match only positive and numeric

    if (initalValue != "") {
      if (!initalValue.match(num)) {
        this._el.nativeElement.value = initalValue.replace(/[^0-9]/g, "");  
        event.stopPropagation();
      }
      else {  
          if(!isNaN(this._el.nativeElement.value)){
            let number = +this._el.nativeElement.value; 
            if(number <-1){
              this._el.nativeElement.value=null;
              event.stopPropagation();
            }
          }
      }
    }


    //  this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    //  this._el.nativeElement.value = initalValue.replace(/^[-+]?\d+$/, '');
    // if ( initalValue !== this._el.nativeElement.value) {
    //   event.stopPropagation();
    // }
  }

}
