import { StorageService } from '../../services/utilities/storage.service';
import { RoleType } from '../../enum/RoleType';
import { Inject, Injectable } from "@angular/core";
import { EncryptDecryptService } from '../../services/utilities/encrypt-decrypt.service';
import { Router } from '@angular/router';
import { CommonService } from '../../services/utilities/common.service';
import { AssociationTypeTerm } from '../../enum/AssociationTypeTerm';
import { InstituteUserPermissionVM } from '../../viewmodels/InstituteUserPermissionVM';

export class MenuConfig {

	isVerifier: boolean = false;
	isPublisher: boolean = false;
	private _encryptDecryptService: EncryptDecryptService;

	isClientURL: boolean = false;
	isStudentURL: boolean = true;
	isTutorURL: boolean = true;
	instituteID: number;

	constructor(private storageService: StorageService,
		private commonService: CommonService,
		private router: Router) {

		this.instituteID = parseInt(this.storageService.getInstituteID());
		let isInstituteURL = this.commonService.isInstituteURL();
		//let indexOfInstituteURL = router.url.toString().indexOf('/institute/');
		let studentRole = this.getStudentUserRoles();
		let clientRole = this.getUserRoles();


		if (isInstituteURL == true && !this.commonService.isNullOrEmpty(clientRole)) {

			this.isClientURL = true;
			this.isStudentURL = false;
		}
		else {
			this.isStudentURL = true;
			this.isClientURL = false;
		}
		this._encryptDecryptService = new EncryptDecryptService();
	}



	public defaults: any = {
		header: {
			self: {},
			items: [
			]
		},
		aside: {
			self: {},
			items: [
				// {
				// 	title: 'Home',
				// 	root: true,
				// 	icon: 'flaticon2-layers-2',					
				// 	page: '/',
				// 	translate: 'MENU.COURSE',
				// 	bullet: 'dot',
				// 	pageCode: 'HOME',
				// 	hasSubMenu: false,
				// },
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/institute/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
					pageCode: 'DASHBOARD',
					hasSubMenu: false,
				},
				{
					title: 'Courses',
					root: true,
					icon: 'flaticon2-layers-2',
					page: '/institute/course/course-list',
					translate: 'MENU.COURSE',
					bullet: 'dot',
					pageCode: 'COURSE',
					hasSubMenu: false,
				},
				{
					title: 'User Management',
					root: true,
					icon: 'flaticon-users',
					page: '/institute/user-master/user',
					translate: 'MENU.USER',
					bullet: 'dot',
					pageCode: 'USER',
					hasSubMenu: false,
				},
				{
					title: 'Test Management',
					root: true,
					icon: 'flaticon2-document',
					page: '/institute/test-master/test',
					translate: 'MENU.TEST',
					bullet: 'dot',
					pageCode: 'TEST',
					hasSubMenu: false,
				},
				{
					title: 'Notifications',
					root: true,
					icon: 'flaticon2-notification',
					page: '/institute/notifications',
					translate: 'MENU.NOTIFICATIONS',
					bullet: 'dot',
					pageCode: 'NOTIFICATIONS',
					hasSubMenu: false,
				},
				{
					title: 'Doubts',
					root: true,
					icon: 'flaticon-questions-circular-button',
					page: '/institute/doubts/doubts-list',
					translate: 'MENU.DOUBTS',
					bullet: 'dot',
					pageCode: 'DOUBTS',
					hasSubMenu: false,
				},
				{
					title: 'Configurations',
					root: true,
					icon: 'flaticon-settings',
					page: '/institute/configurations',
					translate: 'MENU.CONFIGURATIONS',
					bullet: 'dot',
					pageCode: 'CONFIGURATIONS',
					hasSubMenu: false,
				},
			]
		},
	};

	public defaultsVerifierPublisher: any = {
		header: {
			self: {},
			items: [
			]
		},
		aside: {
			self: {},
			items: [
				{
					// title: 'Question Bank',
					// root: true,
					// icon: 'flaticon-questions-circular-button',
					// page: '/institute/question',
					// translate: 'MENU.QUESTION',
					// bullet: 'dot',

				},
			]
		},
	};

	public defaultsStudent: any = {
		header: {
			self: {},
			items: [

			]
		},
		aside: {
			self: {},
			items: [
				{
					title: 'My Course',
					root: true,
					icon: 'flaticon2-menu-4',
					page: '/student/course',
					translate: 'MENU.MY_COURSE',
					bullet: 'dot',
					pageCode: 'SUBJECT',
					hasSubMenu: false,
				},
			]
		},
	};

	public defaultTutor: any = {
		header: {
			self: {},
			items: [
			]
		},
		aside: {
			self: {},
			items: []
		},
	};

	public get configs(): any {

		if (this.isStudentURL) {
			if (this.hasStudentRole(RoleType.Student)) {
				return this.defaultsStudent;
			}
		}
		if (this.isClientURL) {
			const permissions = this.storageService.getUserPermission();

			let userType = this.storageService.getAdminUserTypeTerm();
			let clientRole = this.getUserRoles();
			if (clientRole == AssociationTypeTerm.Tutor) {
				//return this.defaultTutor;
				return this.getTutorRoles(permissions);
			}
			else {
				return this.getAdminRoles(permissions, userType);
				//if (this.instituteID == 2) {
				//	return this.defaults1;
				//}
				//else {
				//	return this.defaults;
				//}

			}
		}
	}


	getAdminRoles(permission: InstituteUserPermissionVM, userType) {
		let items = [];

		items.push({
			title: 'Dashboard',
			root: true,
			icon: 'flaticon2-architecture-and-city',
			page: '/institute/dashboard',
			translate: 'MENU.DASHBOARD',
			bullet: 'dot',
			pageCode: 'DASHBOARD',
			hasSubMenu: false,
		});
		items.push({
			title: 'Courses',
			root: true,
			icon: 'flaticon2-layers-2',
			page: '/institute/course/course-list',
			translate: 'MENU.COURSE',
			bullet: 'dot',
			pageCode: 'COURSE',
			hasSubMenu: false,
		});
		items.push({
			title: 'User Management',
			root: true,
			icon: 'flaticon-users',
			page: '/institute/user-master/user',
			translate: 'MENU.USER',
			bullet: 'dot',
			pageCode: 'USER',
			hasSubMenu: false,
		});
		items.push({
			title: 'Test Management',
			root: true,
			icon: 'flaticon2-document',
			page: '/institute/test-master/test',
			translate: 'MENU.TEST',
			bullet: 'dot',
			pageCode: 'TEST',
			hasSubMenu: false,
		});

		if (permission.isMyData == true && permission.isAccessMyData == true) {
			items.push({
				title: 'My Data',
				root: true,
				icon: 'flaticon2-file',
				page: '/institute/my-data',
				translate: 'MENU.MYDATA',
				bullet: 'dot',
				pageCode: 'MYDATA',
				hasSubMenu: false,
			});
		}

		if (permission.isSendNotification == true) {
			items.push({
				title: 'Notifications',
				root: true,
				icon: 'flaticon2-notification',
				page: '/institute/notifications',
				translate: 'MENU.NOTIFICATIONS',
				bullet: 'dot',
				pageCode: 'NOTIFICATIONS',
				hasSubMenu: false,
			});
		}

		if (permission.isDoubts == true) {
			items.push({
				title: 'Doubts',
				root: true,
				icon: 'flaticon-questions-circular-button',
				page: '/institute/doubts/doubts-list',
				translate: 'MENU.DOUBTS',
				bullet: 'dot',
				pageCode: 'DOUBTS',
				hasSubMenu: false,
			});
		}

		if (userType == RoleType.InstituteAdmin) {
			items.push({
				title: 'Configurations',
				root: true,
				icon: 'flaticon-settings',
				page: '/institute/configurations',
				translate: 'MENU.CONFIGURATIONS',
				bullet: 'dot',
				pageCode: 'CONFIGURATIONS',
				hasSubMenu: false,
			});
		}


		this.defaults.aside.items = items;
		return this.defaults;
	}


	getTutorRoles(permission: InstituteUserPermissionVM) {
		let items = [];

		items.push({
			title: 'Dashboard',
			root: true,
			icon: 'flaticon2-architecture-and-city',
			page: '/institute/dashboard',
			translate: 'MENU.DASHBOARD',
			bullet: 'dot',
			pageCode: 'DASHBOARD',
			hasSubMenu: false,
		});
		items.push({
			title: 'Courses',
			root: true,
			icon: 'flaticon2-layers-2',
			page: '/institute/course/course-list',
			translate: 'MENU.COURSE',
			bullet: 'dot',
			pageCode: 'COURSE',
			hasSubMenu: false,
		});
		items.push({
			title: 'User Management',
			root: true,
			icon: 'flaticon-users',
			page: '/institute/user-master/user',
			translate: 'MENU.USER',
			bullet: 'dot',
			pageCode: 'USER',
			hasSubMenu: false,
		});
		items.push({
			title: 'Test Management',
			root: true,
			icon: 'flaticon2-document',
			page: '/institute/test-master/test',
			translate: 'MENU.TEST',
			bullet: 'dot',
			pageCode: 'TEST',
			hasSubMenu: false,
		});

		if (permission.isMyData == true) {
			items.push({
				title: 'My Data',
				root: true,
				icon: 'flaticon2-file',
				page: '/institute/my-data',
				translate: 'MENU.MYDATA',
				bullet: 'dot',
				pageCode: 'MYDATA',
				hasSubMenu: false,
			});
		}

		if (permission.isSendNotification == true) {
			items.push({
				title: 'Notifications',
				root: true,
				icon: 'flaticon2-notification',
				page: '/institute/notifications',
				translate: 'MENU.NOTIFICATIONS',
				bullet: 'dot',
				pageCode: 'NOTIFICATIONS',
				hasSubMenu: false,
			});
		}

		if (permission.isDoubts == true) {
			items.push({
				title: 'Doubts',
				root: true,
				icon: 'flaticon-questions-circular-button',
				page: '/institute/doubts/doubts-list',
				translate: 'MENU.DOUBTS',
				bullet: 'dot',
				pageCode: 'DOUBTS',
				hasSubMenu: false,
			});
		}

		this.defaultTutor.aside.items = items;
		return this.defaultTutor;
	}

	hasClientRole(role): boolean {
		let roles = this.getUserRoles();
		if (roles != null && roles != "" && roles != undefined) {
			if (roles.toUpperCase().indexOf(role.toUpperCase()) != -1) {
				return true;
			}
		}
		return false;
	}


	hasStudentRole(role): boolean {
		let studentRoles = this.getStudentUserRoles();
		if (studentRoles != null && studentRoles != "" && studentRoles != undefined) {
			if (studentRoles.toUpperCase().indexOf(role.toUpperCase()) != -1) {
				return true;
			}
		}
		return false;
	}



	getUserRoles(): string {
		return this.storageService.getUserRoles();
	}

	getStudentUserRoles(): string {
		return this.storageService.getStudentUserRoles();
	}
	decrypt(cipherText: any) {
		if (cipherText != null && cipherText != undefined && cipherText != "null") {
			return this._encryptDecryptService.decrypt(cipherText);
		}
		return null;
	}
}

