import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';
import { ApiVersion } from '../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
  providedIn: 'root'
})
export class RoleService {


  constructor(private http: HttpClient) { }

  getRolePrivilegeByUserAndInstitute(userID: number, instituteID?: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Role/GetRolePrivilegeByUserAndInstitute?userID=${userID}&instituteID=${instituteID}`);
  }

}
