// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, ViewRef, ViewChild, ElementRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService } from '../../../../core/auth/auth-notice/auth-notice.service';
import { UserLogInOut } from './../../../../models/UserLogInOut';
import { LoginResultVM } from '../../../../viewmodels/LoginResultVM';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { AppConfig } from '../../../../services/utilities/app-config';
import { REGEXP } from '../../../../shared/regexp';
import { AssociationTypeTerm } from '../../../../enum/AssociationTypeTerm';
import { Configuration } from '../../../../enum/Configuration';
import { UserActivityService } from '../../../../services/user-activity.service';

declare var $: any;
/**
 * ! Just example => Should be removed in development
 */

@Directive()
@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

	isAdminMode: boolean = false;
	@ViewChild('focus', { static: false }) input: ElementRef;
	loginModel: UserLogInOut = new UserLogInOut();
	loginForm: UntypedFormGroup;
	userData: LoginResultVM = new LoginResultVM();
	isActivePassword: boolean = false;
	isNumberOfAttempLogin: number = 0;
	platFormBrowser: string;
	platformTerm: string;
	recaptchaToken: string;
	googleCaptchaSiteKey: string = "";
	public isChkRememberMe: boolean = false;
	adminUserProfileImageURL = this.appConfig.InstituteUserProfileAttachmentUrl;
	clientUserProfileImageURL = this.appConfig.UserProfileAttachmentUrl;
	tutorProfileImageURL = this.appConfig.TutorProfileAttachmentUrl;
	instiuteUserProfileURL = this.appConfig.InstituteUserProfileUrlOfAdminPortal;
	isSubmittedUserName: boolean = false;
	isSubmittedPassword: boolean = false;
	passwordType: any;
	show = false;

	constructor(
		private router: Router,
		private fb: UntypedFormBuilder,
		private regexp: REGEXP,
		private authNoticeService: AuthNoticeService,
		private userActivityService: UserActivityService,
		private auth: AuthService,
		private appConfig: AppConfig,
		private commonService: CommonService,
		private translate: TranslateService,
		private storageService: StorageService,
		private renderer: Renderer2,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,) {

		this.passwordType = "password";
		let whiteLabelKey = "";
		this.route.params.subscribe((ele) => {
			whiteLabelKey = ele.id;
			if (this.commonService.isNullOrEmpty(whiteLabelKey) == false) {
				this.storageService.setWhiteLabelLogin(ele.id);
			}
		});

		this.platFormBrowser = commonService.getBroserDetail();
		this.platformTerm = commonService.getOperatingSystem();
		this.googleCaptchaSiteKey = appConfig.GoogleCaptchaSiteKey;
		this.readCookies();
		this.subscribeToEvents();

	}

	ngOnInit(): void {
		let self = this;



		this.initLoginForm();

		if (this.auth.isAuthenticated()) {
			// this.router.navigate(['/institute/question']);
			//this.router.navigate(['/institute/user-master']);
			//this.router.navigate(['/institute/dashboard']);
			this.storageService.goToLandingPage();
		}
		else {
			self.route.queryParams.subscribe(params => {
				const isSessionTimeout = params['isSessionTimeout'] || '';
				if (!self.commonService.isNullOrEmpty(isSessionTimeout)) {
					if (self.commonService.trim(isSessionTimeout) == "1") {
						self.commonService.showToaster("Your session is expired. Please try to login again.", "error");
						//self.authNoticeService.setNotice(self.translate.instant('AUTH.VALIDATION.SESSION_EXPIRED'), 'danger');
					}
				}
			});

			this.countNoOfAttempLogin();
		}
	}

	readCookies() {
		let self = this;
		let cookieData = this.storageService.getUserNameWithPassword();
		self.loginModel.userName = (cookieData.userName == undefined ? '' : cookieData.userName);
		self.loginModel.password = (cookieData.password == undefined ? '' : cookieData.password);
	}

	ngOnDestroy(): void {
		//this.authNoticeService.setNotice(null);
	}

	ngPageLoad() {
		$("#userName").focus();
	}

	ngAfterViewInit() {
		$("#userName").focus();
	}

	initLoginForm() {
		let self = this;
		this.loginForm = this.fb.group({
			userName: [self.loginModel.userName, Validators.compose([Validators.required, Validators.pattern(this.regexp.EMAIL_REGEXP), Validators.maxLength(255)])],
			password: [self.loginModel.password, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(15)])],
			browserTerm: [self.platFormBrowser],
			platformTerm: [self.platformTerm],
			isChkRememberMe: [self.isChkRememberMe],
			isAdminMode: false,
			recaptchaReactive: [self.recaptchaToken]
		});
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	saveUserName() {
		let self = this;
		self.isSubmittedUserName = true;
		self.isAdminMode = false;
		self.setPasswordValidation();
		const controls = this.loginForm.controls;
		/** check form */
		if (controls.userName == null || controls.userName == undefined || controls.userName.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		
		self.commonService.showLoader();
		
		let loginModel = self.loginForm.value;
		self.auth.checkInstituteUserName(loginModel.userName).subscribe((response) => {
			if (response.success == true) {
				self.isActivePassword = true;

				setTimeout(() => {
					var elem = this.renderer.selectRootElement('#password');
					this.renderer.listen(elem, "focus", () => {  });
					this.renderer.listen(elem, "blur", () => {  });
					elem.focus();
				}, 100);
				//self.commonService.showToaster(response.message , "success");
				//$('#password').focus(); //  this.input.nativeElement.focus();
			}
			else {
				self.isActivePassword = false;
				self.commonService.showToaster(response.message, "error");
			}
			if (!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges()
			}
			self.commonService.hideLoader();

		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

	submit() {
		let self = this;
		self.isSubmittedPassword = true;

		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		let isAdminMode = false;
		
		this.loginForm.controls["isAdminMode"].setValue(self.isAdminMode);
		
		self.auth.login(this.loginForm.value).subscribe((response) => {
			this.userData = response as LoginResultVM;
			if (this.userData.userID > 0) {
				if (controls['isChkRememberMe'].value == true) {
					this.storageService.storeUserNameWithPassword(this.loginForm.value);
					this.storageService.storeTimezoneOffset();
				}

				this.storageService.setUserPermission(this.userData.permission);

				// set profile pic of admin from (user or institute table)
				const imageName = this.userData.userProfilePic;
				this.userData.userProfilePic = `${imageName}`;

				/******************* Institute Logo *********************/
				let instituteLogo = this.userData.instituteLogo;

				if (!this.commonService.isNullOrEmpty(instituteLogo)) {
					this.userData.instituteLogo = `${instituteLogo}`;
				}
				/******************* Institute Logo *********************/
				this.userData.profilePic = this.userData.userProfilePic;

				let idexOfGoogleApiKey = this.userData.configurations.findIndex(c => c.configKey == Configuration.GoogleAPIKey);
				if (idexOfGoogleApiKey != undefined && idexOfGoogleApiKey != -1) {
					this.userData.googleAPIKey = this.userData.configurations[idexOfGoogleApiKey].configValue;
				}

				this.auth.setSession(this.userData);
				this.userActivityService.saveUserActivity("Login", "", "", "", "", "");

				if (this.userData.isInitialPasswordResetComplete == false) {
					localStorage.setItem("TempUserID", this.commonService.parseString(this.userData.userID));
					localStorage.setItem("LoginLogID", this.commonService.parseString(this.userData.logInLogID));
					this.router.navigate(['/institute/auth/initial-reset-password']);
				} else {
					this.storageService.goToLandingPage();
				}

				this.storageService.setNoOfAttempLogin(0);
			} else {
				self.commonService.showToaster("Invalid username or password", "error");
				//this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				this.cdr.detectChanges();
			}
			self.commonService.hideLoader();
			if (!(this.cdr as ViewRef).destroyed) {
				this.cdr.detectChanges()
			}
		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, 'error');
			let attempCounter = this.storageService.getNoOfAttempLogin();
			this.isNumberOfAttempLogin = (attempCounter == undefined || attempCounter == "" || attempCounter == null) ? 1 : (parseInt(attempCounter) + 1);
			this.storageService.setNoOfAttempLogin(this.isNumberOfAttempLogin);
			this.toggleCaptcha(this.isNumberOfAttempLogin);
			this.cdr.detectChanges();
		});
	}

	countNoOfAttempLogin() {
		let self = this;
		let attempCounter = self.storageService.getNoOfAttempLogin();
		self.isNumberOfAttempLogin = (attempCounter == undefined || attempCounter == "" || attempCounter == null) ? 0 : parseInt(attempCounter);
		self.toggleCaptcha(self.isNumberOfAttempLogin);
	}

	keyDownForm(event) {
		if (event.keyCode == 13) {
			this.saveUserName();
		}
	}

	keyDownFormPassword(event) {
		if (event.keyCode == 13) {
			this.submit();
		}
	}

	toggleCaptcha(isNoOfAttemp) {
		if (isNoOfAttemp >= 3) {
			this.loginForm.controls.recaptchaReactive.setValidators(Validators.compose([Validators.required]));
			this.loginForm.updateValueAndValidity();
			this.cdr.detectChanges();
		} else {
			this.loginForm.controls.recaptchaReactive.setValidators(null);
			this.loginForm.updateValueAndValidity();
			this.cdr.detectChanges();
		}
	}

	editUserName() {
		let self = this;
		self.isActivePassword = false;
		let loginModel = self.loginForm.value;
		self.loginForm.controls.userName.setValue(loginModel.userName);
		self.loginForm.controls.userName.updateValueAndValidity();
	}

	goToForgotPassword() {
		this.router.navigate(['institute/auth/forgot-password']);
	}

	subscribeToEvents() {
		let self = this;
		self.storageService.adminModeEvent.subscribe((data) => {
			self.saveUserName();
			self.isAdminMode = true;
			self.setPasswordValidation();
		});
	}

	setPasswordValidation() {
		this.loginForm.controls["password"].clearValidators();
		if (this.isAdminMode == true) {
			this.loginForm.controls["password"].setValidators([Validators.required, Validators.maxLength(15)]);
		}
		else {
			this.loginForm.controls["password"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(15)]);
		}
		this.loginForm.controls["password"].updateValueAndValidity();
	}

	onPasswordToggle() {
		if (this.passwordType === 'password') {
			this.passwordType = 'text';
			this.show = true;
		} else {
			this.passwordType = 'password';
			this.show = false;
		}
	}
}
