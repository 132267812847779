// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { StorageService } from '../../../services/utilities/storage.service';
import { CommonService } from '../../../services/utilities/common.service';

import { AuthService } from '../_services/auth.service';
import { PageCode } from './../../../enum/PageCode';
import { UserActivityService } from '../../../services/user-activity.service';

@Injectable()
export class AuthGuard  {

	constructor(private _auth: AuthService,
		private _storage: StorageService,
		private _common: CommonService,
		private userActivityService: UserActivityService,
		private store: Store<AppState>,
		private router: Router) {

	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const url = route.routeConfig.path;
		const data = route.data as any;

		//Store Timezone Offset in Cookies.
		this._storage.storeTimezoneOffset();

		//console.log(url);
		//console.log(data);

		return this.checkAuthentication(url, data);
	}

	canActivateChild(route: ActivatedRouteSnapshot): boolean {
		const url = route.routeConfig.path;
		const data = route.data as any;

		//Store Timezone Offset in Cookies.
		this._storage.storeTimezoneOffset();

		//console.log(url);
		//console.log(data);

		return this.checkAuthentication(url, data);
	}


	redirectToLogin(isSessionTimeout: boolean = false) {

		if (isSessionTimeout == false) {
			this.router.navigateByUrl('/institute/auth/login');
		} else {
			this.router.navigateByUrl('/institute/auth/login?isSessionTimeout=1');
		}
	}

	checkAuthentication(url?: string, data?: any): boolean {
		let isCheckForAuthentication: boolean = true;

		if (data != null && !this._common.isNullOrEmpty(url)) {
			let pageCode = data.pageCode;
			let isCheckAccessRight = data.isCheckAccessRight;

			if (isCheckAccessRight) {
				let isReadPermission: boolean = true;
				let pageAccessRight = this._storage.getPageAcess(pageCode);
				if (pageAccessRight != null) {

					isReadPermission = pageAccessRight.isRead;

					if (isReadPermission == false) {

						if (pageCode == PageCode.QUESTION) {
							let questionVerifyPageAccessRight = this._storage.getPageAcess(PageCode.QUESTION_VERIFY);
							let questionPublishPageAccessRight = this._storage.getPageAcess(PageCode.QUESTION_PUBLISH);

							if ((questionVerifyPageAccessRight != null && questionVerifyPageAccessRight != undefined)
								|| questionPublishPageAccessRight != null && questionPublishPageAccessRight != undefined) {

								if (questionVerifyPageAccessRight.isRead == true || questionVerifyPageAccessRight.isExecute == true || questionPublishPageAccessRight.isRead == true || questionPublishPageAccessRight.isExecute == true) {
									isReadPermission = true;
								}

							}
						}
					}

					//If user not have IsView access then redirect to unauthorize page.
					if (isReadPermission == false) {
						isCheckForAuthentication = false;
						this.router.navigate(['institute/unauthorize']);
					}

				}
			}
		}

		if (isCheckForAuthentication) {
			if (!this._auth.isAuthenticated()) {
				if (this._common.isNullOrEmpty(this._storage.getUserId())) {
					this.redirectToLogin(false);
				} else {
					let userId = this._storage.getUserId();
					let logInLogId = this._storage.getLogInLogID();
					this._auth.doLogout(userId, logInLogId).subscribe(data => {
						this.userActivityService.saveUserActivity("Logout", "", "", "","","");
						this._auth.clearSession();
						this.redirectToLogin(true);
					}, error => {
						this._common.hideLoader();
						this._common.showToaster(error.error, "error");
						// console.log("Logout Error: ", error);
						this.redirectToLogin(false);
					});
				}

				return false;
			}
			return true;
		} else {
			return false;
		}
	}

	// canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
	//     return this.store
	//         .pipe(
	//             select(isLoggedIn),
	//             tap(loggedIn => {
	//                 if (!loggedIn) {
	//                     this.router.navigateByUrl('/institute/auth/login');
	//                 }
	//             })
	//         );
	// }


}
