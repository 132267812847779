//@Packages
import { Injectable, EventEmitter } from '@angular/core';
// Lodash
import { remove } from 'lodash';
 
//@View Models
import { ProgressBarModel } from '../../../../models/common/ProgressBarModel';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {

  progressBarData: ProgressBarModel[];

  refreshProgressbarEvent = new EventEmitter<boolean>();
  refreshDataEvent = new EventEmitter<boolean>();

/********************************* Profile Image Path *********************************/
  isProfileImageChange: boolean;
  profileImageChangeEvent =   new EventEmitter<boolean>(); 
  // profileImageChangeEvent:Subject<boolean> = new Subject<boolean>();
  // constructor() {
  //     // this.profileImageChangeEvent.subscribe((value) => {
  //     //       this.isProfileImageChange = value;
  //     //   });
  // }
/********************************* Profile Image Path *********************************/

  constructor() { 
  }

  setData(data: ProgressBarModel) {
    let self = this;

    if (self.progressBarData == null || self.progressBarData == undefined && self.progressBarData.length == 0) {
      self.progressBarData = [];
    }
    self.progressBarData.push(data);
    self.refreshProgressbarEvent.emit(true);
    
  }

    toggleProfileImageChange() { 
       this.profileImageChangeEvent.emit(true);
    }

  getData(): ProgressBarModel[] {
    let self = this;
    return self.progressBarData;
  }

  removeData(progressBarID, progressBarType) {
    let self = this;

    if (self.progressBarData && self.progressBarData.length > 0) {
      remove(self.progressBarData, function(n) {
        return n.progressBarType == progressBarType && n.progressBarID == progressBarID;
      });

      self.refreshProgressbarEvent.emit(true);
      self.refreshDataEvent.emit(true);
    }
  }

 
}
