// Angular
import { Component, OnInit, NgZone, Directive } from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../../core/_base/layout';

//Services
import { StorageService } from '../../../../services/utilities/storage.service';

@Directive()
@Component({
	selector: 'kt-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
	// Public properties
	headerLogo: string;
	asideDisplay: boolean;
	showHideHamburgerClassName: string =  "show-hamburger kt-header-mobile__toolbar";

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
	};

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		private storageService: StorageService,
		private ngZone: NgZone) {
		this.subscribeToEvents();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.headerLogo = this.layoutConfigService.getStickyLogo();
		this.asideDisplay = this.layoutConfigService.getConfig('aside.self.display');
	}

	subscribeToEvents() {
		let self = this;
		self.storageService.showHideHamburgerEvent.subscribe((data) => {
		  this.ngZone.run(() => {  
			if (data == true) { 
			  self.showHideHamburgerClassName = "show-hamburger kt-header-mobile__toolbar";
			}
			else{
			  self.showHideHamburgerClassName = "hide-hamburger kt-header-mobile__toolbar";
			}
		  });
		});
	  }
}
