import { Pipe, PipeTransform } from '@angular/core';
import {CommonService} from '../services/utilities/common.service';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  constructor(private commonService: CommonService){

  }
  
  transform(text: any, length: number): any {
    return this.getTruncatedSubjectDetail(text, length);
  }
  
  getTruncatedSubjectDetail(text, length) {
    let self = this;
    if (!self.commonService.isNullOrEmpty(text)) {
      if (text.length > length) {
        text = text.trim().substring(0, length) + '...';
      }
    }
    return text;
  }

}
