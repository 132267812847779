//@Packages
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';

const HostURL  :string= environment.HostURL;
const AdminHostURL :string= environment.AdminHostURL;


@Injectable({
    providedIn: 'root'
})
export class AppConfig {

    constructor(private _storageService: StorageService) { }

    public UserAvtarImage_URL =  '../assets/media/images/user-empty-avatar.png';   

    public TutorProfileAttachmentUrl = HostURL + 'Upload/Tutor/';
    public UserProfileAttachmentUrl = HostURL + 'Upload/User/';
    public ImportExcelTemplateUrl = '../assets/importfile/StudentImportList.xlsx';
    
    public InstituteUserProfileUrlOfAdminPortal = AdminHostURL + 'Upload/Institute/';
    
    public InstituteUserProfileAttachmentUrl = AdminHostURL + 'Upload/User/';
    public StaticImageURL = HostURL + 'Images/';        

    public GoogleCaptchaSiteKey = "6LeSyagZAAAAAPRlFhUMsaHtFC-xeJm9-4DOASO3";
	public GoogleCaptchaSecretKey = "6LeSyagZAAAAAPPytX77YymkpmCBQWhsbVKp10cjs";

	public YouTubeVideoAPI_URL = 'https://www.googleapis.com/youtube/v3/videos';

    public setAuthHeader(headers: HttpHeaders) {
        headers.append('Content-Type', 'application/json');
        headers.append('x-access-token', this._storageService.getToken());
    }

    public getHeaders() {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        //console.log(this._storageService.getToken());
        headers.append('Authorization', `Bearer ${this._storageService.getToken()}`);
        return headers;
    }

}
