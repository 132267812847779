// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressbar_bottom {
  background: #07b307;
  color: #fff;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34%;
  margin-left: auto;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 500;
  box-shadow: 0px 0px 6px #9c9494;
  border-radius: 5px;
}
.progressbar_bottom p {
  margin-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/shared/progressbar/progressbar.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,kBAAA;AACJ;AAAI;EACI,2BAAA;AAER","sourcesContent":[".progressbar_bottom {\r\n    background: #07b307;\r\n    color: #fff;\r\n    padding: 8px 0px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 34%;\r\n    margin-left: auto;\r\n    font-size: 12px;\r\n    margin-top: 8px;\r\n    font-weight: 500;\r\n    box-shadow: 0px 0px 6px #9c9494;\r\n    border-radius: 5px;\r\n    p {\r\n        margin-bottom: 0 !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
