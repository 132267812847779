import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { NewApiResult } from '../viewmodels/common/NewApiResult';

const BaseInstituteURL = environment.BaseInstituteURL;
const ApiUrl = environment.ApiURL;


@Injectable({
	providedIn: 'root'
})
export class CourseService {

	constructor(private http: HttpClient) { }

	getAllCourseByExamID(examID, instituteID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Course/GetAllCourseByExamID?examID=${examID}&instituteID=${instituteID}`);
	}

	getCourseForGroupFilter(instituteID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Course/GetCourseForGroupFilter?instituteID=${instituteID}`);
	}

	getCourseDetailByInstitute(courseID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Course/GetCourseDetailByInstitute?courseID=${courseID}&instituteID=${instituteID}`);
	}

	getCourseWithGroupByTutor(tutorID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Course/GetCourseWithGroupByTutor?tutorID=${tutorID}&instituteID=${instituteID}`);
	}

	getRegistrationCourses() {
		return this.http.get<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/course/GetRegistrationCourses`);

	}
}


