import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, Directive } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MathjaxComponent } from '../mathjax/mathjax.component';
import { MathJaxIdPipe } from '../../../../pipes/math-jax-id.pipe';

@Directive()
@Component({
  selector: 'kt-latex-preview',
  templateUrl: './latex-preview.component.html',
  styleUrls: ['./latex-preview.component.scss']
})
export class LatexPreviewComponent implements OnInit {

  previewTxt: string;
 
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private _dialogRef: MatDialogRef<LatexPreviewComponent>,
    private cdr: ChangeDetectorRef,
    private mathJaxIdPipe: MathJaxIdPipe,
  ) { 
    this.previewTxt = this.data;
  }

  ngOnInit() {
  }
  closeModal() {
    this._dialogRef.close();
  }
}
