// Angular
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation, ViewRef, Directive  } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ApexAxisChartSeries, ApexChart, ApexFill, ApexTooltip, ApexXAxis, ApexLegend, ApexDataLabels, ApexTitleSubtitle,
	ApexPlotOptions, ApexYAxis
} from "ng-apexcharts";

import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { CourseService } from '../../../../services/course.service';


import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupService } from '../../../../services/group.service';
import { SubjectService } from '../../../../services/subject.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StudentService } from '../../../../services/student.service';
import { AppConfig } from '../../../../services/utilities/app-config';
import { LessonService } from '../../../../services/lesson.service';
import { InstituteVM } from '../../../../viewmodels/InstituteVM';
import { InstituteService } from '../../../../services/institute.service';
import { TutorService } from '../../../../services/tutor.service';
import { LayoutConfigService } from '../../../../core/_base/layout/services/layout-config.service';
import { CourseVM } from '../../../../viewmodels/CourseVM';

declare var $: any;
declare global {
	interface Window {
		Apex: any;
	}
}

@Directive()
@Component({
	selector: 'kt-register-new',
	templateUrl: './register-new.component.html',
	styleUrls: ['register-new.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class NewRegisterInstituteComponent implements OnInit {
	isLoading: boolean = false;
	isSubmitted: boolean = false;
	viewMode = 0;
	frmRegister: UntypedFormGroup;
	resultInstitute: InstituteVM;
	headerLogo: string;
	courses: CourseVM[] = [];

	constructor(private fb: UntypedFormBuilder, private commonService: CommonService, private storageService: StorageService, private router: Router,
		private dashboardService: DashboardService, private cdr: ChangeDetectorRef, private courseService: CourseService,
		private _formBuilder: UntypedFormBuilder, private groupService: GroupService, private subjectService: SubjectService, private tutorService: TutorService,
		private instituteService: InstituteService, private studentService: StudentService, private appConfig: AppConfig, private layoutConfigService: LayoutConfigService,
		private lessonService: LessonService, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {

	}

	ngOnInit() {
		let self = this;
		self.buildForm();
		self.headerLogo = this.layoutConfigService.getLogo();
		self.initDropdownValues();
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.frmRegister.controls[controlName];
		if (!control) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	buildForm() {

		const model = new InstituteVM();

		this.frmRegister = this.fb.group({
			contactName: [model.contactName, Validators.maxLength(320)],
			instituteName: [model.instituteName, Validators.maxLength(150)],
			mobileNo: [model.mobileNo, [Validators.maxLength(13), Validators.pattern('[- +()0-9]+')]],
			email: [model.email, [Validators.maxLength(100), Validators.email]],
			courses: [model.courses],
		});
	}

	registerInstitute() {
		const self = this;
		self.isSubmitted = true;
		const controls = self.frmRegister.controls;

		let isFormValid = true;
		if (self.frmRegister.invalid) {

			Object.keys(controls).forEach(controlName => {
				controls[controlName].markAsTouched();
			});
			isFormValid = false;
		}

		if (isFormValid === false) {
			self.commonService.showToaster("Please enter all the details", "error");
			return;
		}

		let institute = self.frmRegister.value as InstituteVM;
		institute.type = 1;

		self.commonService.showLoader();

		self.instituteService.register(institute).subscribe(data => {
			if (data.success == true) {
				self.resultInstitute = data.data as InstituteVM;
				if (self.resultInstitute != null) {
					self.resultInstitute.email = institute.email;
					self.resultInstitute.courses = institute.courses;
					self.resultInstitute.type = 1;
					self.viewMode = 1;
				}

				self.addTutorAndBatch(institute, self.resultInstitute);
				
			}
			else {
				self.commonService.showToaster(data.data, "error");
				self.commonService.hideLoader();
			}


		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

	getCourses() {
		let self = this;
		return new Promise((resolve, reject) => {
			self.courseService.getRegistrationCourses().subscribe(data => {
				resolve(data);
			}, error => {
				resolve(error);
			});
		});
	}

	addTutorAndBatch(institute: InstituteVM, resultInstitute: InstituteVM) {
		const self = this;

		institute.courseID = resultInstitute.courseID;
		institute.coursesEnrolled = resultInstitute.coursesEnrolled;
		institute.instituteId = resultInstitute.instituteId;
		this.tutorService.registerTutorAndBatch(institute).subscribe(data => {
			if (data.success == true) {
				self.viewMode = 1;
				if (!(this.cdr as ViewRef).destroyed) {
					this.cdr.detectChanges()
				}
				self.commonService.showToaster("Success", "success");
			}
			else {
				self.commonService.showToaster(data.message, "error");
			}


			self.commonService.hideLoader();
		}, (err) => {
			self.commonService.hideLoader();
			self.commonService.showToaster(err.error, "error");
		});
	}

	initDropdownValues() {
		let self = this;
		self.commonService.showLoader();
		Promise.all(
			[
				self.getCourses(),
			]).then((data: any) => {

				if (data != null) {

					let courseResult = data[0];

					if (courseResult !== null && courseResult.statusCode === 1) {
						self.courses = courseResult.data.response as CourseVM[];
					}

					self.commonService.hideLoader();
				}
			}).catch((error) => {
				self.commonService.hideLoader();
				self.commonService.showToaster(error.error, "error");
				console.error(error);
			});
	}
}
