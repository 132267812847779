//@Packages
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//@View Models
import { ApiResult } from '../viewmodels/common/ApiResult';

//@Services
import { environment } from '../../environments/environment';
import { ApiVersion } from '../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
  providedIn: 'root'
})
export class TutorService {

  constructor(private http: HttpClient) { }

  getTutorByIDNew(tutorID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/GetTutorByIDNew?tutorID=${tutorID}&instituteID=${instituteID}`);
  }

  insertTutorNew(model) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/InsertTutorNew`, model);
  }

  updateTutorNew(model) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/UpdateTutorNew`, model);
  }

  deleteTutor(tutorID: number, instituteID: number) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/DeleteTutor?tutorID=${tutorID}&instituteID=${instituteID}`, null);
  }

  updateTutorStatus(tutorID: number, status: boolean, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/UpdateTutorStatus?tutorID=${tutorID}&status=${status}&instituteID=${instituteID}`);
  }

  searchInstituteTutor(input) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/SearchInstituteTutor`, input);
  }

  getInstituteSubscriptionCourseInfoBySubjectIDs(subjectIDs: string, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Subscription/GetInstituteSubscriptionCourseInfoBySubjectIDs?subjectIDs=${subjectIDs}&instituteID=${instituteID}`);
  }

  getAllTutors(instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/GetAll?instituteID=${instituteID}`);
  }

  getTutorByCourseID(courseID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/GetTutorByCourseID?courseID=${courseID}&instituteID=${instituteID}`);
  }

  getTutorView(tutorID:number, instituteID:number){
     return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/GetTutorView?tutorID=${tutorID}&instituteID=${instituteID}`);
  }

  getInstituteTutorByCourseIDWithPagination(input) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/GetInstituteTutorByCourseIDWithPagination`, input);
  }

	registerTutorAndBatch(input) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Tutor/registerTutorAndBatch`, input);
	}
}
