// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .kt-portlet.kt-portlet--ngviewer .kt-portlet__body {
  padding: 1.75rem 1.75rem;
}
:host .kt-portlet.kt-portlet--ngviewer .kt-portlet__body .kt-portlet__code.kt-portlet__code--show {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/partials/content/general/material-preview/material-preview.component.scss"],"names":[],"mappings":"AACC;EACO,wBAAA;AAAR;AAGI;EACK,mBAAA;AADT","sourcesContent":[":host {\r\n\t.kt-portlet.kt-portlet--ngviewer .kt-portlet__body {\r\n        padding: 1.75rem 1.75rem;\r\n    }\r\n\r\n    .kt-portlet.kt-portlet--ngviewer .kt-portlet__body .kt-portlet__code.kt-portlet__code--show {\r\n         margin-bottom: 20px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
