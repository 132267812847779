// Packages
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild, AfterViewInit, ChangeDetectorRef, Directive } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { inject } from '@angular/core/testing';
import { UntypedFormBuilder, FormGroup, FormArray, FormControl, Validators, Form } from '@angular/forms';

// Image Cropper 
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile } from 'ngx-image-cropper';

// Sevices
import { CommonService } from '../../../../services/utilities/common.service';

// @Directive()
@Component({
  selector: 'kt-crop-attachment',
  templateUrl: './crop-attachment.component.html',
  styleUrls: ['./crop-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropAttachmentComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  mainImageFile: File;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  imageCropper: boolean = false;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  cropper = {
    x1: 100,
    y1: 100,
    x2: 200,
    y2: 200
  }
  imgResultBeforeCompress: string;

  imgResultAfterCompress: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CropAttachmentComponent>,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService) {
  }

  ngAfterViewInit() {
  }

  ngOnInit() {

  }

  closemodel() {
    if (this.mainImageFile != undefined && this.croppedImage != "") {
      let obj = {
        mainImageFile: this.mainImageFile,
        croppedImageFile: this.croppedImage != undefined ? this.croppedImage : null,
      }
      this.dialogRef.close(obj);
    }
    else {
      let obj = {
        mainImageFile: null,
        croppedImageFile: null,
      }
      this.dialogRef.close(obj);
    }
  }

  cancelmodel() {
    let obj = {
      mainImageFile: null,
      croppedImageFile: null
    }
    this.dialogRef.close(obj);
  }


  onSelectFile(event: any): void {
    let self = this;

    if (event.target.files && event.target.files[0]) {
      this.imageChangedEvent = event;

      var mimeType = event.target.files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        self.commonService.showToaster("Only image is supported", "error");
        return;
      }
      self.addImageAttachment(event.target.files[0]);
    }
  }

  addImageAttachment(file: any) {
    let self = this;
    self.imageCropper = true;
    /***************************render image *****************************/
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.croppedImage = event.target.result;
      self.mainImageFile = file;
      this.cdr.detectChanges();
    }
    reader.readAsDataURL(file);
    /***************************render image *****************************/
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    //console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

}
