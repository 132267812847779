// Angular
import { Component, Input, OnInit, NgZone, ChangeDetectorRef, ViewChild, Renderer2, Directive } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

// @Services
import { NotificationService } from '../../../../../services/notification.service';
import { StorageService } from '../../../../../services/utilities/storage.service';
import { CommonService } from '../../../../../services/utilities/common.service';

// @View Models
import { NotificationVM } from '../../../../../viewmodels/NotificationVM';
import { ApiResult } from '../../../../../viewmodels/common/ApiResult';
import { DateFormat } from '../../../../../enum/DateFormat';
import { DateSeprator } from '../../../../../enum/DateSeprator';
import { NotificationAssociation } from '../../../../../enum/NotificationAssociation';
declare var $: any;

@Directive()
@Component({
	selector: 'kt-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit {

	userID: number = 0;
	notifications: NotificationVM[] = [];

	@ViewChild('notificationDropdown', { static: false }) notificationDropdown;

	// Show dot on top of the icon
	@Input() dot: string;

	// Show pulse on icon
	@Input() pulse: boolean;

	@Input() pulseLight: boolean;

	// Set icon class name
	@Input() icon = 'flaticon2-bell-alarm-symbol';
	@Input() iconType: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() type: 'brand' | 'success' = 'success';

	/**
	 * Component constructor
	 *
	 * @param sanitizer: DomSanitizer
	 */
	constructor(private sanitizer: DomSanitizer,
		private router: Router,
		private ngZone: NgZone,
		private cdr: ChangeDetectorRef,
		private renderer: Renderer2,
		private notificationService: NotificationService,
		private storageService: StorageService,
		private commonService: CommonService) {
			 
	}

	ngOnInit(): void {
		const userID = this.storageService.getUserId();

		if (!this.commonService.isNullOrEmpty(userID)) {
			this.userID = +userID;
		}


		this.getUserNotifications();
	}

	showSearchBox(){
		$("#search_icon").css("display", "none");

	}

	backGroundStyle(): string {
		if (!this.bgImage) {
			return 'none';
		}

		return 'url(' + this.bgImage + ')';
	}

	getUserNotifications() {
		let self = this;
		self.notificationService.getByNotificationSentTo(self.userID, self.storageService.getTimezoneOffset()).subscribe((data) => {
			self.notifications = [];

			const result = data as ApiResult;
			if (result.success == true) {
				self.notifications = result.data as NotificationVM[];
			} else {
				self.commonService.showToaster(result.data, "error");
			}

			this.getUnreadableNotifications;
			self.cdr.detectChanges();
		}, (err) => {
			console.log(err);
			self.commonService.showToaster(err.error, "error");
		})
	}

	get getUnreadableNotifications(): number {
		let self = this;

		if (self.notifications != null && self.notifications != undefined && self.notifications.length > 0) {
			const notifications = self.notifications.filter(i => i.isView == false);

			if (notifications != null && notifications != undefined) {
				return notifications.length;
			} else {
				return 0;
			}
		}

		return 0;
	}

	parseDateTime(date: string): string {
		if (date != null && date != undefined && date != "") {
			let redableDate = this.commonService.getDateTimeString(new Date(date), DateFormat.DDMMYYYY, DateSeprator.SLASH);
			return redableDate;
		}
		return "";
	}

	readNotification(d: NotificationVM) {
		let self = this;

		if (d.isView == false) {
			self.commonService.showLoader();

			self.notificationService.updateViewFlag(d.notificationID, true).subscribe((data) => {
				self.commonService.hideLoader();
				const result = data as ApiResult;
				if (result.success == true) {
					d.isView = true;
					//Remove show class from notification dropdown
					self.renderer.removeClass(self.notificationDropdown.nativeElement, 'show');
 
					if (d.associationName == NotificationAssociation.Question) {
						self.router.navigate(['institute/question/question-edit', d.associationID]);
					}
					else if (d.associationName == NotificationAssociation.PargraphQuestion) {
						self.router.navigate(['institute/question/paragraph-question-edit', d.associationID]);
					}
					else if (d.associationName == NotificationAssociation.TestMaster || d.associationName == NotificationAssociation.StudentTest) {
						self.router.navigate(['institute/test-master/test-result', d.associationID]);
					}
					else{
						self.router.navigate(['institute/question/question-edit', d.associationID]);
					}
				} else {
					self.commonService.showToaster(result.data, "error");
				}
			}, (err) => {
				console.log(err);
				self.commonService.hideLoader();
				self.commonService.showToaster(err.error, "error");
			})
		}
	}


}
