// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `kt-base {
  opacity: 0;
  height: 100%;
}
kt-base .kt-grid--root {
  height: 100%;
}

.kt-page--loaded kt-base {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.kt-page-color {
  background-color: #f8f6fc !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/base/base.component.scss"],"names":[],"mappings":"AAAA;EACC,UAAA;EACA,YAAA;AACD;AACC;EACC,YAAA;AACF;;AAIC;EACC,UAAA;EACA,kCAAA;AADF;;AAMA;EACI,oCAAA;AAHJ","sourcesContent":["kt-base {\r\n\topacity: 0;\r\n\theight: 100%;\r\n\r\n\t.kt-grid--root {\r\n\t\theight: 100%;\r\n\t}\r\n}\r\n\r\n.kt-page--loaded {\r\n\tkt-base {\r\n\t\topacity: 1;\r\n\t\ttransition: opacity 1s ease-in-out;\r\n\t}\r\n}\r\n\r\n\r\n.kt-page-color {\r\n    background-color: #f8f6fc !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
