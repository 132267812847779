//@Packages
import { Component, OnInit, ChangeDetectorRef, ViewRef, Renderer2, ElementRef, ViewChild, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

//@View-Models
import { ResetPasswordModel } from '../../../../models/ResetPasswordModel';
import { ApiResult } from '../../../../viewmodels/common/ApiResult';

//@Services
import { AuthNoticeService } from '../../../../core/auth/auth-notice/auth-notice.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { MustMatch } from '../../../..//validator/must-match.validator';
import { REGEXP } from '../../../../shared/regexp';
declare var $: any;
// @Directive()
@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userId: number;
  resetpasswordModel: ResetPasswordModel = new ResetPasswordModel();
  resetPasswordForm: UntypedFormGroup;
  loading = false;
  isSubmitted: boolean = false;
  hideForgotPassword = true;
  hideforgetCnfPassword = true;

  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private regexp: REGEXP,
    private renderer: Renderer2,
    private formBuilder: UntypedFormBuilder) {

    this.activeRoute.queryParams.subscribe(params => {
      this.userId = params['id'] || "";
    });

  }

  ngOnInit() {
    this.initResetPasswordForm();
    this.checkForgotPasswordDetail();
  }

  ngAfterViewInit() {
    $("#newPassword").focus();
  }

  initResetPasswordForm() {
    let self = this;
    self.resetpasswordModel.userID = self.userId;
    self.resetPasswordForm = this.formBuilder.group({
      userID: [self.resetpasswordModel.userID],
      newPassword: [self.resetpasswordModel.newPassword, [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this.regexp.PASSWORD_EXP)]],
      confirmPassword: [self.resetpasswordModel.confirmPassword, [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this.regexp.PASSWORD_EXP)]],
      updatedBy: [self.userId]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });

  }

  checkForgotPasswordDetail() {
    let self = this;

    if (self.userId > 0) {
      self.commonService.showLoader();

      self.auth.checkForgotPasswordDetail(self.userId).subscribe(response => {
        self.commonService.hideLoader();

        const result = response as ApiResult;

        if (result.success == false) {
          self.commonService.showToaster(result.message, 'error');
          self.router.navigate(['/institute/auth/login']);
        }
        if (!(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges()
        }
      }, err => {
        console.log(err);
        self.commonService.showToaster(err.error, 'error');
        self.commonService.hideLoader();
      });
    }
  }


  resetPassword() {
    let self = this;
    self.isSubmitted = true;
    const controls = self.resetPasswordForm.controls;
    /** check form */
    if (self.resetPasswordForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    self.commonService.showLoader();

    self.auth.resetPassword(this.resetPasswordForm.value).subscribe((response) => {

      let result = response as ApiResult;

      if (result != null && result != undefined) {
        self.commonService.hideLoader();

        if (result.success == true) {
          self.commonService.showToaster(result.message, 'success');
          self.router.navigate(['/institute/auth/login']);
        } else {
          self.commonService.showToaster(result.message, 'error');
          //self.authNoticeService.setNotice(this.translate.instant(result.message), 'danger');
          self.cdr.detectChanges();
        }
      }
      self.commonService.hideLoader();
      if (!(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges()
      }
    }, (err) => {
      self.commonService.hideLoader();
      self.commonService.showToaster(err.error, 'error');
      //self.authNoticeService.setNotice(err.error, 'danger');
      self.cdr.detectChanges();
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  goBack() {
    this.router.navigate(['/institute/auth/login']);
  }

  keyDownForm(event) {
    if (event.keyCode == 13) {
      this.resetPassword();
    }
  }


}
