import { Pipe, PipeTransform } from '@angular/core';
import { AttachmentURLWithContent } from '../viewmodels/common/AttachmentURLWithContent';
import { CommonService } from '../services/utilities/common.service';

@Pipe({
  name: 'isImageContentPipe'
})
export class IsImageContentPipePipe implements PipeTransform {

  constructor(private commonService: CommonService) {

  }
  transform(attachmentObj: AttachmentURLWithContent, args: any[]): any {
    return this.getIsImageContent(attachmentObj);
  }

  getIsImageContent(attachmentObj: AttachmentURLWithContent) {
    let fileName = attachmentObj.attachmentURL;
   
    if (!this.commonService.isNullOrEmpty(fileName) && !this.commonService.isNullOrEmpty(attachmentObj.fileExtensionTerm)) {
      let validImageExts = new Array("jpg", "jpeg", "png", "tiff");
      let fileExtension = fileName.split('.').pop();
      fileExtension = fileExtension.substring(fileExtension.lastIndexOf('.'));
     
      if (validImageExts.includes(fileExtension.toLowerCase())) {
        return true;
      }
      else { 
        // let validFileExts = new Array("doc", "docx", "html","pdf","txt", "ppt", "pptx");
        // if(!validFileExts.includes(attachmentObj.fileExtensionTerm.split('.').pop())){
        //   attachmentObj.isDisplayFilePreview = false;
        // }else{
        //   attachmentObj.isDisplayFilePreview = true;
        // }
        return false;
      }  
    } else {
      return false;
    }

  }
}
