import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { of } from 'rxjs';
import { NewApiResult } from '../viewmodels/common/NewApiResult';

const BaseInstituteURL = environment.BaseInstituteURL;
const BaseAdminURL = environment.AdminHostURL + "api/";
const ApiUrl = environment.ApiURL;

@Injectable({
	providedIn: 'root'
})
export class AttachmentService {
	constructor(private http: HttpClient) { }


	getSummary(id: number, summaryType: string, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/GetSummary?id=${id}&summaryType=${summaryType}&instituteID=${instituteID}`);
	}

	saveVideo(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/SaveVideo`, model);
	}

	updateTitleDescription(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/UpdateTitleDescription`, model);
	}

	saveDocument(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/SaveDocument`, model);
	}

	getAttachments(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/GetAttachments`, model);
	}

	getList(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/GetList`, model);
	}

	getSubjectSummary(instituteID, subjectID) {
		return this.http.get<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/mydata/GetSubjectSummary?subjectID=${subjectID}&instituteID=${instituteID}`);
	}

	getLessonSummary(instituteID, lessonID) {
		return this.http.get<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/mydata/GetLessonSummary?lessonID=${lessonID}&instituteID=${instituteID}`);
	}

	getListByLessonID(model) {
		return this.http.post<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/mydata/GetListByLessonID`, model);
	}

	getQuestionsByLessonID(model) {
		return this.http.post<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/mydata/GetQuestionsByLessonID`, model);
	}

	getListByTopicID(model) {
		return this.http.post<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/mydata/GetListByTopicID`, model);
	}

	getQuestionsByTopicID(model) {
		return this.http.post<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/mydata/GetQuestionsByTopicID`, model);
	}

	getAttachment(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/GetAttachment`, model);
	}

	deleteAttachment(attachmentID: number, instituteID: number) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Attachment/DeleteAttachment?attachmentID=${attachmentID}&instituteID=${instituteID}`);
	}
}
