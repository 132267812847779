import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'contactMask' })
export class ContactMaskPipe implements PipeTransform {
	transform(value: string, type: string) {
		if (type == "N") {
			if (value.length > 2) {
				return value[0] + value.slice(1).substring(0, value.length - 2).replace(/[0-9]/g, '*') + value[value.length - 1];
			}
			else {
				return value;
			}
		}
		else {
			debugger;
			var finalText = "";
			if (value != undefined) {
				var str = value.split("@");
				if (str.length >= 2) {
					var start = str[0];
					if (start.length > 1) {
						finalText = start[0] + "*".repeat(start.length - 1);
					}
					else {
						finalText = start;
					}

					finalText = finalText + "@";

					var domainText = str[1].split(".");
					for (var i = 0; i < domainText.length; i++) {
						var dt = domainText[i];
						if (i == 0) {
							if (dt.length > 1) {
								finalText = finalText + dt[0] + "*".repeat(dt.length - 1);
							}
							else {
								finalText = finalText + dt;
							}
						}
						else {
							finalText = finalText + dt; 
						}

						if (i < domainText.length - 1) {
							finalText = finalText + ".";
						}

					}
				}
			}
			
			return finalText;
		}

	}
}
