import { ItemVM } from "./ItemVM";
import { TimeBasedEfficiencyVM } from "./TimeBasedEfficiencyVM";

export class NotificationStudentVM {
	instituteNotificationID?: number;
	instituteID?: number;
	studentID?: number;
	notificationType: string;
	userID?: number;
	displayName: string;
    userName:string;
	token: string;
	isValid: boolean;
	testID?: number;
	progressReport: ProgressReportVM
}

export class ProgressReportVM {
	studentID?: number;
	studentName?: number;
	course: string;
	date: Date;
	studentProficiency: number;
	averageProficiency: number;
	testScores: SubjectTestMarksVM[] = [];
	subjectsProficiency: ItemEfficiencyVM[] = [];
	chaptersProficiency: ItemEfficiencyVM[] = [];
	topicsProficiency: ItemEfficiencyVM[] = [];
	timeBasedEfficiency: TimeBasedEfficiencyVM[] = [];
	subjectTimeBasedEfficiency: TimeBasedEfficiencyVM[] = [];
	subjects: ItemVM[] = [];
	tests: ItemVM[] = [];
	instituteLogo: string;
}

export class ItemEfficiencyVM {
	id: number;
	name: string;
	efficiency?: number;
	parentID?: number;
	averageEfficiency?: number;
}

//export class SubjectTestMarksVM {
//	subjectID: number;
//	tests: TestMarksVM[] = [];
//}

export class SubjectTestMarksVM {
	testID: number;
	subjectName: string;
	subjectID?: number;
	marksEarned?: number;
	totalMarks?: number;
	percentage?: number;
}




