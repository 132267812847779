import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';
import { NewApiResult } from '../viewmodels/common/NewApiResult';

const BaseInstituteURL = environment.BaseInstituteURL;
const ApiUrl = environment.ApiURL;

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient) { }

	searchUser(searchInput) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/SearchUser`, searchInput);
	}



	deleteUser(userID) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/DeleteUser?userID=${userID}`, null);
	}

	updateUserStatus(userID, status) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/UpdateUserStatus?userID=${userID}&status=${status}`);
	}

	searchByName(searchName, instituteID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/SearchByName?searchName=${searchName}&instituteID=${instituteID}`);
	}

	isLoginAllowed(userID, instituteID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/IsLoginAllowed?userID=${userID}&instituteID=${instituteID}`);
	}

	getUserByID(userID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/UserByID?userID=${userID}`);
	}

	getAutoGenerateUserPassword() {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/GetAutoGenerateUserPassword`);
	}

	updateIsEmailVerified(userID, isEmailVerified) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/UpdateIsEmailVerified?userID=${userID}&isEmailVerified=${isEmailVerified}`);
	}

	changePassword(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/ChangePassword`, model);
	}


	getUserProfileByID(userID: number, instituteID: number, associationTypeTerm: string) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/GetUserProfileByID?userID=${userID}&instituteID=${instituteID}&associationTypeTerm=${associationTypeTerm}`);
	}

	searchAdminUser(searchInput) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/SearchAdminUser`, searchInput);
	}

	insertUserNew(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/InsertUserNew`, model);
	}



	updateUserNew(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/UpdateUserNew`, model);
	}

	updateProfilePhoto(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/UpdateProfilePhoto`, model);
	}

	getUserByIdNew(userID, instituteID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/User/GetUserByIdNew?userID=${userID}&instituteID=${instituteID}`);
	}

	InsertStudentNewFromInstitute(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Student/InsertStudentNewFromInstitute`, model);
	}

	getStudentByIdNew(instituteID, studentID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Student/GetStudentByIDNew?instituteID=${instituteID}&studentID=${studentID}`);
	}

	updateStudentNew(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Student/UpdateStudentNew`, model);
	}
	uploadExcel(ExcelUploadVM) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Student/UploadStudent`, ExcelUploadVM);
	}
	insertStudentfromExcel(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Student/InsertStudentFormExcel`, model);
	}

	getInstituteConfigurations(instituteID) {
		return this.http.get<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/User/GetInstituteConfigurations?instituteID=${instituteID}`);
	}

	saveInstituteConfiguration(model) {
		return this.http.post<NewApiResult>(`${ApiUrl}${ApiVersion.V1}/User/SaveInstituteConfiguration`, model);
	}

}
