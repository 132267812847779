// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .kt-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
:host ::ng-deep .kt-loading-bar .progress-bar {
  background-color: #5d78ff;
}
:host ::ng-deep .kt-header__topbar-item {
  height: 100%;
}
@media (min-width: 1025px) {
  :host ::ng-deep .kt-header__topbar,
  :host ::ng-deep .kt-header__topbar-item-wrapper {
    height: 100%;
  }
}

kt-menu-horizontal {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/views/theme/header/header.component.scss"],"names":[],"mappings":"AAEQ;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;AADZ;AAEY;EAEI,yBAAA;AADhB;AAIQ;EACI,YAAA;AAFZ;AAIQ;EACI;;IAEI,YAAA;EAFd;AACF;;AAOA;EACI,WAAA;AAJJ","sourcesContent":[":host {\r\n    ::ng-deep {\r\n        .kt-loading-bar {\r\n            position: absolute;\r\n            top: 0;\r\n            left: 0;\r\n            right: 0;\r\n            width: 100%;\r\n            .progress-bar {\r\n                // brand color\r\n                background-color: #5d78ff;\r\n            }\r\n        }\r\n        .kt-header__topbar-item {\r\n            height: 100%;\r\n        }\r\n        @media (min-width: 1025px) {\r\n            .kt-header__topbar,\r\n            .kt-header__topbar-item-wrapper {\r\n                height: 100%;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\nkt-menu-horizontal {\r\n    width: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
