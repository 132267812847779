import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ElementRef, Directive } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as objectPath from 'object-path';

// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { StorageService } from './services/utilities/storage.service';
import { ApiResult } from './viewmodels/common/ApiResult';
import { AuthService } from './core/auth/_services/auth.service';
import { RoleService } from './services/role.service';
import { RolePrivilege } from './models/RolePrivilege';

@Directive()
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'Metronic';
	loader: boolean;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	// Public proprties
	loaderLogo: string;
	loaderType: string;
	loaderMessage: string;
	@ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;
	
	userID?: number = 0;
	instituteID?: number = 0;

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
				         private router: Router,
				         private layoutConfigService: LayoutConfigService,
						 private splashScreenService: SplashScreenService,
						 private storageService :StorageService,
						 private roleService: RoleService,
						 private auth: AuthService) {

		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		//Remove Student Test or Quiz Started Flag
		this.storageService.removeStudentTestOrQuizStartedFlag();

		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);

		const loaderConfig = this.layoutConfigService.getConfig('loader');
		this.loaderLogo = objectPath.get(loaderConfig, 'logo');
		this.loaderType = objectPath.get(loaderConfig, 'type');
		this.loaderMessage = objectPath.get(loaderConfig, 'message');

		this.splashScreenService.init(this.splashScreen);

		
		// //Fetch Role Right Permission
		// if (this.auth.isAuthenticated() == true) {
		// 	this.userID = +this.storageService.getUserId();
		// 	this.instituteID = +this.storageService.getInstituteID();

		// 	if (this.userID != undefined && this.userID != null && +this.userID > 0) {
		// 		this.roleService.getRolePrivilegeByUserAndInstitute(this.userID, this.instituteID).subscribe(data => {
		// 			const result = data as ApiResult;

		// 			if (result.success == true) {
		// 				this.storageService.setRolePrivileges(result.data as RolePrivilege[]);
		// 				this.storageService.goToLandingPage();
		// 			}
		// 		}, error => {
		// 			console.log(error);
		// 		})
		// 	}
		// }
	}


	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
