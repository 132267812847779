import { UserRoleVM } from './UserRoleVM';

export class UserVM {
	userID?: number;
	roleID?: number;
	userTypeTerm?: string;
	associationID?: number;
	associationTypeTerm?: string;
	firstName?: string;
	lastName?: string;
	phoneNo?: string;
	userName?: string;
	displayName?: string;
	password?: string;
	confirmPassword?: string;
	statusTerm?: string;
	lastLoginDateTime?: Date;
	createdBy?: number;
	createdOn: Date;
	profilepic?: string;
	isActive?: boolean;
	instituteID?: number;
	resetPasswordExpiryDate?: Date;
	isResetPasswordSuccess?: boolean;
	updatedOn: Date;
	updatedBy?: number;
	isInitialPasswordResetComplete?: boolean;
	isSignupUser?: boolean;
	isEmailVerified?: boolean;
	updatelog?: Date;
	userRoles?: UserRoleVM[] = [];
	emailVerificationCode: string;

	otpNumber: string;
	otpExpireAt?: Date;
}
