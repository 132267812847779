
export class UserActivityVM {
	userActivityID?: number;
	instituteID: string;
	userID: string;
	studentID: string;
	tutorID: string;
	instituteAdminID?: number;
	system?: string;
	module: string;
	subModule: string;
	action: string;
	actionParam: string;
	paramType: string;
	paramID?: number;
	ipAddress: string;
	browser: string;
	deviceID: string;
	deviceName: string;
	sdkOSVersion: string;
	appVersion: string;
	updatedOn: Date;
	updatedBy: string;
}
