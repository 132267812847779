export enum ProjectTermCategory {
    QuestionType = "QuestionType",
    ComplexityLevel = "ComplexityLevel",
    QuestionStatusTerm = "QuestionStatusTerm",
    SubjectStatusTerm = "SubjectStatusTerm",
    CourseStatusTerm = "CourseStatusTerm",
    CourseMinimumQualification = "CourseMinimumQualification",
    SourceOfQuestion = "SourceOfQuestion",
    ExamFrequency = "ExamFrequency",
    ExamType = "ExamType",
    QuestionCategory = "QuestionCategory",
    InstituteType = "InstituteType",
    InstituteStatusType="InstituteStatusType",
    CertificateTemplateType = "CertificateTemplateType",
    TestRankingType = "TestRankingType", 
    BrainFlexRoleType = "BrainFlexRoleType",
    InstituteRoleType = "InstituteRoleType",
    UserStatusType = "UserStatusType",
    SubscriptionBusinessRuleType= "SubscriptionBusinessRuleType",
    OccupationType = "OccupationType",
    RelationType = "RelationType",
    GenderType = "GenderType",
    GradeType = "GradeType",
    StudentStatusType = "StudentStatusType",
    ActiveInactiveStatusTerm = "ActiveInactiveStatusTerm",
    GroupStatusType  = "GroupStatusType",
    GroupMemberStatusType="GroupMemberStatusType",
    SubscriptionStatusType  = "SubscriptionStatusType",
    TestStatusTerm="TestStatusTerm",
    TestSyllabusType= "TestSyllabusType",
    StudentReportTerm ="StudentReportTerm"
}