// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	// intercept request and add token
	constructor(
		private commonService: CommonService,
		private storageService: StorageService) {

	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });  
		let accessToken = '';
		let tempToken = '';

		let studentRole = this.storageService.getStudentUserRoles();
		let instituteRole = this.storageService.getUserRoles();

		if (!this.commonService.isNullOrEmpty(studentRole)) {

			accessToken = this.storageService.getStudentToken();
		}

		if (!this.commonService.isNullOrEmpty(instituteRole)) {

			accessToken = this.commonService.getAccessToken();
		}

		tempToken = this.commonService.getTempToken();


		let resType = "";

		let headers = {};
		if (
			request.url.match("InsertTutorNew") ||
			request.url.match("UpdateTutorNew") ||
			request.url.match("InsertUserNew") ||
			request.url.match("UpdateUserNew") ||
			request.url.match("UpdateProfilePhoto") ||
			request.url.match("InsertStudentNew") ||
			request.url.match("UpdateStudentNew") ||
			request.url.match("UploadStudent") ||		
			request.url.match("ImportTestPaper") ||
			request.url.match("UploadOmrFile") ||
			request.url.match("UploadAttachments") ||
			request.url.match("UploadOmrResponse") ||
			request.url.match("UploadTestMarks") ||
			request.url.match("SaveDocument")
		) {
			headers = {
				Authorization: `Bearer ${accessToken}`,
				'Accept': 'application/json',
			};
		} 
		else if (request.url.match("ExportTestQuestionPaper") || request.url.match("ExportTestResultStudentList")){
			headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			resType = "blob";
		}
		else {
			if (this.commonService.isNullOrEmpty(tempToken) == false) {
				headers = {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'TempToken': `${tempToken}`
				};
			}
			else {
				headers = {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				};
			}

			
		}

		if(resType == "blob")
		{
			return next.handle(request.clone({
			withCredentials: true,
			//headers: httpHeaderData			
			setHeaders: headers,
			responseType: 'blob'
		})).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					let self = this;
					let errorMessage: any;
					if (error instanceof HttpErrorResponse) {
						if (error.status !== 0) {
							let errorStatus = error.status;
							try {
								if (errorStatus == 401) {

									const existAccessToken = self.commonService.getAccessToken();
									// if (existAccessToken) {
									// 	setTimeout(function () {
									// 		let isInstituteURL = self.commonService.isInstituteURL();
									// 		if (isInstituteURL == true) {
									// 			self.commonService.redirectToLoginPage(true);
									// 		}
									// 		else {
									// 			self.commonService.redirectToStudentLoginPage(true);
									// 		}
									// 	}, 3000);
									// 	return throwError(errorMessage = { field: 'custom', message: 'Your session is expired. Please try to login again' });
									// }
									let isInstituteURL = self.commonService.isInstituteURL();
									setTimeout(function () {
										if (isInstituteURL == true) {
											self.commonService.redirectToLoginPage(true);
										}
									}, 3000);
									return throwError(errorMessage = { field: 'custom', message: 'Your session is expired. Please try to login again' });

								} if (errorStatus == 409) {
									return throwError(errorMessage = { code: errorStatus, field: 'custom', message: error.error });
								}
								else {
									const response = error.error || '';
									if (typeof response == 'object' && response != null) {
										///errorMessage = { field: 'custom', message: response.message };

										let firstPropertyName = Object.keys(response)[0];
										let errorMsg = response[firstPropertyName][0];

										if (errorMsg) {
											errorMessage = { field: 'custom', message: errorMsg };
										} else {
											errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
										}

									} else {
										//errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
										errorMessage = { field: 'custom', message: response };
									}
								}
							} catch (exception) {
								errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
							}
						} else {
							errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
						}

						return throwError(errorMessage);

					} else {
						errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
						return throwError(errorMessage);
					}
				}
			)
		);
		}
		else{
		return next.handle(request.clone({
			withCredentials: true,
			//headers: httpHeaderData			
			setHeaders: headers
		})).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					let self = this;
					let errorMessage: any;
					if (error instanceof HttpErrorResponse) {
						if (error.status !== 0) {
							let errorStatus = error.status;
							try {
								if (errorStatus == 401) {

									const existAccessToken = self.commonService.getAccessToken();
									// if (existAccessToken) {
									// 	setTimeout(function () {
									// 		let isInstituteURL = self.commonService.isInstituteURL();
									// 		if (isInstituteURL == true) {
									// 			self.commonService.redirectToLoginPage(true);
									// 		}
									// 		else {
									// 			self.commonService.redirectToStudentLoginPage(true);
									// 		}
									// 	}, 3000);
									// 	return throwError(errorMessage = { field: 'custom', message: 'Your session is expired. Please try to login again' });
									// }
									let isInstituteURL = self.commonService.isInstituteURL();
									setTimeout(function () {
										if (isInstituteURL == true) {
											self.commonService.redirectToLoginPage(true);
										}
									}, 3000);
									return throwError(errorMessage = { field: 'custom', message: 'Your session is expired. Please try to login again' });

								} if (errorStatus == 409) {
									return throwError(errorMessage = { code: errorStatus, field: 'custom', message: error.error });
								}
								else {
									const response = error.error || '';
									if (typeof response == 'object' && response != null) {
										///errorMessage = { field: 'custom', message: response.message };

										let firstPropertyName = Object.keys(response)[0];
										let errorMsg = response[firstPropertyName][0];

										if (errorMsg) {
											errorMessage = { field: 'custom', message: errorMsg };
										} else {
											errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
										}

									} else {
										//errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
										errorMessage = { field: 'custom', message: response };
									}
								}
							} catch (exception) {
								errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
							}
						} else {
							errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
						}

						return throwError(errorMessage);

					} else {
						errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
						return throwError(errorMessage);
					}
				}
			)
		);
		}
	}
}
