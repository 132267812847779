import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/utilities/common.service';

@Pipe({
  name: 'mathJaxId'
})
export class MathJaxIdPipe implements PipeTransform {
  
  constructor(private commonService: CommonService) {    
  }

  transform(value: any, ...args: any[]): any {
    return `mathContent_${this.commonService.generateRandomString(8)}`
  }

}
