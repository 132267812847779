import { Component, OnInit, ChangeDetectorRef, NgZone, Directive } from '@angular/core';
import { ProgressBarModel } from '../../../../models/common/ProgressBarModel';
import { CommonService } from '../../../../services/utilities/common.service';
import { ProgressbarService } from './progressbar.service';

@Directive()
@Component({
  selector: 'kt-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {

  //dummyData: number[] = [1,2,3,4,5];
  data: ProgressBarModel[] = [];

  constructor(private commonService: CommonService, 
    private cdr: ChangeDetectorRef, 
    private ngZone: NgZone,
    private progressbarService: ProgressbarService) {
  }

  ngOnInit() {
    let self = this;
    self.subscribeToEvents();
  }

  subscribeToEvents() {
    let self = this;
		self.progressbarService.refreshProgressbarEvent.subscribe((data) => {
			this.ngZone.run(() => {
				if (data != null && data != undefined && data == true) {
          self.getData();
				}
			});
		});
  }

  getData() {
    let self = this;
    self.data = self.progressbarService.getData();
    self.cdr.detectChanges();
  }
}
