import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';
import { ApiVersion } from '../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private http: HttpClient) { }

  deleteGroup(groupID: number, instituteID: number) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/DeleteGroup?groupID=${groupID}&instituteID=${instituteID}`, null);
  }

  checkExistParentGroup(refGroupID: number, instituteID: number) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/CheckExistParentGroup?refGroupID=${refGroupID}&instituteID=${instituteID}`, null);
  }

  getSearchGroupByCourse(searchText: string, courseID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/SearchGroupByCourse?searchText=${searchText}&courseID=${courseID}&instituteID=${instituteID}`);
  }

  getTopPerformingGroup(courseID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/GetTopPerformingGroup?courseID=${courseID}&instituteID=${instituteID}`);
  }


  getGroupView(groupID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/getGroupView?groupID=${groupID}&instituteID=${instituteID}`);
  }

  getGroupByIDNew(groupID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/GetGroupByIDNew?groupID=${groupID}&instituteID=${instituteID}`);
  }

  insertGroupNew(groupModel) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/InsertGroupNew`, groupModel);
  }

  updateGroupNew(groupVM) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/UpdateGroupNew`, groupVM);
  }

  UpdateGroupStudents(groupVM) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/UpdateGroupStudents`, groupVM);
  }

  getGroupPerformanceByMonth(groupPerformanceByMonthInput) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/GetGroupPerformanceByMonth`, groupPerformanceByMonthInput);
  }

  getGroupByCourseID(courseID: number, instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/GetGroupByCourseID?courseID=${courseID}&instituteID=${instituteID}`);
  }

  getGroupByInstituteID(instituteID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/GetGroupByInstituteID?instituteID=${instituteID}`);
  }

  getGroupByCourses(courseIDs: string, instituteID: number, tutorID: number) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Group/GetGroupByCourses?courseIDs=${courseIDs}&instituteID=${instituteID}&tutorID=${tutorID}`);
  }

  GetSourceOfQuestionList() {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Question/GetSourceOfQuestionList`);
  }

} 
