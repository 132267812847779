// Angular
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { REGEXP } from "../../shared/regexp";
import { environment } from "../../../environments/environment";

// Enums
import { DateFormat } from "../../enum/DateFormat";
import { DateSeprator } from "../../enum/DateSeprator";

// View Models
import { ApiResult } from "../../viewmodels/common/ApiResult";

// Models
import { ProgressBarModel } from "../../models/common/ProgressBarModel";
import { ToastrService } from "ngx-toastr";

declare var $: any;
import * as moment from "moment";
import { UserActivityVM } from "../../viewmodels/UserActivityVM";

@Injectable({
	providedIn: "root",
})
export class CommonService {
	public projectBars: ProgressBarModel[] = [];

	public optionName = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
	];
	public DEFAULT_PAGE_SIZE: number = 10;
	public DISAGGREGATION_TYPE_NONE: string = "None";
	public IMAGE_FILE_FORMAT: string = "jpeg|jpg|png|gif|bmp";
	public BANK_STATEMENT_FILE_FORMAT: string = "csv|xlsx|xls";
	public newInterval: any;

	public myDataSessionKey = "MyDataHistory";

	public InstituteKeyName: string = "Institute";

	public DATEPICKER_CONFIG: any = {
		dateInputFormat: DateFormat.YYYYMMDD,
		showWeekNumbers: false,
		containerClass: "theme-dark-blue",
		isAnimated: true,
	};

	public DATERANGEPICKER_CONFIG: any = {
		rangeInputFormat: DateFormat.YYYYMMDD,
		showWeekNumbers: false,
		containerClass: "theme-dark-blue",
		isAnimated: true,
	};

	constructor(
		//private _titlecasePipe: TitleCasePipe,
		private router: Router,
		private toastr: ToastrService,
		private _regexp: REGEXP,
		private http: HttpClient
	) { }

	isNullOrEmpty(item) {
		if (
			item == null ||
			item == "" ||
			item == undefined ||
			item == "null" ||
			item == "undefined"
		) {
			return true;
		} else {
			return false;
		}
	}

	isEmptyObject(obj) {
		if (obj) {
			if (Object.keys(obj).length === 0) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	isEmptyArray(array) {
		if (
			!array ||
			array == null ||
			array == undefined ||
			array.length == 0
		) {
			return true;
		} else {
			return false;
		}
	}

	isNumeric(item) {
		if (!this.isNullOrEmpty(item)) {
			return !isNaN(item);
		}
		return false;
	}

	isValidNumber(item) {
		if (!this.isNullOrEmpty(item)) {
			var reg = RegExp(this._regexp.NUMBER_REGEXP);
			return reg.test(item);
		}
		return false;
	}

	convertToNumber(value): number {
		if (!this.isNullOrEmpty(value)) {
			return Number(value);
		} else {
			return Number(0);
		}
	}

	parseString(value): string {
		if (!this.isNullOrEmpty(value) && value !== "null") {
			return value.toString();
		} else {
			return "";
		}
	}

	parseBoolean(value): boolean {
		if (
			!this.isNullOrEmpty(value) &&
			(value == 1 || value == "1" || value == "true")
		) {
			return true;
		} else {
			return false;
		}
	}

	startsWith(text, prefixText): boolean {
		if (!this.isNullOrEmpty(text)) {
			if (text.startsWith(prefixText)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	endsWith(text, postfixText): boolean {
		if (!this.isNullOrEmpty(text)) {
			if (text.endsWith(postfixText)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	onlyNumberKey(event) {
		return event.charCode == 8 || event.charCode == 0
			? null
			: event.charCode >= 48 && event.charCode <= 57;
	}

	onlyNumeric(event) {
		//46    -   Period(.)
		if (
			(event.which != 46 || event.key.indexOf(".") != -1) &&
			(event.which < 48 || event.which > 57)
		) {
			//event.preventDefault();
			return true;
		}
	}

	numberOnly(event): boolean {
		//46    -   Period(.)
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			// && charCode != 46
			return false;
		}
		return true;
	}

	numberPercentageOnly(event): boolean {
		//46    -   Period(.)
		const charCode = event.which ? event.which : event.keyCode;
		if (
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			charCode != 46
		) {
			return false;
		} else {
			let value = event.value;
			//console.log('percentage:' + value);
			if (value < 0 || value > 100) {
				return false;
			}
		}
		return true;
	}

	isNumericKey(event) {
		let charCode = event.which ? event.which : event.keyCode;
		let value = event.key;
		let dotcontains = value.indexOf(".") != -1;
		let minuscontains = value.indexOf("-") != -1;
		if (dotcontains) {
			if (charCode == 46) return true;
			else return false;
		}

		if (minuscontains) {
			if (charCode == 45) return true;
			else return false;
		}

		if (charCode == 46) return true;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
		return true;
	}

	formatNumberWithDecimal(number, decimalPlaces) {
		if (number != null && number != "" && number != undefined) {
			return Number(number).toFixed(decimalPlaces);
		}
		return "0.00";
	}

	lower(item) {
		if (!this.isNullOrEmpty(item)) {
			return item.toLowerCase().trim();
		}
		return "";
	}

	upper(item) {
		if (!this.isNullOrEmpty(item)) {
			return item.toUpperCase().trim();
		}
		return "";
	}

	getDefaultOrValue(value?: number) {
		if (value === undefined || value === null || isNaN(value) === true) {
			return 0;
		}
		else {
			return value;
		}

	}

	// titleCase(item) {
	//   if (!this.isNullOrEmpty(item)) {
	//     return this._titlecasePipe.transform(item);
	//   }
	//   return "";
	// }

	trim(item) {
		if (!this.isNullOrEmpty(item)) {
			return item.trim();
		}
		return "";
	}

	showLoader() {
		$("#divLoader").show();
	}

	showStudentLoader() {
		$("#divStudentLoader").show();
	}

	hideStudentLoader() {
		$("#divStudentLoader").hide();
	}

	hideLoader() {
		$("#divLoader").hide();
	}

	scrollToTop() {
		window.scroll(0, 0);
	}

	/*** Date Utility Functions START ****/

	parseDate(date: string) {
		return moment(date).format("L");
	}

	parseDateToReadableFormat(
		inputDateString: string,
		dateFormat?: string,
		dateSeparator?: string
	): string {
		if (this.isNullOrEmpty(inputDateString)) {
			return null;
		}
		let onlyDateString = inputDateString.substring(0, 10);

		let d = new Date(onlyDateString);

		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return null;
		}

		let month, date, year;
		let MM, dd, yyyy;
		let fullDate;

		date = d.getDate();
		month = d.getMonth() + 1; // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
		year = d.getFullYear();

		//set initial value.
		yyyy = year;
		MM = month;
		dd = date;

		//Append '0' prefix in date.
		if (date >= 1 && date <= 9) {
			dd = "0" + date;
		}

		//Append '0' prefix in month.
		if (month >= 1 && month <= 9) {
			MM = "0" + month;
		}

		if (dateFormat == DateFormat.DDMMYYYY) {
			fullDate = dd + dateSeparator + MM + dateSeparator + yyyy;
		} else if (dateFormat == DateFormat.MMDDYYYY) {
			fullDate = MM + dateSeparator + dd + dateSeparator + yyyy;
		} else if (dateFormat == DateFormat.YYYYMMDD) {
			fullDate = yyyy + dateSeparator + MM + dateSeparator + dd;
		} else if (dateFormat == DateFormat.DDMMMYYYY) {
			let shortMonthName = this.getShortMonthName(month);
			fullDate =
				dd + dateSeparator + shortMonthName + dateSeparator + yyyy;
		}

		return fullDate;
	}

	parseDateString(
		inputDateString: string,
		dateFormat?: string,
		dateSeparator?: string
	): string {
		if (this.isNullOrEmpty(inputDateString)) {
			return null;
		}
		let onlyDateString = inputDateString.substring(0, 10);

		return this.getDateString(
			new Date(onlyDateString),
			dateFormat,
			dateSeparator
		);
	}

	getFullMonthName(monthNo): string {
		let monthName = "";
		switch (monthNo) {
			case 1:
				monthName = "January";
				break;
			case 2:
				monthName = "February";
				break;
			case 3:
				monthName = "March";
				break;
			case 4:
				monthName = "April";
				break;
			case 5:
				monthName = "May";
				break;
			case 6:
				monthName = "June";
				break;
			case 7:
				monthName = "July";
				break;
			case 8:
				monthName = "August";
				break;
			case 9:
				monthName = "September";
				break;
			case 10:
				monthName = "October";
				break;
			case 11:
				monthName = "November";
				break;
			case 12:
				monthName = "December";
				break;
			default:
				monthName = "";
				break;
		}
		return monthName;
	}

	getBrainflexTitle() {
		return "BrainFlex 360";
	}

	getShortMonthName(monthNo): string {
		let monthName = "";
		switch (monthNo) {
			case 1:
				monthName = "Jan";
				break;
			case 2:
				monthName = "Feb";
				break;
			case 3:
				monthName = "Mar";
				break;
			case 4:
				monthName = "Apr";
				break;
			case 5:
				monthName = "May";
				break;
			case 6:
				monthName = "Jun";
				break;
			case 7:
				monthName = "Jul";
				break;
			case 8:
				monthName = "Aug";
				break;
			case 9:
				monthName = "Sep";
				break;
			case 10:
				monthName = "Oct";
				break;
			case 11:
				monthName = "Nov";
				break;
			case 12:
				monthName = "Dec";
				break;
			default:
				monthName = "";
				break;
		}
		return monthName;
	}

	getMonthNumber(monthName): number {
		let monthNo: number;
		switch (monthName) {
			case "Jan":
			case "January":
				monthNo = 0;
				break;
			case "Feb":
			case "February":
				monthNo = 1;
				break;
			case "Mar":
			case "March":
				monthNo = 2;
				break;
			case "Apr":
			case "April":
				monthNo = 3;
				break;
			case "May":
				monthNo = 4;
				break;
			case "Jun":
			case "June":
				monthNo = 5;
				break;
			case "Jul":
			case "July":
				monthNo = 6;
				break;
			case "Aug":
			case "August":
				monthNo = 7;
				break;
			case "Sep":
			case "September":
				monthNo = 8;
				break;
			case "Oct":
			case "October":
				monthNo = 9;
				break;
			case "Nov":
			case "November":
				monthNo = 10;
				break;
			case "Dec":
			case "December":
				monthNo = 11;
				break;
			default:
				monthNo = 0;
				break;
		}
		return monthNo;
	}

	getDayOfWeek(day): string {
		let weekDay = "";
		switch (day) {
			case 0:
				weekDay = "Sunday";
				break;
			case 1:
				weekDay = "Monday";
				break;
			case 2:
				weekDay = "Tuesday";
				break;
			case 3:
				weekDay = "Wednesday";
				break;
			case 4:
				weekDay = "Thursday";
				break;
			case 5:
				weekDay = "Friday";
				break;
			case 6:
				weekDay = "Saturday";
				break;
			default:
				weekDay = "";
				break;
		}

		return weekDay;
	}

	getDateString(
		objDate?: Date,
		dateFormat?: string,
		dateSeparator?: string
	): string {
		let d = objDate;
		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return null;
		}

		let month, date, year;
		let MM, dd, yyyy;
		let fullDate;

		date = d.getDate();
		month = d.getMonth() + 1; // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
		year = d.getFullYear();

		//set initial value.
		yyyy = year;
		MM = month;
		dd = date;

		//Append '0' prefix in date.
		if (date >= 1 && date <= 9) {
			dd = "0" + date;
		}

		//Append '0' prefix in month.
		if (month >= 1 && month <= 9) {
			MM = "0" + month;
		}

		if (this.isNullOrEmpty(dateSeparator)) {
			dateSeparator = DateSeprator.SLASH;
		}

		if (dateFormat == DateFormat.DDMMYYYY) {
			fullDate = dd + dateSeparator + MM + dateSeparator + yyyy;
		} else if (dateFormat == DateFormat.MMDDYYYY) {
			fullDate = MM + dateSeparator + dd + dateSeparator + yyyy;
		} else if (dateFormat == DateFormat.YYYYMMDD) {
			fullDate = yyyy + dateSeparator + MM + dateSeparator + dd;
		}
		return fullDate;
	}

	getDateTimeString(
		objDate?: Date,
		dateFormat?: string,
		dateSeparator?: string
	): string {
		let d = objDate;
		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return null;
		}

		let month, date, year;
		let MM, dd, yyyy;
		let fullDateTime;
		let hours = 0,
			minutes = 0,
			seconds = 0;

		date = d.getDate();
		month = d.getMonth() + 1; // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
		year = d.getFullYear();

		//set initial value.
		yyyy = year;
		MM = month;
		dd = date;

		hours = d.getHours();
		minutes = d.getMinutes();
		seconds = d.getSeconds();

		if (hours === 0 && minutes === 0 && seconds === 0) {
			//Get Current Time
			var today = new Date();
			hours = today.getHours();
			minutes = today.getMinutes();
			seconds = today.getSeconds();
		}

		//Append '0' prefix in date.
		if (date >= 1 && date <= 9) {
			dd = "0" + date;
		}

		//Append '0' prefix in month.
		if (month >= 1 && month <= 9) {
			MM = "0" + month;
		}

		if (this.isNullOrEmpty(dateSeparator)) {
			dateSeparator = DateSeprator.SLASH;
		}

		if (dateFormat == DateFormat.DDMMYYYY) {
			fullDateTime =
				dd +
				dateSeparator +
				MM +
				dateSeparator +
				yyyy +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds;
		} else if (dateFormat == DateFormat.MMDDYYYY) {
			fullDateTime =
				MM +
				dateSeparator +
				dd +
				dateSeparator +
				yyyy +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds;
		} else if (dateFormat == DateFormat.YYYYMMDD) {
			fullDateTime =
				yyyy +
				dateSeparator +
				MM +
				dateSeparator +
				dd +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds;
		}
		return fullDateTime;
	}

	// ss comment time format defualt passing issue 
	getDateTimeStringFromSelecteDate(
		objDate?: Date,
		dateFormat?: string,
		dateSeparator?: string
	): string {
		let d = objDate;
		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return null;
		}

		let month, date, year;
		let MM, dd, yyyy;
		let fullDateTime;
		let hours = 0,
			minutes = 0,
			seconds = 0;

		date = d.getDate();
		month = d.getMonth() + 1; // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
		year = d.getFullYear();

		//set initial value.
		yyyy = year;
		MM = month;
		dd = date;

		hours = d.getHours();
		minutes = d.getMinutes();
		seconds = d.getSeconds();


		//Append '0' prefix in date.
		if (date >= 1 && date <= 9) {
			dd = "0" + date;
		}

		//Append '0' prefix in month.
		if (month >= 1 && month <= 9) {
			MM = "0" + month;
		}

		if (this.isNullOrEmpty(dateSeparator)) {
			dateSeparator = DateSeprator.SLASH;
		}

		if (dateFormat == DateFormat.DDMMYYYY) {
			fullDateTime =
				dd +
				dateSeparator +
				MM +
				dateSeparator +
				yyyy +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds;
		} else if (dateFormat == DateFormat.MMDDYYYY) {
			fullDateTime =
				MM +
				dateSeparator +
				dd +
				dateSeparator +
				yyyy +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds;
		} else if (dateFormat == DateFormat.YYYYMMDD) {
			fullDateTime =
				yyyy +
				dateSeparator +
				MM +
				dateSeparator +
				dd +
				" " +
				hours +
				":" +
				minutes +
				":" +
				seconds;
		}
		return fullDateTime;
	}

	mergeDateTimeString(dateString: string, time: string): string {
		if (this.isNullOrEmpty(dateString)) {
			return null;
		}

		let fullDateTime;

		fullDateTime = this.trim(dateString + " " + time);

		return fullDateTime;
	}

	parseDateTimeString(
		objDate?: Date,
		dateFormat?: string,
		dateSeparator?: string
	): string {
		let d = objDate;
		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return null;
		}

		let month, date, year;
		let MM, dd, yyyy;
		let fullDateTime;

		date = d.getDate();
		month = d.getMonth() + 1; // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
		year = d.getFullYear();

		//set initial value.
		yyyy = year;
		MM = month;
		dd = date;

		//Append '0' prefix in date.
		if (date >= 1 && date <= 9) {
			dd = "0" + date;
		}

		//Append '0' prefix in month.
		if (month >= 1 && month <= 9) {
			MM = "0" + month;
		}

		if (this.isNullOrEmpty(dateSeparator)) {
			dateSeparator = DateSeprator.SLASH;
		}

		if (dateFormat == DateFormat.DDMMYYYY) {
			fullDateTime = `${dd}${dateSeparator}${MM}${dateSeparator}${yyyy} ${this.formatTime(
				objDate
			)}`;
			//fullDateTime = dd + dateSeparator + MM + dateSeparator + yyyy + " " + this.formatTime(objDate);
		} else if (dateFormat == DateFormat.MMDDYYYY) {
			fullDateTime = `${MM}${dateSeparator}${dd}${dateSeparator}${yyyy} ${this.formatTime(
				objDate
			)}`;
			//fullDateTime = MM + dateSeparator + dd + dateSeparator + yyyy + " " + this.formatTime(objDate);
		} else if (dateFormat == DateFormat.YYYYMMDD) {
			fullDateTime = `${yyyy}${dateSeparator}${MM}${dateSeparator}${dd} ${this.formatTime(
				objDate
			)}`;
			//fullDateTime = yyyy + dateSeparator + MM + dateSeparator + dd + " " + this.formatTime(objDate);
		}
		return fullDateTime;
	}

	formatTime(objDate?: Date): string {
		let d = objDate;
		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return "";
		}

		let am_pm = "";

		let hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
		am_pm = d.getHours() >= 12 ? "PM" : "AM";
		let sHours = hours < 10 ? "0" + hours : hours;
		let sMinutes =
			d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
		let sSeconds =
			d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

		let time = sHours + ":" + sMinutes + ":" + sSeconds + " " + am_pm;

		return time;
	}

	getUTCTime = function () {
		const dt = new Date();
		return new Date(
			dt.getUTCFullYear(),
			dt.getUTCMonth(),
			dt.getUTCDate(),
			dt.getUTCHours(),
			dt.getUTCMinutes(),
			dt.getUTCSeconds()
		).getTime();
	}

	getLocalTime = function () {
		const dt = new Date();
		return dt.getTime();
	}

	getDateObject(inputDateString, dateFormat, dateSeparator): Date {
		if (this.isNullOrEmpty(inputDateString)) {
			return null;
		}

		let dateParts = inputDateString.split(dateSeparator);

		let MM, dd, yyyy;

		if (dateFormat == DateFormat.DDMMYYYY) {
			dd = dateParts[0];
			MM = dateParts[1];
			yyyy = dateParts[2];
		} else if (dateFormat == DateFormat.MMDDYYYY) {
			MM = dateParts[0];
			dd = dateParts[1];
			yyyy = dateParts[2];
		} else if (dateFormat == DateFormat.YYYYMMDD) {
			yyyy = dateParts[0];
			MM = dateParts[1];
			dd = dateParts[2];
		}
		let dObj = new Date(Number(yyyy), Number(MM) - 1, Number(dd));
		return dObj;
	}

	convertDDMMYYYY_To_MMDDYYYY(inputDate, dateSeparator) {
		if (this.isNullOrEmpty(inputDate)) {
			return "";
		}
		if (this.isNullOrEmpty(dateSeparator)) {
			dateSeparator = DateSeprator.SLASH;
		}

		let dateParts = inputDate.split(dateSeparator);

		let MM, dd, yyyy;

		dd = dateParts[0];
		MM = dateParts[1];
		yyyy = dateParts[2];

		return this.trim(MM + dateSeparator + dd + dateSeparator + yyyy);
	}

	convertMMDDYYYY_To_YYYYMMDD(inputDate, dateSeparator) {
		if (this.isNullOrEmpty(inputDate)) {
			return "";
		}
		if (this.isNullOrEmpty(dateSeparator)) {
			dateSeparator = DateSeprator.SLASH;
		}

		let dateParts = inputDate.split(dateSeparator);

		let MM, dd, yyyy;

		dd = dateParts[1];
		MM = dateParts[0];
		yyyy = dateParts[2];

		return this.trim(yyyy + dateSeparator + MM + dateSeparator + dd);
	}

	parseJsonDate(date, dateSeparator?: string): string {
		if (!this.isNullOrEmpty(date)) {
			let dt = new Date(parseInt(date.substr(6)));

			return this.getDateString(dt, DateFormat.DDMMYYYY, dateSeparator);
		}
		return null;
	}

	parseJsonDateTime(date) {
		if (!this.isNullOrEmpty(date)) {
			let dt = new Date(parseInt(date.substr(6)));

			return moment(dt).format("mm/dd/yyyy HH:MM:ss");
		}
		return "";
	}

	getTime(dateObject) {
		if (this.isNullOrEmpty(dateObject)) {
			dateObject = new Date();
		}

		let datetext = dateObject.toTimeString();

		datetext = datetext.split(" ")[0];

		return datetext;
	}

	getDateObjectFromTime(time) {
		let today = new Date();
		let year = today.getFullYear();
		let month = today.getMonth();
		let day = today.getDate();

		let hour = 0,
			minute = 0;

		if (!this.isNullOrEmpty(time)) {
			let timeArr = time.split(":");

			hour = parseInt(timeArr[0]);
			minute = parseInt(timeArr[1]);
		}
		let dtObj = new Date(year, month, day, hour, minute, 0, 0);

		return dtObj;
	}

	getHours(d) {
		let h = parseInt(d.split(":")[0]);
		if (d.split(":")[1].split(" ")[1] == "PM") {
			h = h + 12;
		}
		return h;
	}
	getMinutes(d) {
		return parseInt(d.split(":")[1].split(" ")[0]);
	}

	getHour_Minute_AM_Or_PM(objDate?: Date): string {
		let d = objDate;
		if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
			return "";
		}

		let am_pm = "";

		let hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
		am_pm = d.getHours() >= 12 ? "PM" : "AM";
		let sHours = hours < 10 ? "0" + hours : hours;
		let sMinutes =
			d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

		let time = sHours + ":" + sMinutes + " " + am_pm;

		return time;
	}

	convertTo24Hour(time: any): string {
		//let hours = time.substr(0, 2);
		let hours = time.split(":")[0];
		hours = hours.toString().length == 1 ? "0" + hours.toString() : hours;

		let minutes = time.split(":")[1].split(" ")[0];
		minutes =
			minutes.toString().length == 1 ? "0" + minutes.toString() : minutes;

		let am_pm = time.split(":")[1].split(" ")[1];

		let strTime = `${hours}:${minutes} ${am_pm}` as any;

		if (strTime.indexOf("AM") != -1 && +hours == 12) {
			strTime = strTime.replace("12", "00");
		}

		if (strTime.indexOf("PM") != -1 && +hours < 12) {
			strTime = strTime.replace(hours, +hours + 12);
		}

		return this.trim(strTime.replace(/(AM|PM)/, ""));
	}

	combineDateAndTimeString(dateString, timeString): Date {
		//console.log(moment(dateString + " " + timeString).format()); // Return format
		//console.log(moment(dateString + " " + timeString)); // Return object of moment

		return moment(dateString + " " + timeString).toDate();

		//return moment(dt).format("YYYY/MM/DD  HH:mm:ss");
	}

	/*** Date Utility Functions END ****/

	dropdownArray(array: any, idColumnName, valueColumnName) {
		let resultArray = [];
		if (array != null && array != undefined && array.length > 0) {
			array.forEach((item, index) => {
				//console.log(index);
				let id = item[idColumnName];
				let name = item[valueColumnName];

				let result = {
					id: id,
					text: name,
				};
				resultArray.push(result);
			});
		}
		return resultArray;
	}

	removePrefixChar(text, prefixChar) {
		if (text.startsWith(prefixChar)) {
			return text.slice(1);
		}
		return text;
	}

	removePostfixChar(text, postfixChar) {
		if (text.endsWith(postfixChar)) {
			return text.slice(0, -1);
		}
		return text;
	}

	redirectToUnauthorizePage() {
		this.router.navigate(["/institute/unauthorize"]);
	}

	removeArrayItemByValue(arr, item) {
		if (arr != null && arr.length > 0) {
			const index: number = arr.indexOf(item);
			if (index !== -1) {
				arr.splice(index, 1);
			}
		}
		return arr;
	}

	removeArrayItemByIndex(arr, index) {
		if (arr != null && arr.length > 0) {
			if (index !== -1) {
				arr.splice(index, 1);
			}
		}
		return arr;
	}

	getMonths() {
		let arr = [];
		arr.push({ id: "1", monthName: "January", shortMonthName: "Jan" });
		arr.push({ id: "2", monthName: "February", shortMonthName: "Feb" });
		arr.push({ id: "3", monthName: "March", shortMonthName: "Mar" });
		arr.push({ id: "4", monthName: "April", shortMonthName: "Apr" });
		arr.push({ id: "5", monthName: "May", shortMonthName: "May" });
		arr.push({ id: "6", monthName: "June", shortMonthName: "Jun" });
		arr.push({ id: "7", monthName: "July", shortMonthName: "Jul" });
		arr.push({ id: "8", monthName: "August", shortMonthName: "Aug" });
		arr.push({ id: "9", monthName: "September", shortMonthName: "Sep" });
		arr.push({ id: "10", monthName: "October", shortMonthName: "Oct" });
		arr.push({ id: "11", monthName: "November", shortMonthName: "Nov" });
		arr.push({ id: "12", monthName: "December", shortMonthName: "Dec" });
		return arr;
	}

	getYears(pastYearUpto: number, upcomingYearUpto: number) {
		let currentYear = new Date().getFullYear();

		let arr = [];

		let startYear = currentYear - pastYearUpto;

		//Past  Year
		for (let i = startYear; i < currentYear; i++) {
			arr.push(i);
		}

		//Upcoming Year
		for (let i = currentYear; i <= currentYear + upcomingYearUpto; i++) {
			arr.push(i);
		}

		return arr;
	}

	getAccessToken() {
		return localStorage.getItem("access_token");
	}

	getTempToken() {
		return sessionStorage.getItem("temp_token");
	}

	setTempToken(token) {
		return sessionStorage.setItem("temp_token", token);
	}

	setSessionData(key, value: any) {
		if (value == null) {
			return sessionStorage.setItem(key, null);
		}
		else {
			var data = JSON.stringify(value);
			return sessionStorage.setItem(key, data);
		}

	}

	removeSessionData(key) {
		sessionStorage.removeItem(key);
	}

	getSessionData(key) {
		var result = sessionStorage.getItem(key);
		if (result != null) {
			return JSON.parse(result);
		}
		else {
			return null;
		}
	}

	redirectToLoginPage(isSessionTimeout: boolean = false) {
		localStorage.clear(); //Clear session

		if (isSessionTimeout == false) {
			this.router.navigate(["/institute/auth/login"]);
		} else {
			this.router.navigate(["/institute/auth/login"], {
				queryParams: { isSessionTimeout: "1" },
			});
		}
	}



	getBroserDetail() {
		var objappVersion = navigator.appVersion;
		var objAgent = navigator.userAgent;
		var objbrowserName = navigator.appName;
		var objfullVersion = "" + parseFloat(navigator.appVersion);
		var objOffsetName, objOffsetVersion, ix;
		// In Chrome
		if ((objOffsetVersion = objAgent.indexOf("Chrome")) != -1) {
			objbrowserName = "Chrome";
			objfullVersion = objAgent.substring(objOffsetVersion + 7);
		}
		// In Microsoft internet explorer
		else if ((objOffsetVersion = objAgent.indexOf("MSIE")) != -1) {
			objbrowserName = "Microsoft Internet Explorer";
			objfullVersion = objAgent.substring(objOffsetVersion + 5);
		}
		// In Firefox
		else if ((objOffsetVersion = objAgent.indexOf("Firefox")) != -1) {
			objbrowserName = "Firefox";
		}
		// In Safari
		else if ((objOffsetVersion = objAgent.indexOf("Safari")) != -1) {
			objbrowserName = "Safari";
			objfullVersion = objAgent.substring(objOffsetVersion + 7);
			if ((objOffsetVersion = objAgent.indexOf("Version")) != -1)
				objfullVersion = objAgent.substring(objOffsetVersion + 8);
		}
		// For other browser "name/version" is at the end of userAgent
		else if (
			(objOffsetName = objAgent.lastIndexOf(" ") + 1) <
			(objOffsetVersion = objAgent.lastIndexOf("/"))
		) {
			objbrowserName = objAgent.substring(
				objOffsetName,
				objOffsetVersion
			);
			objfullVersion = objAgent.substring(objOffsetVersion + 1);
			if (objbrowserName.toLowerCase() == objbrowserName.toUpperCase()) {
				objbrowserName = navigator.appName;
			}
		}
		// trimming the fullVersion string at semicolon/space if present
		if ((ix = objfullVersion.indexOf(";")) != -1)
			objfullVersion = objfullVersion.substring(0, ix);
		if ((ix = objfullVersion.indexOf(" ")) != -1)
			objfullVersion = objfullVersion.substring(0, ix);

		return "Browser = " + objbrowserName + ", Version = " + objfullVersion;
		//http://mrbool.com/how-to-detect-different-browsers-and-their-versions-using-javascript/25424
	}

	getOperatingSystem() {
		let userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
			windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
			iosPlatforms = ["iPhone", "iPad", "iPod"],
			os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = "Mac OS";
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = "iOS";
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = "Windows";
		} else if (/Android/.test(userAgent)) {
			os = "Android";
		} else if (!os && /Linux/.test(platform)) {
			os = "Linux";
		}

		return os;
	}

	showToaster(message: string, toastrType: 'success' | 'error' | 'warning' | 'info') {
		switch (toastrType) {
			case "success":
				setTimeout(() =>
					this.toastr.success(message, "Success!", {
						enableHtml: true,
						tapToDismiss: true,
						timeOut: 3000,
					})
				);
				break;
			case "error":
				setTimeout(() =>
					this.toastr.error(message, "Error!", {
						enableHtml: true,
						tapToDismiss: true,
						timeOut: 8000,
					})
				);
				break;
			case "warning":
				setTimeout(() =>
					this.toastr.warning(message, "Warning!", {
						enableHtml: true,
						tapToDismiss: true,
						timeOut: 3000,
					})
				);
				break;
			case "info":
				setTimeout(() =>
					this.toastr.info(message, "Info!", {
						enableHtml: true,
						tapToDismiss: true,
						timeOut: 3000,
					})
				);
				break;
			default:
				console.warn("Invalid toastrType provided:", toastrType);
				break;
		}
	}

	objectToFormData(obj) {
		const formData = new FormData();
		this.appendFormData(formData, obj);
		return formData;
	}

	appendFormData(formData, data, root = null) {
		root = root || "";
		if (data instanceof File) {
			formData.append(root, data);
		} else if (Array.isArray(data)) {
			for (var i = 0; i < data.length; i++) {
				if (data[i] instanceof File) {
					this.appendFormData(formData, data[i], root);
				} else {
					this.appendFormData(
						formData,
						data[i],
						root + "[" + i + "]"
					);
				}
			}
		} else if (typeof data === "object" && data) {
			for (var key in data) {
				if (data.hasOwnProperty(key)) {
					let value = data[key];

					if (root === "") {
						this.appendFormData(formData, value, key);
					} else {
						this.appendFormData(formData, value, root + "." + key);
					}
				}
			}
		} else {
			if (data !== null && typeof data !== "undefined") {
				formData.append(root, data);
			}
		}
	}

	generateRandomString(length) {
		let result = "";
		let characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	setProgressBarModel(progressBarModel: ProgressBarModel) {
		let self = this;
		self.projectBars.push(progressBarModel);
	}

	removeProgressBarModel(progressBarID: string, progressType: string) {
		let self = this;
		self.projectBars = self.projectBars.filter(
			(c) =>
				c.progressBarID != progressBarID &&
				c.progressBarType == progressType
		);
	}

	minConvertIntoHour(minute: number) {
		var hours = Math.floor(minute / 60);
		var minutes = minute % 60;

		if (hours > 0) {
			return (
				(hours.toString().length == 1
					? "0" + hours.toString()
					: hours) +
				":" +
				(minutes.toString().length == 1
					? "0" + minutes.toString()
					: minutes) +
				":00 Hrs"
			);
		} else {
			return "00:" + minutes + ":00 Mins";
		}
	}

	removeLastChar(input: string, char: string) {
		if (!this.isNullOrEmpty(input) && !this.isNullOrEmpty(char)) {
			let lastChar = input.slice(-1);
			if (lastChar == char) {
				input = input.slice(0, -1);
			}
			return input;
		}
		return "";
	}

	isArraySequenceEqual(arr1, arr2) {
		let self = this;
		let isEqual = false;

		if (!self.isEmptyArray(arr1) && !self.isEmptyArray(arr2)) {
			if (arr1.toString() == arr2.toString()) {
				isEqual = true;
			}
		}

		return isEqual;
	}

	isStudentURL(): boolean {
		let self = this;
		const host = window.location.host;
		const url = window.location.href;

		let isStudentPrefixExist = false;

		if (!self.isNullOrEmpty(host) && !self.isNullOrEmpty(url)) {
			let urlPrefix = url.split(host)[1].slice(1).split("/")[0];

			if (self.lower(urlPrefix) == "student") {
				isStudentPrefixExist = true;
			}
		}

		return isStudentPrefixExist;
	}

	isInstituteURL(): boolean {
		let self = this;
		const host = window.location.host;
		const url = window.location.href;

		let isInstitutePrefixExist = false;

		if (!self.isNullOrEmpty(host) && !self.isNullOrEmpty(url)) {
			let urlPrefix = url.split(host)[1].slice(1).split("/")[0];

			if (self.lower(urlPrefix) == "institute") {
				isInstitutePrefixExist = true;
			}
		}

		return isInstitutePrefixExist;
	}

	getScreenWidth(): number {
		return window.screen.width || 1366;
	}

	getScreenHeight(): number {
		return window.screen.height || 768;
	}

	generateNewGUID() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}
		return (
			s4() +
			s4() +
			"-" +
			s4() +
			"-" +
			s4() +
			"-" +
			s4() +
			"-" +
			s4() +
			s4() +
			s4()
		);
	}
	// Timer by YD
	showTimer(duration: any, message: any) {
		let timer: any = duration, minutes: any, seconds: any; clearInterval(this.newInterval); if (message == "" || message == null || message == undefined) {
			this.newInterval = setInterval(function () {
				minutes = Math.floor(timer / 60); seconds = Math.floor(timer % 60);
				minutes = minutes < 10 ? "0" + minutes : minutes; seconds = seconds < 10 ? "0" + seconds : seconds; document.querySelector('#timeLoader').textContent = minutes + ":" + seconds;
				if (--timer < 0) { document.querySelector('#timeLoader').textContent = ""; }
			}, 1000);
		} else {
			this.newInterval = setInterval(function () {
				minutes = Math.floor(timer / 60); seconds = Math.floor(timer % 60);
				minutes = minutes < 10 ? "0" + minutes : minutes; seconds = seconds < 10 ? "0" + seconds : seconds;
				document.querySelector('#timeLoader').textContent = message
				if (--timer < 0) { document.querySelector('#timeLoader').textContent = ""; }
			}, 1000);
		}
	}

	keyGenerate4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	mathElements = [
		'math',
		'maction',
		'maligngroup',
		'malignmark',
		'menclose',
		'merror',
		'mfenced',
		'mfrac',
		'mglyph',
		'mi',
		'mlabeledtr',
		'mlongdiv',
		'mmultiscripts',
		'mn',
		'mo',
		'mover',
		'mpadded',
		'mphantom',
		'mroot',
		'mrow',
		'ms',
		'mscarries',
		'mscarry',
		'msgroup',
		'msline',
		'mspace',
		'msqrt',
		'msrow',
		'mstack',
		'mstyle',
		'msub',
		'msup',
		'msubsup',
		'mtable',
		'mtd',
		'mtext',
		'mtr',
		'munder',
		'munderover',
		'semantics',
		'annotation',
		'annotation-xml'
	];

	initializeCKEditor(CKEDITOR) {
		CKEDITOR.config.toolbar_Basic = [
			{ name: 'clipboard', items: ['Cut', 'Copy', 'PasteFromWord', '-', 'Undo', 'Redo'] },
			{ name: 'editing', items: ['Find', 'Replace'] },
			{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
			{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
			{ name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
			{ name: 'colors', items: ['TextColor', 'BGColor', '-', 'ckeditor_wiris_formulaEditor', 'ckeditor_wiris_formulaEditorChemistry', 'texzilla'] },
		];

		CKEDITOR.config.toolbar = 'Basic';
		CKEDITOR.config.width = '100%';
		CKEDITOR.config.height = 250;
		/*CKEDITOR.config.extraPlugins += (CKEDITOR.config.extraPlugins.length === 0 ? '' : ',') + 'pastebase64';*/
		/*CKEDITOR.config.extraAllowedContent = this.mathElements.join(' ') + '(*)[*]{*};img[data-mathml,data-custom-editor,role](Wirisformula)';*/
		CKEDITOR.config.allowedContent = true;
		CKEDITOR.config.removePlugins = 'elementspath';
		CKEDITOR.config.resize_enabled = false;
	}


	timeSince(date) {
		var dt = moment(date).toDate().getTime() / 1000;
		var seconds = Math.floor(((new Date().getTime() / 1000) - dt));

		var interval = 0;
		interval = Math.floor(seconds / 86400);
		if (interval >= 1) return moment(date).format("DD MMM YYYY");

		interval = Math.floor(seconds / 3600);
		if (interval >= 1) return interval + " hrs ago";

		interval = Math.floor(seconds / 60);
		if (interval > 1) return interval + " min ago";

		return Math.floor(seconds) + " sec ago";
	}

	getPivotArray(dataArray, rowIndex, colIndex, dataIndex) {
		var result = {}, ret = [];
		var newCols = [];
		for (var i = 0; i < dataArray.length; i++) {

			if (!result[dataArray[i][rowIndex]]) {
				result[dataArray[i][rowIndex]] = {};
			}
			result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];

			//To get column names
			if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
				newCols.push(dataArray[i][colIndex]);
			}
		}

		newCols.sort();
		var item = [];

		//Add Header Row
		item.push('Item');
		item.push.apply(item, newCols);
		ret.push(item);

		//Add content 
		for (var key in result) {
			item = [];
			item.push(key);
			for (var i = 0; i < newCols.length; i++) {
				item.push(result[key][newCols[i]] || "-");
			}
			ret.push(item);
		}
		return ret;
	}

	copyMessage(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	//editorExtraPlugin = 'ckeditor_wiris, colorbutton, colordialog, pastebase64';
	//editorRemovePlugin = "scayt,wsc,uploadimage,uploadwidget,uploadfile,filetools,filebrowser,maximize";
	//editorRemoveButtons = "Paste,PasteText,PasteFromWord";
	//editorExtraAllowedContent = this.mathElements.join(' ') + '(*)[*]{*};img[data-mathml,data-custom-editor,role](Wirisformula)';
}
