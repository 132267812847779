
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';
import { of } from 'rxjs';
import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  constructor(
    private http: HttpClient) { }

  getLessonsForTestMaster(testMasterID: number, testTemplateID: number, courseID: number, subjectID: number, instituteID: number, instituteUserID: number, associationTypeTerm: string) {
	  return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetLessonsForTestMaster?testMasterID=${testMasterID}&testTemplateID=${testTemplateID}&courseID=${courseID}&subjectID=${subjectID}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&associationTypeTerm=${associationTypeTerm}&instituteUserID=${instituteUserID}`);
  }

  getChapterConceptInfoList(searchText: string, instituteID: number, tutorID: number, courseIDs: string, groupIDs: string, subjectIDs: string, pageNum: number, sortColumn: string, sortDirection: string) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetChapterConceptInfoList?searchText=${searchText}&instituteID=${instituteID}&tutorID=${tutorID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
	}

	searchLessonAndTopic(searchText: string, instituteID) {
		return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Lesson/SearchLessonAndTopic?term=${searchText}&instituteID=${instituteID}`);
	}

  getAllLessons(subjectID) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetAll?subjectID=${subjectID}`).pipe(
      catchError(err => {
        return of({ data: "Error in loading lessons", success: false, message: "" });
      }));
  }

  

}
