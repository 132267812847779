
//@Packages
import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router'

//@Services
import { EncryptDecryptService } from './encrypt-decrypt.service';

// Enums
import { CookieStorage } from '../../enum/CookieStorage';
import { RoleType } from '../../enum/RoleType';
import { PageCode } from '../../enum/PageCode';

// View Models
import { LoginResultVM } from '../../viewmodels/LoginResultVM';
import { UserLogInOut } from '../../models/UserLogInOut';
import { Configuration } from '../../models/Configuration';
import { RolePrivilege } from '../../models/RolePrivilege';
import { LoginVM } from '../../viewmodels/student/LoginVM';
import { FlippedQuestionVM } from '../../viewmodels/FlippedQuestionVM';
import { RemoveQuestionTestMasterVM } from '../../viewmodels/RemoveQuestionTestMasterVM';
import { CommonService } from './common.service';
import { RefreshTokenResultVM } from '../../viewmodels/student/RefreshTokenResultVM';
import { InstituteUserPermissionVM } from '../../viewmodels/InstituteUserPermissionVM';
import { Session } from 'protractor';


@Injectable({
	providedIn: 'root'
})
export class StorageService {

	refreshMenus = new EventEmitter<boolean>();
	changeMenuBaseOnURL = new EventEmitter<boolean>(); //student module subject view screen hide menu
	showHideHamburgerEvent = new EventEmitter<boolean>();
	adminModeEvent = new EventEmitter<boolean>();

	constructor(private _encryptDecryptService: EncryptDecryptService,
		private router: Router,
		private commonService: CommonService,
		private cookieService: CookieService) { }

	setCredentials(authResult: LoginResultVM): void {

		// Save session data and update login status subject
		const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + Date.now());

		localStorage.setItem('refresh_Token', this.encrypt(authResult.refresh_Token));
		localStorage.setItem('access_token', authResult.access_Token);
		localStorage.setItem('expires_at', this.encrypt(expiresAt));
		localStorage.setItem('googleAPIKey', this.encrypt(authResult.googleAPIKey));


		//set users data.
		localStorage.setItem("userid", this.encrypt((authResult.userID == null || authResult.userID == undefined) ? "" : authResult.userID));
		localStorage.setItem("logInLogId", this.encrypt((authResult.logInLogID == null || authResult.logInLogID == undefined) ? "" : authResult.logInLogID));
		localStorage.setItem("username", this.encrypt(authResult.userName));
		localStorage.setItem("displayname", this.encrypt(authResult.displayName));
		localStorage.setItem("roles", this.encrypt(authResult.roles));
		localStorage.setItem("profilePic", this.encrypt(authResult.profilePic));
		localStorage.setItem("firstName", this.encrypt(authResult.firstName));
		localStorage.setItem("lastName", this.encrypt(authResult.lastName));
		localStorage.setItem("lastLoginDate", this.encrypt(authResult.lastLoginDate));
		localStorage.setItem("instituteID", this.encrypt(authResult.instituteID));
		localStorage.setItem("instituteName", this.encrypt(authResult.instituteName));
		localStorage.setItem("instituteKey", this.encrypt(authResult.instituteKey));
		localStorage.setItem("userTypeTerm", this.encrypt(authResult.userTypeTerm));
		localStorage.setItem("associationTypeTerm", this.encrypt(authResult.associationTypeTerm));
		localStorage.setItem("tutorID", this.encrypt(authResult.tutorID));
		localStorage.setItem("isWhiteLabelApp", this.encrypt(authResult.isWhiteLabelApp));
		localStorage.setItem("instituteLogo", this.encrypt(authResult.instituteLogo));
		//localStorage.setItem("roleprivilege", this.encrypt(authResult.rolePrivileges));
		localStorage.setItem("roleprivilege", this.encrypt((authResult.userID == null || authResult.userID == undefined) ? "" : JSON.stringify(authResult.rolePrivileges)));

		sessionStorage.removeItem("dashboardHistory");

		//Set IsSuperAdmin
		if (authResult.roles != null && authResult.roles != undefined && authResult.roles != "") {
			if (this.hasRole(RoleType.Admin)) {
				authResult.isSuperAdmin = true;
			} else {
				authResult.isSuperAdmin = false;
			}
		}

		localStorage.setItem("issuperadmin", this.encrypt(authResult.isSuperAdmin));

		if (authResult.configurations != null && authResult.configurations != undefined && authResult.configurations.length > 0) {
			this.setConfigurations(authResult.configurations);
		}
	}



	clearCredentials(): void {
		localStorage.clear();
	}

	clearStudentCredentials(): void {
		let self = this;

		localStorage.removeItem('s_access_token');
		localStorage.removeItem('s_expires_at');
		localStorage.removeItem("s_userid");
		localStorage.removeItem("s_logInLogId");
		localStorage.removeItem("s_username");
		localStorage.removeItem("s_displayname");
		localStorage.removeItem("s_studentName");
		localStorage.removeItem("s_studentID");
		localStorage.removeItem("s_roles");
		localStorage.removeItem("s_profilePic");
		localStorage.removeItem("s_firstName");
		localStorage.removeItem("s_lastName");
		localStorage.removeItem("s_lastLoginDate");
		localStorage.removeItem("s_instituteID");
		localStorage.removeItem("s_roleprivilege");
		localStorage.removeItem("s_userTypeTerm");
		localStorage.removeItem("s_associationTypeTerm");
	}

	clearClientCredentials(): void {
		let self = this;

		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_Token');
		localStorage.removeItem('expires_at');
		localStorage.removeItem("userid");
		localStorage.removeItem("logInLogId");
		localStorage.removeItem("username");
		localStorage.removeItem("displayname");
		localStorage.removeItem("roles");
		localStorage.removeItem("profilePic");
		localStorage.removeItem("firstName");
		localStorage.removeItem("lastName");
		localStorage.removeItem("lastLoginDate");
		localStorage.removeItem("instituteID");
		localStorage.removeItem("instituteName");
		localStorage.removeItem("roleprivilege");
		localStorage.removeItem("issuperadmin");
		localStorage.removeItem("useremail");
		localStorage.removeItem("noOfAttemp");
		localStorage.removeItem("questionlistcolumnfilter");
		localStorage.removeItem("questionlistfilter");
		localStorage.removeItem("subject_isshowgridview");
		localStorage.removeItem("studentlistcolumnfilter");
		localStorage.removeItem("tutorlistcolumnfilter");
		localStorage.removeItem("disableTestTemplateDropdown");
		localStorage.removeItem("removedtestquestions");
		localStorage.removeItem("flippedtestquestions");
		localStorage.removeItem("userTypeTerm");
		localStorage.removeItem("associationTypeTerm");
		localStorage.removeItem("tutorID");

	}

	storeUserName(userEmail: string) {
		localStorage.setItem("useremail", this.encrypt(userEmail));
	}

	storeUserId(userId: string) {
		localStorage.setItem("userid", this.encrypt(userId));
	}

	setLogInLogID(logInLogId: string) {
		localStorage.setItem("logInLogId", this.encrypt(logInLogId));
	}

	setNoOfAttempLogin(noOfAttempLogin) {
		localStorage.setItem("noOfAttemp", this.encrypt(noOfAttempLogin));
	}

	storageUserInfo(userId: string, userEmail: string) {
		this.storeUserId(userId);
		this.storeUserName(userEmail);
		const expireInDays: number = 1;
		this.cookieService.set("useremail", userEmail, expireInDays);
	}


	encrypt(plainText: any) {
		if (plainText != null && plainText != undefined && plainText != "null") {
			return this._encryptDecryptService.encrypt(plainText);
		}
		return null;
	}

	decrypt(cipherText: any) {
		if (cipherText != null && cipherText != undefined && cipherText != "null") {
			return this._encryptDecryptService.decrypt(cipherText);
		}
		return null;
	}

	storeTimezoneOffset() {
		const cookieExists: boolean = this.cookieService.check('timezoneoffset');
		if (!cookieExists) {
			const expireInDays: number = 15; //expires in 15 days
			let timezoneOffset = new Date().getTimezoneOffset().toString();
			this.cookieService.set("timezoneoffset", timezoneOffset, expireInDays);
		}
	}


	getTimezoneOffset(): string {
		const cookieExists: boolean = this.cookieService.check('timezoneoffset');
		let timeZoneOffset = "";
		if (cookieExists) {
			timeZoneOffset = this.cookieService.get('timezoneoffset');
		}

		if (timeZoneOffset == null || timeZoneOffset == undefined || timeZoneOffset == "") {
			timeZoneOffset = new Date().getTimezoneOffset().toString();
		}
		return timeZoneOffset;
	}


	storeUserNameWithPassword(userCredential) {
		this.cleareCookieCredential();
		const expireInDays: number = 2;
		this.cookieService.set(CookieStorage.USERNAME, this.encrypt(userCredential.userName), expireInDays);
		this.cookieService.set(CookieStorage.USERPASSWORD, this.encrypt(userCredential.password), expireInDays);
		this.cookieService.set(CookieStorage.REMEMBERME, this.encrypt(userCredential.isChkRememberMe), expireInDays);
	}


	getUserNameWithPassword() {
		let loginModel = new UserLogInOut();
		loginModel.userName = this.decrypt(this.cookieService.get(CookieStorage.USERNAME));
		loginModel.password = this.decrypt(this.cookieService.get(CookieStorage.USERPASSWORD));
		return loginModel;
	}


	cleareCookieCredential() {
		this.cookieService.delete(CookieStorage.USERNAME);
		this.cookieService.delete(CookieStorage.USERPASSWORD);
		this.cookieService.delete(CookieStorage.REMEMBERME);
	}

	setUserProfilePic(profilePic) {
		localStorage.setItem("profilePic", this.encrypt(profilePic));
	}

	/***********************************client  local storage *********************************/

	getInstituteLogo() {
		return this.decrypt(localStorage.getItem('instituteLogo'));
	}

	getUserProfilePic(): string {
		return this.decrypt(localStorage.getItem('profilePic'));
	}

	getUserFirstName(): string {
		return this.decrypt(localStorage.getItem('firstName'));
	}

	getUserLastName(): string {
		return this.decrypt(localStorage.getItem('lastName'));
	}

	getLastLoginDate(): string {
		return this.decrypt(localStorage.getItem('lastLoginDate'));
	}

	getInstituteID(): string {
		return this.decrypt(localStorage.getItem('instituteID'));
	}

	getInstituteName(): string {
		return this.decrypt(localStorage.getItem('instituteName'));
	}

	getInstituteKey(): string {
		return this.decrypt(localStorage.getItem('instituteKey'));
	}

	isWhiteLabelApp(): boolean {
		let isWhiteLabel = this.decrypt(localStorage.getItem('isWhiteLabelApp'));
		if (isWhiteLabel == "true" || isWhiteLabel == "True") {
			return true;
		}
		return false;
	}

	getNoOfAttempLogin() {
		return this.decrypt(localStorage.getItem('noOfAttemp'));
	}

	getUserId(): string {
		return this.decrypt(localStorage.getItem('userid'));
	}

	getStudentID(): string {
		return this.decrypt(localStorage.getItem('studentID'));
	}

	getLogInLogID(): string {
		return this.decrypt(localStorage.getItem('logInLogId'));
	}

	getTokenExpireAt(): string {
		return this.decrypt(localStorage.getItem('expires_at'));
	}

	getUserDisplayName(): string {
		return this.decrypt(localStorage.getItem('displayname'));
	}

	getAdminUserTypeTerm(): string {
		return this.decrypt(localStorage.getItem('userTypeTerm'));
	}

	getAdminAssociationTypeTerm(): string {
		return this.decrypt(localStorage.getItem('associationTypeTerm'));
	}

	getTutorID(): string {
		return this.decrypt(localStorage.getItem('tutorID'));
	}


	isSuperAdmin(): boolean {
		let issuperadmin = this.decrypt(localStorage.getItem('issuperadmin'));
		if (issuperadmin == "true" || issuperadmin == "True") {
			return true;
		}
		return false;
	}


	getToken() {
		return localStorage.getItem('access_token');
	}
	getUserRoles(): string {
		return this.decrypt(localStorage.getItem('roles'));
	}

	getRolePrivileges(): RolePrivilege[] {
		let rights = this.decrypt(localStorage.getItem('roleprivilege'));
		return (JSON.parse(rights) as RolePrivilege[]);
	}
	/***********************************client  local storage *********************************/


	/*************************************************Student*************************************** */
	storeStudentNameWithPassword(userCredential) {
		const expireInDays: number = 2;
		this.cookieService.set(CookieStorage.MOBILENO, this.encrypt(userCredential.mobileNo), expireInDays);
		this.cookieService.set(CookieStorage.USERPASSWORD, this.encrypt(userCredential.password), expireInDays);
	}

	cleareStudentCookieCredential() {
		this.cookieService.delete(CookieStorage.MOBILENO);
		this.cookieService.delete(CookieStorage.USERPASSWORD);
	}

	getStudentNameWithPassword() {
		let loginModel = new LoginVM();
		loginModel.mobileNo = this.decrypt(this.cookieService.get(CookieStorage.MOBILENO));
		loginModel.password = this.decrypt(this.cookieService.get(CookieStorage.USERPASSWORD));
		return loginModel;
	}


	setStudentCredentials(): void {
		// const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + Date.now());

		// localStorage.setItem('s_access_token', authResult.access_Token);
		// localStorage.setItem('s_expires_at', this.encrypt(expiresAt));
		// localStorage.setItem("s_userid", this.encrypt((authResult.userID == null || authResult.userID == undefined) ? 0 : authResult.userID));
		// localStorage.setItem("s_logInLogId", this.encrypt((authResult.logInLogID == null || authResult.logInLogID == undefined) ? 0 : authResult.logInLogID));
		// localStorage.setItem("s_username", this.encrypt(authResult.userName));
		// localStorage.setItem("s_displayname", this.encrypt(authResult.displayName));
		// localStorage.setItem("s_studentName", this.encrypt(authResult.studentName));
		// localStorage.setItem("s_studentID", this.encrypt((authResult.studentID == null || authResult.studentID == undefined) ? 0 : authResult.studentID));
		// localStorage.setItem("s_roles", this.encrypt(authResult.roles));
		// localStorage.setItem("s_profilePic", this.encrypt(authResult.profilePic));
		// localStorage.setItem("s_firstName", this.encrypt(authResult.firstName));
		// localStorage.setItem("s_lastName", this.encrypt(authResult.lastName));
		// localStorage.setItem("s_lastLoginDate", this.encrypt(authResult.lastLoginDate));
		// localStorage.setItem("s_userTypeTerm", this.encrypt(authResult.userTypeName));
		// localStorage.setItem("s_instituteID", this.encrypt(authResult.instituteID));
		// localStorage.setItem("s_associationTypeTerm", this.encrypt(authResult.associationTypeTerm));
		// localStorage.setItem("s_roleprivilege", this.encrypt((authResult.userID == null || authResult.userID == undefined) ? "" : JSON.stringify(authResult.rolePrivileges)));

		// //Set IsSuperAdmin
		// if (authResult.roles != null && authResult.roles != undefined && authResult.roles != "") {
		// 	if (this.hasRole(RoleType.Admin)) {
		// 		authResult.isSuperAdmin = true;
		// 	} else {
		// 		authResult.isSuperAdmin = false;
		// 	}
		// }

		// localStorage.setItem("issuperadmin", this.encrypt(authResult.isSuperAdmin));

		// if (authResult.configurations != null && authResult.configurations != undefined && authResult.configurations.length > 0) {
		// 	this.setConfigurations(authResult.configurations);
		// }
	}

	setStudentNoOfAttempLogin(noOfAttempLogin) {
		localStorage.setItem("s_noOfAttemp", this.encrypt(noOfAttempLogin));
	}

	/****************************student quiz local storage ********************************/
	setStudentQuizMasterID(studentQuizMasterID) {
		localStorage.setItem("s_studentQuizMasterID", this.encrypt(studentQuizMasterID));
	}

	getStudentQuizMasterID(): string {
		return this.decrypt(localStorage.getItem('s_studentQuizMasterID'));
	}

	setStudentQuizCourseID(studentQuizCourseID) {
		localStorage.setItem("s_studentQuizCourseID", this.encrypt(studentQuizCourseID));
	}

	getStudentQuizCourseID(): string {
		return this.decrypt(localStorage.getItem('s_studentQuizCourseID'));
	}

	setStudentQuizSubjectID(studentQuizSubjectID) {
		localStorage.setItem("s_studentQuizSubjectID", this.encrypt(studentQuizSubjectID));
	}

	getStudentQuizSubjectID(): string {
		return this.decrypt(localStorage.getItem('s_studentQuizSubjectID'));
	}

	setStudentQuizLessonID(studentQuizLessonID) {
		localStorage.setItem("s_studentQuizLessonID", this.encrypt(studentQuizLessonID));
	}

	getStudentQuizLessonID(): string {
		return this.decrypt(localStorage.getItem('s_studentQuizLessonID'));
	}

	setStudentQuizTopicID(studentQuizTopicID) {
		localStorage.setItem("s_studentQuizTopicID", this.encrypt(studentQuizTopicID));
	}

	getStudentQuizTopicID(): string {
		return this.decrypt(localStorage.getItem('s_studentQuizTopicID'));
	}

	getStudentUserTypeTerm() {
		return this.decrypt(localStorage.getItem('s_userTypeTerm'));
	}

	getStudentAssociationTypeTerm() {
		return this.decrypt(localStorage.getItem('s_associationTypeTerm'));
	}

	/****************************student quiz local storage ********************************/


	setStudentProfilePic(profilePicPath) {
		localStorage.setItem("s_profilePic", this.encrypt(profilePicPath));
	}

	getStudentUserProfilePic(): string {
		return this.decrypt(localStorage.getItem('s_profilePic'));
	}

	getStudentUserFirstName(): string {
		return this.decrypt(localStorage.getItem('s_firstName'));
	}

	getStudentUserLastName(): string {
		return this.decrypt(localStorage.getItem('s_lastName'));
	}

	getStudentLastLoginDate(): string {
		return this.decrypt(localStorage.getItem('s_lastLoginDate'));
	}

	getStudentInstituteID(): string {
		return this.decrypt(localStorage.getItem('s_instituteID'));
	}

	getStudentNoOfAttempLogin() {
		return this.decrypt(localStorage.getItem('s_noOfAttemp'));
	}

	getStudentUserId(): string {
		return this.decrypt(localStorage.getItem('s_userid'));
	}

	getStudentStudentID(): string {
		return this.decrypt(localStorage.getItem('s_studentID'));
	}

	getStudentLogInLogID(): string {
		return this.decrypt(localStorage.getItem('s_logInLogId'));
	}

	getStudentTokenExpireAt(): string {
		return this.decrypt(localStorage.getItem('s_expires_at'));
	}

	getStudentUserDisplayName(): string {
		return this.decrypt(localStorage.getItem('s_displayname'));
	}

	getStudentToken() {
		return localStorage.getItem('s_access_token');
	}

	getStudentRolePrivileges(): RolePrivilege[] {
		let rights = this.decrypt(localStorage.getItem('s_roleprivilege'));
		return (JSON.parse(rights) as RolePrivilege[]);
	}

	getStudentUserRoles(): string {
		return this.decrypt(localStorage.getItem('s_roles'));
	}

	hasStudentUserRole(role): boolean {
		let roles = this.getStudentUserRoles();
		if (roles != null && roles != "" && roles != undefined) {
			if (roles.toUpperCase().indexOf(role.toUpperCase()) != -1) {
				return true;
			}
		}
		return false;
	}


	setStudentMobileNumber(mobileNo) {
		localStorage.setItem("studentMobileNo", this.encrypt(mobileNo));
	}

	getStudentMobileNumber(): string {
		return this.decrypt(localStorage.getItem('studentMobileNo'));
	}

	setUserPermission(permission: InstituteUserPermissionVM) {
		if (permission != null) {
			localStorage.setItem("userAccess", this.encrypt(JSON.stringify(permission)));
		}
		else {
			localStorage.setItem("userAccess", null);
		}
	}

	getUserPermission(): InstituteUserPermissionVM {
		
		var data = localStorage.getItem("userAccess");
		if (this.commonService.isNullOrEmpty(data) == false) {
			var content = this.decrypt(data);
			return JSON.parse(content) as InstituteUserPermissionVM;
		}
		else {
			this.commonService.redirectToLoginPage();
			return null;
		}
	}

	goToStudentLandingPage() {
		let isAllowCoursePage = true;
		const studentCourseMenuPrivilege = this.getStudentPageAcess(PageCode.STUDENT_COURSE_LIST);

		if (studentCourseMenuPrivilege != null && studentCourseMenuPrivilege != undefined) {
			if (studentCourseMenuPrivilege.isRead == false) {
				isAllowCoursePage = false;

			}
			else {
				isAllowCoursePage = true;
			}
		}

		if (isAllowCoursePage) {
			this.router.navigate(['student/course']);
		}
		else {
			this.router.navigate(['student/dashboard']);
		}
	}

	getStudentPageAcess(formCode): RolePrivilege {
		let rights = this.getStudentRolePrivileges();
		if (rights != null && rights != undefined && rights.length > 0) {
			return rights.find(i => i.formCode.trim() == formCode);
		}
		return null;
	}

	/************************************************************************************************* */


	setConfigurations(configurations: Configuration[]) {
		if (configurations != null && configurations.length > 0) {
			configurations.forEach(item => {
				localStorage.setItem(item.configKey, this.encrypt(item.configValue));
			});
		}
	}

	getConfigurationValue(configKey): string {
		return this.decrypt(localStorage.getItem(configKey));
	}


	hasRole(role): boolean {
		let roles = this.getUserRoles();
		if (roles != null && roles != "" && roles != undefined) {
			if (roles.toUpperCase().indexOf(role.toUpperCase()) != -1) {
				return true;
			}
		}
		return false;
	}

	//Question List - Start
	storeQuestionListColumnFilter(columns: string) {
		localStorage.setItem("questionlistcolumnfilter", this.encrypt(columns));
	}

	getQuestionListColumnFilter(): string {
		return this.decrypt(localStorage.getItem('questionlistcolumnfilter'));
	}

	storeQuestionListFilter(columns: string) {
		localStorage.setItem("questionlistfilter", this.encrypt(columns));
	}

	getQuestionListFilter(): string {
		return this.decrypt(localStorage.getItem('questionlistfilter'));
	}
	//Question List - End

	//Subject List - Grid View or Tile View - Start

	storeSubjectIsGridOrTileView(columns: string) {
		localStorage.setItem("subject_isshowgridview", this.encrypt(columns));
	}

	getSubjectIsGridOrTileView(): boolean {
		let isShowGrid = this.decrypt(localStorage.getItem('subject_isshowgridview'));
		if (isShowGrid == "true" || isShowGrid == "True") {
			return true;
		}
		return false;
	}

	//Subject List - Grid View or Tile View - End

	getTestAddFormData(): string {
		return this.decrypt(localStorage.getItem('testaddformdata'));
	}

	removeTestAddFormData() {
		localStorage.removeItem("testaddformdata");
	}
	storeTestAddFormData(columns: string) {
		localStorage.setItem("testaddformdata", this.encrypt(columns));
	}
	//#region Student List

	storeStudentListColumnFilter(columns: string) {
		localStorage.setItem("studentlistcolumnfilter", this.encrypt(columns));
	}

	getStudentListColumnFilter(): string {
		return this.decrypt(localStorage.getItem('studentlistcolumnfilter'));
	}

	//#endregion

	//Cookie
	setCookie(cookieName: string, value: string) {
		const expireInDays: number = 15;
		this.cookieService.set(cookieName, value, expireInDays);
	}

	getCookie(cookieName: string): string {
		return this.cookieService.get(cookieName);
	}

	checkCookie(cookieName: string): boolean {
		return this.cookieService.check(cookieName);
	}

	deleteCookie(cookieName: string) {
		this.cookieService.delete(cookieName);
	}

	getPaginationSize(pageSizeKey): string {
		return this.decrypt(localStorage.getItem(pageSizeKey));
	}

	storeDisableTestTemplateExamDropdown(input: string) {
		localStorage.setItem("disableTestTemplateDropdown", this.encrypt(input));
	}

	getDisableTestTemplateExamDropdownValue() {
		return this.decrypt(localStorage.getItem('disableTestTemplateDropdown'));
	}

	removeDisableTestTemplateExamDropdown() {
		localStorage.removeItem("disableTestTemplateDropdown");
	}


	getPageAcess(formCode): RolePrivilege {
		let rights = this.getRolePrivileges();
		if (rights != null && rights != undefined && rights.length > 0) {
			return rights.find(i => i.formCode.trim() == formCode);
		}
		return null;
	}

	setRolePrivileges(rolePrivileges: RolePrivilege[]) {
		localStorage.setItem("roleprivilege", this.encrypt((rolePrivileges != null && rolePrivileges != undefined && rolePrivileges.length > 0) ? JSON.stringify(rolePrivileges) : ""));
		this.refreshMenus.emit(true);
	}

	goToLandingPage() {
		//let isAllowQuestionPage = false;
		//const questionMenuPrivilege = this.getPageAcess(PageCode.QUESTION);
		// if (questionMenuPrivilege != null && questionMenuPrivilege != undefined) {
		//   if (questionMenuPrivilege.isRead == false) {
		//     isAllowQuestionPage = false;
		//     //Question Verify Privilege
		//     const questionVerifyPageAccessRight = this.getPageAcess(PageCode.QUESTION_VERIFY);
		//     //Question Publish Privilege
		//     const questionPublishPageAccessRight = this.getPageAcess(PageCode.QUESTION_PUBLISH);

		//     if (questionVerifyPageAccessRight.isRead == true || questionVerifyPageAccessRight.isExecute == true || questionPublishPageAccessRight.isRead == true || questionPublishPageAccessRight.isExecute == true) {
		//       isAllowQuestionPage = true;
		//     }
		//   }
		//   else {
		//     isAllowQuestionPage = true;
		//   }
		// }
		//this.router.navigate(['/institute/user-master']);
		// this.router.navigate(['/institute/user-master']);
		// if (isAllowQuestionPage) {
		//   this.router.navigate(['/institute/question']);
		// }
		// else {
		//   this.router.navigate(['/institute/dashboard']);
		// }

		let self = this;

		const userRole = self.getUserRoles();

		// if (userRole == RoleType.Tutor) {
		// 	this.router.navigate(['/institute/tutor/dashboard']);
		// } else {
		// 	this.router.navigate(['/institute/admin/dashboard']);
		// }

		this.router.navigate(['/institute/dashboard']);
	}


	//Tutor List

	storeTutorListColumnFilter(columns: string) {
		localStorage.setItem("tutorlistcolumnfilter", this.encrypt(columns));
	}

	getTutorListColumnFilter(): string {
		return this.decrypt(localStorage.getItem('tutorlistcolumnfilter'));
	}

	setCourseName(courseName: string) {
		sessionStorage.setItem("courseName", this.encrypt(courseName));
	}

	setSubjectName(subjectName: string) {
		sessionStorage.setItem("subjectName", this.encrypt(subjectName));
	}

	getCourseName(): string {
		return this.decrypt(sessionStorage.getItem('courseName'));
	}

	getSubjectName(): string {
		return this.decrypt(sessionStorage.getItem('subjectName'));
	}

	//#region Removed Test Questions

	storeRemovedTestQuestions(columns: string) {
		localStorage.setItem("removedtestquestions", this.encrypt(columns));
	}

	getRemovedTestQuestions(): RemoveQuestionTestMasterVM[] {
		let questions = this.decrypt(localStorage.getItem('removedtestquestions'));
		return (JSON.parse(questions) as RemoveQuestionTestMasterVM[]);
	}

	//#endregion

	//#region Flipped Test Questions

	storeFlippedTestQuestions(columns: string) {
		localStorage.setItem("flippedtestquestions", this.encrypt(columns));
	}

	getFlippedTestQuestions(): FlippedQuestionVM[] {
		let questions = this.decrypt(localStorage.getItem('flippedtestquestions'));
		return (JSON.parse(questions) as FlippedQuestionVM[]);
	}

	//#endregion
	changeMenuBaseOnURLContent(event) {
		this.changeMenuBaseOnURL.emit(event);
	}

	setStudentTestOrQuizStartedFlag(isStarted: boolean) {
		localStorage.setItem("s_testOrQuizStarted", this.encrypt(isStarted));
	}

	getStudentTestOrQuizStartedFlag(): boolean {
		let flag = this.decrypt(localStorage.getItem('s_testOrQuizStarted'))
		if (flag == "true" || flag == "True") {
			return true;
		}
		return false;
	}

	removeStudentTestOrQuizStartedFlag() {
		localStorage.removeItem("s_testOrQuizStarted");
	}

	refreshStudentCredentials(result: RefreshTokenResultVM): void {
		const expiresAt = JSON.stringify((result.expiresIn * 1000) + Date.now());

		localStorage.setItem('s_access_token', result.access_Token);
		localStorage.setItem('s_expires_at', this.encrypt(expiresAt));
		localStorage.setItem("s_userid", this.encrypt((result.userID == null || result.userID == undefined) ? 0 : result.userID));
		localStorage.setItem("s_displayname", this.encrypt(result.displayName));
		localStorage.setItem("s_studentName", this.encrypt(result.studentName));
		localStorage.setItem("s_studentID", this.encrypt((result.studentID == null || result.studentID == undefined) ? 0 : result.studentID));
		localStorage.setItem("s_firstName", this.encrypt(result.firstName));
		localStorage.setItem("s_lastName", this.encrypt(result.lastName));
		localStorage.setItem("s_instituteID", this.encrypt(result.instituteID));
	}

	getMinPageSize(configKey: string): number {
		let configValue = this.getConfigurationValue(configKey);
		let pageSize = 10;

		if (configValue != null && configValue != undefined && configValue.length > 0) {
			configValue = configValue.trim();

			let pageSizeList = configValue.split(',');

			if (pageSizeList != null && pageSizeList.length > 0) {
				//Cast string to number.
				const data = pageSizeList.map(i => parseInt(i));

				pageSize = Math.min(...data);
			}
		}

		return pageSize;
	}

	showHideHamburger(isDisplay: boolean) {
		this.showHideHamburgerEvent.emit(isDisplay);
	}

	setWhiteLabelLogin(instituteKey: string) {
		sessionStorage.setItem("whitelabelKey", this.encrypt(instituteKey));
	}

	getWhiteLabelLogin() {
		return this.decrypt(sessionStorage.getItem('whitelabelKey'));
	}

	showAdminMode(isDisplay: boolean) {
		this.adminModeEvent.emit(isDisplay);
	}

	getRefreshToken() {
		return this.decrypt(localStorage.getItem('refresh_Token'));
	}

	setRefreshToken(refreshToken: string) {
		localStorage.setItem("refresh_Token", this.encrypt(refreshToken));
	}

	setAccessToken(accessToken: string, expiresIn: number, logInLogID: number) {
		const expiresAt = JSON.stringify((expiresIn * 1000) + Date.now());

		localStorage.setItem("access_token", accessToken);
		localStorage.setItem("logInLogId", this.encrypt(logInLogID));
		localStorage.setItem('expires_at', this.encrypt(expiresAt));
	}

	setTestResultCookie(key: string, value: string) {
		const expiresAt = 0.0416667; // 1 hour
		document.cookie = `${key}=${this.encrypt(value)}; ${expiresAt}`;
		this.cookieService.set(key, this.encrypt(value), expiresAt);
	}

	getGoogleApiKey(): string {
		return this.decrypt(localStorage.getItem('googleAPIKey'));
	}

	clone(data: any) {
		if (data != null) {
			var str = JSON.stringify(data);
			return JSON.parse(str);
		}
		else {
			return null;
		}
	}
}
