import { FileAttachmentVM } from './FileAttachmentVM';

export class StudentVM {
    studentID: number;
    instituteID?: number;
    createdOn?: Date;
    createdBy?: number;
    studentEnrollmentNo: string;
    studentName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    displayName: string;
    profilePicture: string;
    currentlyStudyingInstituteID?: number;
    currentlyStudyingInstituteName: string;
    currentGrade: string;
    currentDivision: string;
    currentRollNo: string;
    homeAddress1: string;
    homeAddress2: string;
    homeArea: string;
    homeCity: string;
    homeState: string;
    homeZipcode: string;
    homePhoneNo: string;
    currentAddress1: string;
    currentAddress2: string;
    currentArea: string;
    currentCity: string;
    currentState: string;
    currentZipCode: string;
    currentPhoneNo: string;
    mobileNo: string;
    genderTypeTerm: string;
    isPhysicallyChallenged: string;
    schoolBoardTerm: string;
    learningTypeTerm: string;
    callTerm: string;
    subCallTerm: string;
    currentStatusTerm: string;
    dateOfBirth?: Date;
    isDocumentSubmitted?: boolean;
    isDocumentVerified?: boolean;
    updateLog?: Date;
    isActive?: boolean;
    updatedOn?: Date;
    updatedBy?: number;
    email: string;
    admissionYearTerm: string;
    userID:number;
    studentProfilePictureFile?:  FileAttachmentVM = new FileAttachmentVM(); 
    studentProfilePictureFileCroppedString:string; 
}
