// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
// Auth
import { AuthNoticeService } from '../../../../core/auth/auth-notice/auth-notice.service';
import { UserLogInOut } from './../../../../models/UserLogInOut';
import { LoginResultVM } from '../../../../viewmodels/LoginResultVM';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { UserService } from '../../../../services/user.service';
import { CookieService } from 'ngx-cookie-service';

// View Model
import { UserVM } from '../../../../viewmodels/UserVM';

// @Directive()
@Component({
  selector: 'kt-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  userID: number;
  emailVerificationCode: string;
  userVM: UserVM = new UserVM();
  emailVerificationMessage: string;
  isShowLink: boolean = true;

  constructor(private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private storageService: StorageService,
    private userService: UserService
  ) {
    this.route.queryParams.subscribe(params => {
      this.userID = parseInt(params['userID']) || 0;
      this.emailVerificationCode = params['code'] || '';
    });
  }

  ngOnInit() {
    this.ngPageLoad();
  }

  ngPageLoad() {
    let self = this;

    self.commonService.showLoader();
    Promise.all(
      [
        self.getUserByID(),
      ]).then((data: any) => {

        if (data != null) {

          let userResult = data[0];

          if (userResult != null && userResult.success == true) {

            self.userVM = userResult.data as UserVM;

            if (self.userVM.isEmailVerified == true) {
              self.emailVerificationMessage = "Your email is already verified.";
              this.commonService.hideLoader();
              if (!(this.cdr as ViewRef).destroyed) {
                this.cdr.detectChanges()
              }
            }
            else {
              if (self.emailVerificationCode != self.userVM.emailVerificationCode) {
                self.emailVerificationMessage = "Your email verification link is old one. Please check latest verification email link in your mail box.";
                this.commonService.hideLoader();
                if (!(this.cdr as ViewRef).destroyed) {
                  this.cdr.detectChanges()
                }
              }
              else {
                self.userService.updateIsEmailVerified(this.userID, true).subscribe((res) => {
                  if (res.success == true) {
                    self.emailVerificationMessage = "Your email is verified successfully.";
                    self.commonService.hideLoader();
                    if (!(this.cdr as ViewRef).destroyed) {
                      this.cdr.detectChanges()
                    }
                  }
                  else {
                    self.commonService.hideLoader();
                    self.commonService.showToaster(res.data, 'error');
                  }
                }, (err) => {
                  self.commonService.hideLoader();
                  self.commonService.showToaster(err.error, "error");
                });
              }
            }
          }

          this.commonService.hideLoader();
        }
      }).catch((error) => {
        this.commonService.hideLoader();
        console.error(error);
      });
  }

  getUserByID() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.userService.getUserByID(this.userID).subscribe(data => {
        resolve(data);
      }, error => {
        resolve(error);
      });
    });
  }
}
