import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from './../services/utilities/app-config'

@Pipe({
  name: 'imageURLBind'
})
export class ImageURLBindPipe implements PipeTransform {
  constructor(private appConfig : AppConfig){

  }

  transform(imageName: any, id: any, imageBaseUrl: any, args?: any): any {
    if(args != null && args != undefined){
      if(args == "Institute Admin") {
          return  this.getInstituteAdminImageURL(imageName, id, imageBaseUrl);
        }
      else{ 
           return this.getImageURL(imageName, id, imageBaseUrl);
       }
    } 
    else{ 
       return this.getImageURL(imageName, id, imageBaseUrl);
    }
  }

  getInstituteAdminImageURL(imageName: any, id: any, imageBaseUrl: any){
  let mainImageURL = "";  
    let adminImageBaseUrl = this.appConfig.InstituteUserProfileAttachmentUrl;
    if (adminImageBaseUrl != null && adminImageBaseUrl != undefined && adminImageBaseUrl != "") {
      mainImageURL = adminImageBaseUrl + id + '/thumb/' + imageName;
    }
    return mainImageURL;
  }

  getImageURL(imageName: any, id: any, imageBaseUrl: any) {
    let mainImageURL = ""; 
    
    if (imageBaseUrl != null && imageBaseUrl != undefined && imageBaseUrl != "") {
      mainImageURL = imageBaseUrl + id + '/thumb/' + imageName;
    }
    return mainImageURL;
  }

}
