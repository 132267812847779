import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';

import { AppConfig } from './utilities/app-config';
import { HttpService } from './utilities/http.service';
import { catchError, map } from 'rxjs/operators';
import { ApiVersion } from '../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;
@Injectable({
  providedIn: 'root'
})
export class ProjecttermService {

  constructor(
    private http: HttpClient) { }

  getProjectTermByCategoryName(categoryName) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/ProjectTerm/GetProjectTermByCategoryNameForInstitute?categoryName=${categoryName}`);
  }

  getProjectTermByCategoryNameForUnauthorizedPage(categoryName) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/ProjectTerm/ProjectTermByCategoryNameForUnauthorizedPage?categoryName=${categoryName}`);
  }

  getProjectTermBySyllabusType(categoryName) {
    return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/ProjectTerm/GetProjectTermByCategoryNameForInstitute?categoryName=${categoryName}`);
  }

  insertQuestionStudentReport(insertQuestionStudentReportModel) {
    return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Question/InsertQuestionStudentReport`, insertQuestionStudentReportModel);
  }
}
