
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ViewRef, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


// Services
import { CommonService } from '../../../../services/utilities/common.service';
import { StorageService } from '../../../../services/utilities/storage.service';
import { RoleService } from '../../../../services/role.service';

import { UserService } from '../../../../services/user.service';
import { REGEXP } from '../../shared/regexp/regexp';
import { AuthService } from '../../../../core/auth/_services/auth.service';


// Validator
import { MustMatch } from '../../../../validator/must-match.validator';
import { ChangePasswordVM } from '../../../../viewmodels/ChangePasswordVM';


// @Directive()
@Component({
  selector: 'kt-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  userID?: number;
  instituteID?: number;
  userForm: UntypedFormGroup;
  changePasswordInput: ChangePasswordVM = new ChangePasswordVM();
  userTypeTerm: string = "";

  hidePassword = true;
  hideConfirmPassword = true;
  hideOldPassword = true;

  main = {
    beforeCodeTitle: 'Change Password',
    isCodeVisible: false,
    isExampleExpanded: false
  };
  isStudentLogin: boolean = false;
  isSubmitted: boolean = false;
  constructor(
    private router: Router,
    private regex: REGEXP,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private storageService: StorageService,
    private roleService: RoleService,
    private userService: UserService,
  ) {

    let self = this;
    let isStudentURL = this.commonService.isStudentURL();
    let studentToken = self.storageService.getStudentToken();
    if (isStudentURL == true && !this.commonService.isNullOrEmpty(studentToken)) {
      this.isStudentLogin = true;
      this.userID = parseInt(this.storageService.getStudentUserId());
      this.instituteID = parseInt(this.storageService.getStudentInstituteID());
    }
    else {
      this.userID = parseInt(this.storageService.getUserId());
      this.instituteID = parseInt(this.storageService.getInstituteID());
    }

  }

  ngOnInit() {
    this.initForm();
  }


  ngOnDestroy() {
    this.cdr.detach();
  }

  initForm() {
    this.userForm = this.fb.group({
      userID: [this.userID],
      instituteID: [this.instituteID],
      oldPassword: [this.changePasswordInput.oldPassword, Validators.compose([Validators.required])],
      newPassword: [this.changePasswordInput.newPassword, Validators.compose([Validators.required, Validators.pattern(this.regex.PASSWORD_EXP)])],
      confirmPassword: [this.changePasswordInput.confirmPassword, Validators.compose([Validators.required, Validators.pattern(this.regex.PASSWORD_EXP)])],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.userForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  changePassword() {
    let self = this;
    self.isSubmitted = true;
    const controls = this.userForm.controls;

    // Check form validation
    if (this.userForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    self.commonService.showLoader();

    let userVM = self.userForm.value;

    self.userService.changePassword(userVM).subscribe((res) => {
      if (res.success == true) {
        self.commonService.hideLoader();
        self.commonService.showToaster(res.message, 'success');
        self.goToListPage();
      }
      else {
        self.commonService.hideLoader();
        self.commonService.showToaster(res.message, 'error');
      }
    }, (err) => {
      self.commonService.hideLoader();
      self.commonService.showToaster(err.message, "error");
    });
  }


  // Form, Validation and Submit Code - End

  // Other functions - Start

  goToListPage() {
    if (this.isStudentLogin == true) {
      this.router.navigate(['student/course/course-list']);
    }
    else {
      this.storageService.goToLandingPage();
    }
  }


  // Other functions - End
}
