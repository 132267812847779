// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-popup {
  overflow: hidden;
  position: relative;
}
.pre-popup .last-pagination-main {
  width: auto;
  float: right;
}
.pre-popup .last-pagination-main .cancel-btn .btn-width {
  padding: inherit !important;
  background: transparent !important;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px !important;
  right: -23px;
}
.pre-popup .preview-heading {
  clear: both;
  border: 1px solid #e8e8e8;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  max-height: 600px;
  overflow-y: auto;
}

span.heading-span {
  display: block;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}

::ng-deep .mat-dialog-container {
  padding: 8px 12px !important;
}

div[aria-label=Pop-out] {
  display: none;
}

div[aria-label=toolbar] {
  width: 52px;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/shared/material-preview/material-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AAEY;EACI,2BAAA;EACA,kCAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,YAAA;AAAhB;AAKI;EACI,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAHR;;AAOA;EACI,cAAA;EACA,kBAAA;EAEA,eAAA;EACA,WAAA;EACA,gBAAA;AALJ;;AAQA;EACI,4BAAA;AALJ;;AAQA;EACI,aAAA;AALJ;;AASA;EACI,WAAA;AANJ","sourcesContent":[".pre-popup {\r\n    overflow: hidden;\r\n    position: relative;\r\n\r\n    .last-pagination-main {\r\n        width: auto;\r\n        float: right;\r\n\r\n        .cancel-btn {\r\n            .btn-width {                \r\n                padding: inherit !important;\r\n                background: transparent !important;\r\n                color: #000;\r\n                font-size: 14px;\r\n                font-weight: 500;\r\n                line-height: 25px !important;\r\n                right: -23px;            \r\n            }\r\n        }\r\n    }\r\n\r\n    .preview-heading {\r\n        clear        : both;\r\n        border       : 1px solid #e8e8e8;\r\n        margin-top   : 10px;\r\n        margin-bottom: 10px;\r\n        padding      : 10px 15px;\r\n        max-height   : 600px;\r\n        overflow-y   : auto;\r\n    }\r\n}\r\n\r\nspan.heading-span {\r\n    display    : block;\r\n    text-align : center;\r\n    // padding: 20px 0px;\r\n    font-size  : 20px;\r\n    color      : #000;\r\n    font-weight: 400;\r\n}\r\n\r\n::ng-deep .mat-dialog-container {\r\n    padding: 8px 12px !important;\r\n}\r\n\r\ndiv[aria-label=\"Pop-out\"] {\r\n    display: none;\r\n}\r\n\r\n//syntax\r\ndiv[aria-label=\"toolbar\"] {\r\n    width: 52px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
