import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
	enableProdMode();
	// if (location.protocol === "http:") {
	// 	// 	window.location.href = location.href.replace("http", "https");
	// }
}
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
