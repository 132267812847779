import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/utilities/common.service';
import { DateFormat } from '../enum/DateFormat';
import { DateSeprator } from '../enum/DateSeprator';

@Pipe({
  name: 'parseDateTime'
})
export class ParseDateTimePipe implements PipeTransform {

  constructor(private commonService: CommonService) {

  }

  transform(value: any, ...args: any[]): any {
    return this.parseDateTime(value);
  }

  parseDateTime(date: string): string {
		if (date != null && date != undefined && date != "") {
			return this.commonService.getDateTimeString(new Date(date), DateFormat.DDMMYYYY, DateSeprator.SLASH);
		}
		return "";
	}

}
