// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .kt-quick-search__category:first-child {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/views/partials/layout/search-result/search-result.component.scss"],"names":[],"mappings":"AACC;EACC,aAAA;AAAF","sourcesContent":[":host {\r\n\t.kt-quick-search__category:first-child {\r\n\t\tmargin-top: 0;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
