import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
//import { MathJax } from 'mathjax';
declare var MathJax: any;

@Directive({
  selector: '[ktMathText]'
})
export class MathTextDirective {
  //Used to bind data: eg: <div [mathText]="raw string">
  @Input('ktMathText') inputString: string;
  // host element
  private hostEl: HTMLElement;

  constructor(public elementRef: ElementRef) {
    this.hostEl = elementRef.nativeElement; //capture the HTML element host
  }

  //have MathJax parse the host element and render the math
  render() { MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.hostEl]) }

  // called when the inputString changes.
  ngOnChanges() {
    //make the input string into the innerText of the host element
    //this.hostEl.innerText = this.inputString;
    this.hostEl.innerHTML = this.inputString;
    this.render();
  }

}

