import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ApiResult } from "../viewmodels/common/ApiResult";

import { AppConfig } from "./utilities/app-config";
import { HttpService } from "./utilities/http.service";
import { catchError, map } from "rxjs/operators";
import { ApiVersion } from "../enum/ApiVersion";

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
	providedIn: "root",
})
export class StudentService {
	constructor(private http: HttpClient) { }

	//#region Common for all Module

	getAllStudent(instituteID) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetAll?instituteID=${instituteID}`
		);
	}

	getGroupStudent(instituteID, groupID) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetGroupStudents?instituteID=${instituteID}&groupID=${groupID}`
		);
	}

	//#endregion

	//#region Student List

	updateStudentStatus(
		instituteID: number,
		studentID: number,
		status: boolean
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/UpdateStudentStatus?instituteID=${instituteID}&studentID=${studentID}&status=${status}`
		);
	}

	//#endregion

	//#region Student Profile

	deleteStudent(instituteID: number, studentID: number) {
		return this.http.post<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/DeleteStudent?instituteID=${instituteID}&studentID=${studentID}`,
			null
		);
	}

	//#endregion

	getTopPerformingStudent(
		courseID: number,
		instituteID: number,
		groupID?: number
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetTopPerformingStudent?courseID=${courseID}&instituteID=${instituteID}&groupID=${groupID}`
		);
	}

	getTopPerformingStudentByGroupIDs(topPerformanceInput) {
		return this.http.post<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetTopPerformingStudentByGroupIDs`,
			topPerformanceInput
		);
	}

	getUnderPerformingStudentByGroupIDs(underPerformanceInput) {
		return this.http.post<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetUnderPerformingStudentByGroupIDs`,
			underPerformanceInput
		);
	}

	getStudentView(studentID: number, instituteID: number) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetStudentWall?studentID=${studentID}&instituteID=${instituteID}`
		);
	}



	getStudentEfficiencyLevelByMonths(
		instituteID: number,
		studentIDs: string,
		subjectIDs: string
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetStudentEfficiencyLevelByMonths?instituteID=${instituteID}&studentIDs=${studentIDs}&subjectIDs=${subjectIDs}`
		);
	}

	getStudentList(
		searchText: string,
		instituteID: number,
		instituteUserID: number,
		courseIDs: string,
		groupIDs: string,
		subjectIDs: string,
		tutorIDs: string,
		testMasterID: number,
		pageNum: number,
		sortColumn: string,
		sortDirection: string,
		caller: string
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetStudentListForInstitute?searchText=${searchText}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&tutorIDs=${tutorIDs}&testMasterID=${testMasterID}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&caller=${caller}`
		);
	}

	getExcelTestResult(
		instituteID: number,
		instituteUserID: number,
		courseIDs: string,
		groupIDs: string,
		subjectIDs: string,
		tutorIDs: string,
		testMasterID: number,
		pageNum: number,
		sortColumn: string,
		sortDirection: string,
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/ExportTestResultStudentList?instituteID=${instituteID}&instituteUserID=${instituteUserID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&tutorIDs=${tutorIDs}&testMasterID=${testMasterID}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
		);
	}

	getTestResultStudentList(
		searchText: string,
		instituteID: number,
		instituteUserID: number,
		courseIDs: string,
		groupIDs: string,
		subjectIDs: string,
		tutorIDs: string,
		testMasterID: number,
		pageNum: number,
		sortColumn: string,
		sortDirection: string
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Student/GetTestResultStudentList?searchText=${searchText}&instituteID=${instituteID}&instituteUserID=${instituteUserID}&courseIDs=${courseIDs}&groupIDs=${groupIDs}&subjectIDs=${subjectIDs}&tutorIDs=${tutorIDs}&testMasterID=${testMasterID}&pageNum=${pageNum}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
		);
	}

	getAssignmentList(
		instituteID: number,
		testMasterID: number,
		studentID: number,
		sortColumn: string,
		sortDirection: string
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Assignment/GetAssignments?instituteID=${instituteID}&testMasterID=${testMasterID}&studentID=${studentID}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
		);
	}

	getLessonActivities(
		instituteID: number,
		studentID: number,
		subjectIDs: string,
		sortColumn: string,
		sortDirection: string
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetLessonActivities?instituteID=${instituteID}&studentID=${studentID}&subjectIDs=${subjectIDs}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
		);
	}

	getChapterWiseProficiency(
		instituteID: number,
		studentID: number, subjectIDs: string, sortColumn: string, sortDirection: string
	) {
		return this.http.get<ApiResult>(
			`${BaseInstituteURL}${ApiVersion.V1}/Lesson/GetChapterWiseProficiencyForInstitute?instituteID=${instituteID}&studentID=${studentID}&subjectIDs=${subjectIDs}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`
		);
	}

	register(model) {
		return this.http.post<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Student/RegisterStudent`, model);
	}
}
