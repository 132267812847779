import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  public EncryptKey: string = "#$123@Brainflex*&456"; // It's static  encryption key.
  constructor() { }

  encrypt(plainText: any) {
    try {
      if (typeof plainText === "string")
        return CryptoJS.AES.encrypt(plainText.trim(), this.EncryptKey).toString();
      else if (typeof plainText === "boolean" || typeof plainText === "number") {
        return CryptoJS.AES.encrypt(plainText.toString(), this.EncryptKey).toString();
      } else {
        return CryptoJS.AES.encrypt(plainText, this.EncryptKey).toString();
      }
    } catch (Error) {
      console.log("plainText : " + plainText);
      console.log("encrypt() Error Message : " + Error.message);
    }
  }

  decrypt(cipherText: any) {
    try {
      return CryptoJS.AES.decrypt(cipherText.trim(), this.EncryptKey).toString(CryptoJS.enc.Utf8);

    } catch (Error) {
      console.log("cipherText : " + cipherText);
      console.log("decrypt() Error Message : " + Error.message);
    }
  }

   //The set method is use for encrypt the value.
   set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
