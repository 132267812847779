// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pre-popup {
  overflow: hidden;
  position: relative;
}
.pre-popup .last-pagination-main {
  width: auto;
  float: right;
}
.pre-popup .last-pagination-main .cancel-btn .btn-width {
  padding: inherit !important;
  background: transparent !important;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
.pre-popup .preview-heading {
  clear: both;
  border: 1px solid #e8e8e8;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  max-height: 600px;
  overflow-y: auto;
}

span.heading-span {
  display: block;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}

::ng-deep .mat-dialog-container {
  padding: 8px 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/views/pages/shared/latex-preview/latex-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;AAER;AAAY;EACI,2BAAA;EACA,kCAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAEhB;AAEI;EACI,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAAR;;AAIA;EACI,cAAA;EACA,kBAAA;EAEA,eAAA;EACA,WAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,4BAAA;AAFJ","sourcesContent":[".pre-popup {\r\n    overflow: hidden;\r\n    position: relative;\r\n    .last-pagination-main {\r\n        width: auto;\r\n        float: right;\r\n        .cancel-btn {\r\n            .btn-width {\r\n                padding: inherit !important;\r\n                background: transparent !important;\r\n                color: #000;\r\n                font-size: 20px;\r\n                font-weight: 500;\r\n            }\r\n        }\r\n    }\r\n    .preview-heading {\r\n        clear: both;\r\n        border: 1px solid #e8e8e8;\r\n        margin-top: 10px;\r\n        margin-bottom: 10px;\r\n        padding: 10px 15px;\r\n        max-height: 600px;\r\n        overflow-y: auto;\r\n    }\r\n}\r\n\r\nspan.heading-span {\r\n    display: block;\r\n    text-align: center;\r\n    // padding: 20px 0px;\r\n    font-size: 20px;\r\n    color: #000;\r\n    font-weight: 400;\r\n}\r\n\r\n::ng-deep .mat-dialog-container{\r\n    padding: 8px 12px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
