
export enum PageName {
    ExamManagement = "Exam Management",
    /**************************************/
    ExamTitle = "Exam",
    ExamList = "exam-list",
    ExamAdd = "exam-add",
    ExamEdit = "exam-edit",
    /**************************************/
    TestTemplateTitle = "Test Template",
    TestTemplateList = "test-template-list",
    TestTemplateAdd = "test-template-add",
    TestTemplateEdit = "test-template-edit",
    /**************************************/
    TestTitle = "Test",
    TestList = "test-list",
    TestAdd = "test-add",
    TestEdit = "test-edit",
    /**************************************/
    RoleTitle = "Role",
    RoleList = "role-list",
    RoleAdd = "role-add",
    RoleEdit = "role-edit",
    /**************************************/
    SchoolTitle = "School",
    SchoolList = "school-list",
    SchoolAdd = "school-add",
    SchoolEdit = "school-edit",
    /**************************************/
    UserTitle = "User",
    UserList = "user-list",
    UserAdd = "user-add",
    UserEdit = "user-edit",
    /**************************************/  
}