 import { Configuration } from '../models/Configuration'
 import { RolePrivilege } from '../models/RolePrivilege';
import { InstituteUserPermissionVM } from './InstituteUserPermissionVM';

export class LoginResultVM  {
    userID: number;
    userTypeName: string;
    associationID: number;
    associationTypeTerm: string;
    userName: string;
    displayName: string;
    statusTerm: string;
    profilePic: string;
    instituteID: number;
    instituteName: string;
	instituteKey: string;
    expiresIn: number;
    access_Token: string;
    refresh_Token?: string;
    isSuperAdmin: boolean;
    logInLogID: number;
    roles: string;
    configurations: Configuration[];
    firstName:string;
    lastName:string;
    phoneNo:string; 
    isActive:boolean;
    isInitialPasswordResetComplete:boolean;
    isSignupUser:boolean;
    isEmailVerified:boolean;
    lastLoginDate?:Date;
    updateLog?:Date;
    createdBy?:number;
    createdOn?:Date; 
    rolePrivileges?: RolePrivilege[] = [];
    userTypeTerm:string;
    isProfilePicFromUser:boolean;
    userProfilePic:string;
    tutorID:string;
	instituteLogo: string;
	googleAPIKey: string;
	isWhiteLabelApp: boolean;
	permission: InstituteUserPermissionVM;
}
