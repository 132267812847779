
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../viewmodels/common/ApiResult';
import { ApiVersion } from '../enum/ApiVersion';

const BaseInstituteURL = environment.BaseInstituteURL;

@Injectable({
    providedIn: 'root'
})

export class DashboardService {

    constructor(private http: HttpClient) { }

    getCountForDashboard(tutorID: number, instituteID: number) {
        return this.http.get<ApiResult>(`${BaseInstituteURL}${ApiVersion.V1}/Dashboard/GetCountForDashboard?tutorID=${tutorID}&instituteID=${instituteID}`);
    }
}