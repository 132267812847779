// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			DASHBOARD1: 'demo Dashboard',
			MYFORM: 'My Form',
			MYWIZARD: 'My Wizard',
			QUESTION: 'Question',
			COURSE: 'Course',
			SUBJECT: 'Subject',
			CHAPTER: 'Chapter',
			CONCEPT: 'Concept',
			USER: 'User',
			EXAM_MANAGEMENT: 'Exam Management',
			EXAMS: 'Exams',
			TEST_TEMPLETE: 'Test Tempalte',
			INSTITUTE: 'Institute',
			SUBSCRIPTION_MANAGEMENT: 'Subscription Management',
			TUTOR_MANAGEMENT: 'Tutor Management',
			STUDENT_MANAGEMENT: 'Student Management',
			GROUP_MANAGEMENT: 'Group Management',
			CLIENT_SUBSCRIPTION: 'Client Subscription',
			MASTER_CONFIGURATION: 'Master Configuration',
			COURSE_MANAGEMENT: 'Course Management',
			MY_COURSE: 'My Course',
			TEST: 'Tests',
			DOUBTS: 'Doubts',
			ANALYTICS: 'Analytics',
			SUBSCRIPTION: 'Subscription',
			MYDATA: 'My Data',
			CONFIGURATIONS: 'Configurations',
			NOTIFICATIONS: 'Notifications'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
				SEND_OTP_BUTTON: 'Send OTP',
				VERIFY_BUTTON: 'Verify OTP'
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
				RESET: 'Reset'
			},
			FORGOT: {
				TITLE: 'Forgot Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset Password',
				DESC: 'Create your password to reset your password',
				SUCCESS: 'Your account has been successfully reset.',
				TITLE_INITIAL_RESET_PASSWORD: 'Reset Your Password',
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'

			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				NEW_PASSWORD: 'New Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username',
				MOBILENO: 'MobileNo'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				INVALID_RESETPASSWORD: 'The Reset password detail is incorrect',
				REQUIRED_FIELD: 'This field is required',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MIN_NUMBER_FIELD: 'Minimum digit:',
				MAX_NUMBER_FIELD: 'Maximum digit:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				REQ_DEGIT: 'Please enter only number',
				INVALID_FIELD: 'Field is not valid',
				EMAIL_INVALID: 'Email is not valid',
				REQ_POSITIVE_NUMBER: 'Enter only positive number',
				SESSION_EXPIRED: 'Your session is expired. Please try to login again.',
				NUMBER_INVALID: 'Invalid number',
				DIGIT_INVALID: 'Invalid digit. Please enter values between 0-9',
				INVALID_CODE: 'Space are not allowed',
				INVALID_PASSWORD: 'Password should contains one letter, one number and one special character. Min. 8 and max. upto 15 characters.',
				INVALID_CONFIRM_PASSWORD: 'Password and Confirm Password must be match.',
				URL_INVALID: 'URL is not valid',
				VALID_REQUIRED: ' is not valid',
			},
			QUESTION: {
				OPTION_TEXT_OR_IMAGE_REQUIRED: 'Please enter option "Text" or select "Image"',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		}
	}
};
